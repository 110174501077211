import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Product from "../product";
import ScrollToTop from "../../helpers/scroll-to-top";
import Filters from "./filters";
import { UserContext } from "../../../services/user-context";
import Breadcrumbs from "../../helpers/breadcrumbs";
import {useLocation} from "react-router-dom";
import { ExploreApi, ProductsSearchResult, TraderPreview } from "../../../api";
import { CountryList } from "../../../helpers/countryList";
import AuthService from "../../../services/auth-service";
import Rating from "../../helpers/rating";
// import { DateTime } from "luxon";

const ExploreCollections = () => {
  const filterValues = {
    searchTerm: null, skip: 0, take: 6, sortBy: "Newest"
  }
  const location = useLocation()
  const {user} = useContext(UserContext);
  const [trader, setTrader] = useState<TraderPreview>();
  const [products, setProducts] = useState<ProductsSearchResult>();
  const [filterOptions, setFilterOptions] = useState(filterValues);

  let token = AuthService.getBearerToken();
  let exploreApi = new ExploreApi(token);

  const breadcrumbs = [
    {name: "Home", link:"/"},
    {name: "Explorer", link:"/explore"},
    {name: trader?.legalName, link:"/explore/collections"}
  ]

  const getAllProducts = async () => {
    if(trader) {
      try{
        const response: ProductsSearchResult = await exploreApi.apiExploreProductsGet(
          trader.id,
          filterOptions.searchTerm, 
          filterOptions.skip, 
          filterOptions.take
        );
        console.log(response)
        setProducts({...response});
        console.log(products)
      }
      catch(error: any) {
        console.log(error)
      }
    }
  }

  const search = (event) => {
    if(event.keyCode === 13) {
      filterOptions["searchTerm"] = event.target.value;
      setFilterOptions({...filterOptions});
      getAllProducts();
    }
  }

  const loadMore = () => {
    filterOptions["take"] = filterOptions["take"] + 5;
    setFilterOptions({...filterOptions})
    getAllProducts();
  }

  useEffect(() => {
    const locationValue:any = location.state;
    setFilterOptions({...filterOptions});
    const traderValue: TraderPreview = locationValue?.trader;
    setTrader({...traderValue});
  }, [])

  useEffect(() => {
    getAllProducts();
  }, [trader])

  const setIsLoggedIn = async () => {
    console.log("this callee")
    token = await AuthService.getBearerToken();
    console.log(token); 
    exploreApi = new ExploreApi(token);
    getAllProducts();
  }

  return (<div>
    <Breadcrumbs crumbs={breadcrumbs} />
    <div className="m-10 md:mx-36">
      <div className={`md:flex ${user && "md:flex-col space-y-4"}`}>
        <div className="flex-col w-1/2 justify-start">
          <p className="text-xll font-bold text-gray-500">{trader?.legalName}</p>
          {products?.authorizedProductSearchData ? <div 
            className="flex">
            <Rating totalRating={2} id="explore-collections" productId="NA" />
            <span className="px-6 font-bold text-lg text-neutral-1000 flex">
              897 ratings
            </span>
          </div> : <p className="text-mdl font-medium text-gray-000">
            {CountryList[trader?.countryOfRegistration]}
          </p>}
        </div>
        {user && <div className="flex w-full justify-start border-y-1 border-gray-200">
          <span className="flex space-x-2 p-2">
            <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M2.21094 8.71811C2.21094 5.52081 4.80286 2.92889 8.00015 2.92889C11.1974 2.92889 13.7894 5.52081 13.7894 8.71811C13.7894 9.68287 13.4392 10.801 12.8369 11.9869C12.2405 13.1614 11.434 14.3304 10.6092 15.3776C9.78678 16.4217 8.96178 17.325 8.34133 17.9681C8.21888 18.095 8.10461 18.2115 8.00015 18.3168C7.89569 18.2115 7.78142 18.095 7.65896 17.9681C7.03852 17.325 6.21352 16.4217 5.39112 15.3776C4.5663 14.3304 3.75983 13.1614 3.16337 11.9869C2.56111 10.801 2.21094 9.68287 2.21094 8.71811ZM7.32282 20.4481C7.32301 20.4482 7.32317 20.4484 8.00015 19.7124L7.32317 20.4484L8.00015 21.0711L8.67712 20.4484L8.00015 19.7124C8.67712 20.4484 8.67729 20.4482 8.67747 20.4481L8.67798 20.4476L8.67946 20.4462L8.68425 20.4418L8.70106 20.4262C8.71543 20.4128 8.73605 20.3935 8.76251 20.3686C8.81542 20.3187 8.89171 20.2461 8.98804 20.1526C9.18065 19.9659 9.45376 19.6955 9.78059 19.3568C10.4331 18.6805 11.3054 17.7259 12.1803 16.6151C13.0528 15.5074 13.9436 14.2247 14.6202 12.8925C15.2909 11.5717 15.7894 10.1281 15.7894 8.71811C15.7894 4.41624 12.302 0.928894 8.00015 0.928894C3.69829 0.928894 0.210938 4.41624 0.210938 8.71811C0.210938 10.1281 0.709417 11.5717 1.38013 12.8925C2.05665 14.2247 2.94748 15.5074 3.81997 16.6151C4.69487 17.7259 5.56718 18.6805 6.21971 19.3568C6.54654 19.6955 6.81965 19.9659 7.01225 20.1526C7.10859 20.2461 7.18488 20.3187 7.23779 20.3686C7.26425 20.3935 7.28487 20.4128 7.29924 20.4262L7.31605 20.4418L7.32084 20.4462L7.32232 20.4476L7.32282 20.4481ZM5.72778 8.40692C5.72778 7.15199 6.7451 6.13467 8.00003 6.13467C9.25495 6.13467 10.2723 7.15199 10.2723 8.40692C10.2723 9.66184 9.25495 10.6792 8.00003 10.6792C6.7451 10.6792 5.72778 9.66184 5.72778 8.40692ZM8.00003 5.13467C6.19282 5.13467 4.72778 6.59971 4.72778 8.40692C4.72778 10.2141 6.19282 11.6792 8.00003 11.6792C9.80724 11.6792 11.2723 10.2141 11.2723 8.40692C11.2723 6.59971 9.80724 5.13467 8.00003 5.13467Z" fill="black"/>
            </svg>
            <span className="font-medium text-mdl text-gray-200">
            From:
            </span>
            <span className="font-bold text-mdl text-gray-500">
              {CountryList[trader?.countryOfRegistration]}
            </span>
          </span>
          <span className="flex space-x-2 p-2">
            <span className="mt-1">
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.95591 6.03375C4.95591 4.35263 6.31873 2.98981 7.99985 2.98981C9.68098 2.98981 11.0438 4.35263 11.0438 6.03375C11.0438 7.71487 9.68098 9.07769 7.99985 9.07769C6.31873 9.07769 4.95591 7.71487 4.95591 6.03375ZM7.99985 0.989807C5.21416 0.989807 2.95591 3.24806 2.95591 6.03375C2.95591 7.53909 3.61535 8.89041 4.66111 9.81459C3.81792 10.2058 3.04925 10.7426 2.39075 11.4011C1.65415 12.1377 1.06985 13.0122 0.671206 13.9746C0.272562 14.937 0.0673828 15.9685 0.0673828 17.0102H2.06738C2.06738 16.2311 2.22083 15.4597 2.51897 14.74C2.8171 14.0202 3.25408 13.3662 3.80496 12.8153C4.35585 12.2644 5.00984 11.8275 5.7296 11.5293C6.44936 11.2312 7.22079 11.0777 7.99986 11.0777C8.77892 11.0777 9.55036 11.2312 10.2701 11.5293C10.9899 11.8275 11.6439 12.2644 12.1948 12.8153C12.7456 13.3662 13.1826 14.0202 13.4807 14.74C13.7789 15.4597 13.9323 16.2311 13.9323 17.0102H15.9323C15.9323 15.9685 15.7272 14.937 15.3285 13.9746C14.9299 13.0122 14.3456 12.1377 13.609 11.4011C12.9505 10.7426 12.1818 10.2058 11.3386 9.81459C12.3844 8.89041 13.0438 7.53909 13.0438 6.03375C13.0438 3.24806 10.7855 0.989807 7.99985 0.989807Z" fill="#161416"/>
              </svg>
            </span>
            <span className="font-medium text-mdl text-gray-200">
            Member Since:
            </span>
            {products?.authorizedProductSearchData && <span className="font-bold text-mdl text-gray-500">
              {/* {DateTime.fromISO(products.authorizedProductSearchData.toString()).toLocaleString()} */}
            </span>}
          </span>
          <span className="flex space-x-2 p-2">
            <span className="mt-1">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM14.7603 11.5884C15.3126 11.5884 15.7603 11.1407 15.7603 10.5884C15.7603 10.0362 15.3126 9.58844 14.7603 9.58844H11.0278V4.08844C11.0278 3.53615 10.5801 3.08844 10.0278 3.08844C9.47554 3.08844 9.02783 3.53615 9.02783 4.08844V10.5884V11.5884H10.0278H14.7603Z" fill="black"/>
              </svg>
            </span>
            <span className="font-medium text-mdl text-gray-200">
            Avg. Response Time:
            </span>
            <span className="font-bold text-mdl text-gray-500">
            1 hour
            </span>
          </span>
          <span className="flex space-x-2 p-2">
            <span className="mt-1">
              <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5455 3.72732H13.0909V0.45459H1.63636C0.736364 0.45459 0 1.19095 0 2.09095V11.091H1.63636C1.63636 12.4491 2.73273 13.5455 4.09091 13.5455C5.44909 13.5455 6.54545 12.4491 6.54545 11.091H11.4545C11.4545 12.4491 12.5509 13.5455 13.9091 13.5455C15.2673 13.5455 16.3636 12.4491 16.3636 11.091H18V7.00005L15.5455 3.72732ZM15.1364 4.95459L16.74 7.00005H13.0909V4.95459H15.1364ZM4.09091 11.9091C3.64091 11.9091 3.27273 11.541 3.27273 11.091C3.27273 10.641 3.64091 10.2728 4.09091 10.2728C4.54091 10.2728 4.90909 10.641 4.90909 11.091C4.90909 11.541 4.54091 11.9091 4.09091 11.9091ZM5.90727 9.45459C5.45727 8.9555 4.81909 8.63641 4.09091 8.63641C3.36273 8.63641 2.72455 8.9555 2.27455 9.45459H1.63636V2.09095H11.4545V9.45459H5.90727ZM13.9091 11.9091C13.4591 11.9091 13.0909 11.541 13.0909 11.091C13.0909 10.641 13.4591 10.2728 13.9091 10.2728C14.3591 10.2728 14.7273 10.641 14.7273 11.091C14.7273 11.541 14.3591 11.9091 13.9091 11.9091Z" fill="black"/>
              </svg>
            </span>
            <span className="font-medium text-mdl text-gray-200">
            Last Delivery:
            </span>
            <span className="font-bold text-mdl text-gray-500">
            2 days
            </span>
          </span>
        </div>}
        <div className={`flex ${user ? "w-full" : "w-1/2"} justify-end`}>
          <div className="font-bold text-mdl text-gray-500 p-1 mx-3">
            Showing {products?.resultCount ? products.resultCount : 0} results
          </div>
          <div className="md:w-96">
            <div className="relative">
              <input
                type="search"
                className="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-200
                bg-white bg-clip-padding
                border border-solid border-gray-300
                placeholder-gray-200
                rounded-full
                transition
                ease-in-out
                m-0
                focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none
              "
                id="search"
                placeholder="Search"
                onKeyDown={search}
              />
              <div className="m-0.5">
                <span 
                  aria-hidden="true">
                  <FontAwesomeIcon 
                    icon={faMagnifyingGlass} 
                    className="w-4 h-4 absolute right-4 cursor-pointer bottom-3" 
                  />
                </span> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="text-gray-500 md:mx-36">
      <div className="grid md:grid-cols-4 gap-6 text-left">
        <div className="block bg-white w-auto">
          <p className="text-xl font-bold text-gray-500">Filters</p>
          <Filters last={true} />
        </div>
        <div className="col-span-3">
          <div className="grid md:grid-cols-3 gap-4">
            {products?.results?.map((product, productIndex)=><Product 
              key={productIndex} 
              product={product}
              setIsLoggedIn={setIsLoggedIn}
            />)}
          </div>
        </div>
      </div>
    </section>
    <div className="flex justify-center">
      <div className="m-10 xl:w-96 relative text-center">
        <button type="button" 
          onClick={loadMore}
          className="load-more">
            Load More
        </button>
      </div>
    </div>
    <ScrollToTop />
  </div>);   
}

export default ExploreCollections;