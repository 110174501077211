import React, { useContext, useEffect } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import Explore from "./components/explore";
import ExploreCollections from "./components/explore/explore-collections";
import ProductDetails from "./components/explore/product-details";
import FAQ from "./components/faq";
import Footer from "./components/footer";
import ChangePassword from "./components/change-password";
import GetStarted from "./components/get-started";
import Homepage from "./components/homepage";
import LandingPage from "./components/landing-page";
import Login from "./components/login";
import PricingPlans from "./components/pricing-plans";
import { ProtectedRoute } from "./components/router";
import TermsOfUse from "./components/terms-of-use";
import TopNavbar from "./components/top-navbar";
import { UserContext } from "./services/user-context";
import ForgotPassword from "./components/forgot-password";
import AuthService from "./services/auth-service";

function App() {

  useEffect(() => {
    AuthService.refreshToken();
  }, [])

  const { user } = useContext(UserContext);

  const guestRoutes: Array<any> = [
    { path: "/login", component: <Login />, exact: true },
    { path: "/get-started", component: <GetStarted />, exact: true },
    { path: "/change-password", component: <ChangePassword />, exact: true },
    { path: "/forgot-password", component: <ForgotPassword />, exact: true },
  ];
  
  const protectedRoutes: Array<any> = [
    { path: "/homepage", component: <Homepage />, exact: true },
    { path: "/homepage/like", component: <Homepage />, exact: true },
    { path: "/homepage/cart", component: <Homepage />, exact: true },
    { path: "/homepage/profile", component: <Homepage />, exact: true },
    { path: "/homepage/create", component: <Homepage />, exact: true },
    { path: "/homepage/create/:id", name:"edit-assets", component: <Homepage />, exact: true },
    { path: "/homepage/myspace", component: <Homepage />, exact: true },
    { path: "/homepage/myportfolio", component: <Homepage />, exact: true },
    { path: "/homepage/transactions", component: <Homepage />, exact: true },
    { path: "/homepage/messages", component: <Homepage />, exact: true },
    { path: "/homepage/network", component: <Homepage />, exact: true },
  ];

  const mixedRoutes: Array<any> = [
    { path: "/explore", component: <Explore />, exact: true },
    { path: "/explore/collections", component: <ExploreCollections />, exact: true },
    { path: "/explore/product/:id", name:"product-details", component: <ProductDetails />, exact: true },
    { path: "/terms-of-use", component: <TermsOfUse />, exact: true },
    { path: "/faq", component: <FAQ />, exact: true },
    { path: "/pricing-plans", component: <PricingPlans />, exact: true },
  ]
  
  return (
    <HashRouter>
      <div className="font-raleway">
        <TopNavbar />
        <main>
          <Routes>
            <Route
              path="/"
              element={user? <Navigate to="/homepage" /> : <><LandingPage /><Footer /></>}
            />
            {mixedRoutes.map((route, key) => {
              return (
                <Route
                  path={route.path}
                  element={<>{route.component}<Footer /></>}
                  key={key}
                />
              );
            })}

            {guestRoutes.map((route, key) => {
              return (
                <Route
                  path={route.path}
                  element={user? <Navigate to="/homepage" /> : route.component}
                  key={key}
                />
              );
            })}

            {protectedRoutes.map((route, key) => {
              return (
                <Route
                  path={route.path}
                  element={<><ProtectedRoute>{route.component}</ProtectedRoute><Footer /></>}
                  key={key}
                />
              );
            })}
          </Routes>
        </main>
      </div>
    </HashRouter>
  );
}

export default App;

