export const CountryList = {
  "1": "Afghanistan",
  "2": "Åland Islands",
  "3": "Albania",
  "4": "Algeria",
  "5": "American Samoa",
  "6": "Andorra",
  "7": "Angola",
  "8": "Anguilla",
  "9": "Antarctica",
  "10": "Antigua and Barbuda",
  "11": "Argentina",
  "12": "Armenia",
  "13": "Aruba",
  "14": "Australia",
  "15": "Austria",
  "16": "Azerbaijan",
  "17": "Bahamas",
  "18": "Bahrain",
  "19": "Bangladesh",
  "20": "Barbados",
  "21": "Belarus",
  "22": "Belgium",
  "23": "Belize",
  "24": "Benin",
  "25": "Bermuda",
  "26": "Bhutan",
  "27": "Bolivia",
  "28": "Bonaire, Sint Eustatius and Saba",
  "29": "Bosnia and Herzegovina",
  "30": "Botswana",
  "31": "Bouvet Island",
  "32": "Brazil",
  "33": "British Indian Ocean Territory",
  "34": "Brunei Darussalam",
  "35": "Bulgaria",
  "36": "Burkina Faso",
  "37": "Burundi",
  "38": "Cabo Verde",
  "39": "Cambodia",
  "40": "Cameroon",
  "41": "Canada",
  "42": "Cayman Islands",
  "43": "Central African Republic",
  "44": "Chad",
  "45": "Chile",
  "46": "China",
  "47": "Christmas Island",
  "48": "Cocos",
  "49": "Colombia",
  "50": "Comoros",
  "51": "Congo",
  "52": "Congo",
  "53": "Cook Islands",
  "54": "Costa Rica",
  "55": "Côte d'Ivoire",
  "56": "Croatia",
  "57": "Cuba",
  "58": "Curaçao",
  "59": "Cyprus",
  "60": "Czechia",
  "61": "Denmark",
  "62": "Djibouti",
  "63": "Dominica",
  "64": "Dominican Republic",
  "65": "Ecuador",
  "66": "Egypt",
  "67": "El Salvador",
  "68": "Equatorial Guinea",
  "69": "Eritrea",
  "70": "Estonia",
  "71": "Ethiopia",
  "72": "Falkland Islands",
  "73": "Faroe Islands",
  "74": "Fiji",
  "75": "Finland",
  "76": "France",
  "77": "French Guiana",
  "78": "French Polynesia",
  "79": "French Southern Territories",
  "80": "Gabon",
  "81": "Gambia",
  "82": "Georgia",
  "83": "Germany",
  "84": "Ghana",
  "85": "Gibraltar",
  "86": "Greece",
  "87": "Greenland",
  "88": "Grenada",
  "89": "Guadeloupe",
  "90": "Guam",
  "91": "Guatemala",
  "92": "Guernsey",
  "93": "Guinea",
  "94": "Guinea-Bissau",
  "95": "Guyana",
  "96": "Haiti",
  "97": "Heard Island and McDonald Islands",
  "98": "Holy See",
  "99": "Honduras",
  "100": "Hong Kong",
  "101": "Hungary",
  "102": "Iceland",
  "103": "India",
  "104": "Indonesia",
  "105": "Iran",
  "106": "Iraq",
  "107": "Ireland",
  "108": "Isle of Man",
  "109": "Israel",
  "110": "Italy",
  "111": "Jamaica",
  "112": "Japan",
  "113": "Jersey",
  "114": "Jordan",
  "115": "Kazakhstan",
  "116": "Kenya",
  "117": "Kiribati",
  "118": "Korea",
  "119": "Korea",
  "120": "Kuwait",
  "121": "Kyrgyzstan",
  "122": "Lao People's Democratic Republic",
  "123": "Latvia",
  "124": "Lebanon",
  "125": "Lesotho",
  "126": "Liberia",
  "127": "Libya",
  "128": "Liechtenstein",
  "129": "Lithuania",
  "130": "Luxembourg",
  "131": "Macao",
  "132": "Macedonia",
  "133": "Madagascar",
  "134": "Malawi",
  "135": "Malaysia",
  "136": "Maldives",
  "137": "Mali",
  "138": "Malta",
  "139": "Marshall Islands",
  "140": "Martinique",
  "141": "Mauritania",
  "142": "Mauritius",
  "143": "Mayotte",
  "144": "Mexico",
  "145": "Micronesia",
  "146": "Moldova",
  "147": "Monaco",
  "148": "Mongolia",
  "149": "Montenegro",
  "150": "Montserrat",
  "151": "Morocco",
  "152": "Mozambique",
  "153": "Myanmar",
  "154": "Namibia",
  "155": "Nauru",
  "156": "Nepal",
  "157": "Netherlands",
  "158": "New Caledonia",
  "159": "New Zealand",
  "160": "Nicaragua",
  "161": "Niger",
  "162": "Nigeria",
  "163": "Niue",
  "164": "Norfolk Island",
  "165": "Northern Mariana Islands",
  "166": "Norway",
  "167": "Oman",
  "168": "Pakistan",
  "169": "Palau",
  "170": "Palestine, State of",
  "171": "Panama",
  "172": "Papua New Guinea",
  "173": "Paraguay",
  "174": "Peru",
  "175": "Philippines",
  "176": "Pitcairn",
  "177": "Poland",
  "178": "Portugal",
  "179": "Puerto Rico",
  "180": "Qatar",
  "181": "Réunion",
  "182": "Romania",
  "183": "Russian Federation",
  "184": "Rwanda",
  "185": "Saint Barthélemy",
  "186": "Saint Helena, Ascension and Tristan da Cunha",
  "187": "Saint Kitts and Nevis",
  "188": "Saint Lucia",
  "189": "Saint Martin",
  "190": "Saint Pierre and Miquelon",
  "191": "Saint Vincent and the Grenadines",
  "192": "Samoa",
  "193": "San Marino",
  "194": "Sao Tome and Principe",
  "195": "Saudi Arabia",
  "196": "Senegal",
  "197": "Serbia",
  "198": "Seychelles",
  "199": "Sierra Leone",
  "200": "Singapore",
  "201": "Sint Maarten",
  "202": "Slovakia",
  "203": "Slovenia",
  "204": "Solomon Islands",
  "205": "Somalia",
  "206": "South Africa",
  "207": "South Georgia and the South Sandwich Islands",
  "208": "South Sudan",
  "209": "Spain",
  "210": "Sri Lanka",
  "211": "Sudan",
  "212": "Suriname",
  "213": "Svalbard and Jan Mayen",
  "214": "Swaziland",
  "215": "Sweden",
  "216": "Switzerland",
  "217": "Syrian Arab Republic",
  "218": "Taiwan, Province of China[a]",
  "219": "Tajikistan",
  "220": "Tanzania, United Republic of",
  "221": "Thailand",
  "222": "Timor-Leste",
  "223": "Togo",
  "224": "Tokelau",
  "225": "Tonga",
  "226": "Trinidad and Tobago",
  "227": "Tunisia",
  "228": "Turkey",
  "229": "Turkmenistan",
  "230": "Turks and Caicos Islands",
  "231": "Tuvalu",
  "232": "Uganda",
  "233": "Ukraine",
  "234": "United Arab Emirates",
  "235": "United Kingdom of Great Britain and Northern Ireland",
  "236": "United States of America",
  "237": "United States Minor Outlying Islands",
  "238": "Uruguay",
  "239": "Uzbekistan",
  "240": "Vanuatu",
  "241": "Venezuela",
  "242": "Viet Nam",
  "243": "Virgin Islands",
  "244": "Virgin Islands",
  "245": "Wallis and Futuna",
  "246": "Western Sahara",
  "247": "Yemen",
  "248": "Zambia",
  "249": "Zimbabwe"
};