import React, { useEffect, useState } from "react";
import filterIcon from "../../assets/filterIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ScrollToTop from "../helpers/scroll-to-top";
import { ExploreApi, MasterApi, TraderSearchResult } from "../../api";
import AuthService from "../../services/auth-service";
import { CountryList } from "../../helpers/countryList";
import PortfolioCarousel from "./portfolio-carousel";

const Explore = () => {
  const filterValues = {
    productsCount: 9, searchTerm: null, skip: 0, take: 5, sortBy: "Newest"
  }
  const [activeAlphabet, setActiveAlphabet] = useState("");
  const [availableAlphabets, setAvailableAlphabets] = useState([]);
  const [sortByOptions, setSortByOptions] = useState([]);
  const [selectedSortByOptions, setSelectedSortByOptions] = useState();
  const [traders, setTraders] = useState<TraderSearchResult>();
  const [filterOptions, setFilterOptions] = useState(filterValues);

  const token = AuthService.getBearerToken();
  const exploreApi = new ExploreApi(token);

  const getAllProducts = async () => {
    try{
      const response: TraderSearchResult = await exploreApi.apiExploreTradersGet(
        filterOptions.searchTerm, 
        filterOptions.skip, 
        filterOptions.take,
        filterOptions.sortBy,
      );
      console.log(response)
      setTraders(response);
    }
    catch(error: any) {
      console.log(error)
    }
  }

  const getAllAvailableAlphabets = async () => {
    try{
      const response: string[] = await exploreApi.apiExploreAvailableTraderAlphabetsGet();
      setAvailableAlphabets(response);
    }
    catch(error: any) {
      console.log(error)
    }
  }

  const selectAlphabet = (item) => {
    setActiveAlphabet(item)
    filterOptions["searchTerm"] = item;
    setFilterOptions({...filterOptions});
    getAllProducts();
  }

  const alphabeticSearch = () => {
    const alphabetList = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
    return alphabetList.map((item,i) => <li 
      key={i} 
      className={`inline-block ${activeAlphabet === item ? "active-li" : "p-2"} 
      ${availableAlphabets.includes(item) ? "cursor-pointer" : "text-gray-100 cursor-not-allowed"}`}>
      <button 
        className={`${availableAlphabets.includes(item) ? "cursor-pointer" : "text-gray-100 cursor-not-allowed"}`}
        disabled={!availableAlphabets.includes(item)}
        onClick={() => selectAlphabet(item)}>{item}</button>
    </li>)
  }
  
  const getSortByOptions = async () => {
    const api = new MasterApi();
    const response = await api.apiMasterSortByOptionsGet();
    setSortByOptions(response);
  }

  const changeSortByOptions = (event) => {
    setSelectedSortByOptions(event.target.value)
    filterOptions["sortBy"] = event.target.value;
    setFilterOptions({...filterOptions})
    getAllProducts();
  }

  const search = (event) => {
    if(event.keyCode === 13) {
      filterOptions["searchTerm"] = event.target.value;
      setActiveAlphabet("");
      setFilterOptions({...filterOptions});
      getAllProducts();
    }
  }

  const loadMore = () => {
    filterOptions["take"] = filterOptions["take"] + 5;
    setFilterOptions({...filterOptions})
    getAllProducts();
  }

  useEffect(() => {
    getSortByOptions();
    getAllProducts();
    getAllAvailableAlphabets();
  }, [])

  const renderProductList = () => {
    if (!traders?.results?.length)
    {
      return <></>
    }
    return(<div>
      {traders.results.map((trader, index)=> <section className="my-20 text-gray-500 md:mx-36 pb-20 border-b-2 border-gray-200" key={index}>
        <div className="grid md:grid-cols-4 gap-6 text-left">
          <div className="col-span-1">
            <div className="block bg-white w-auto">
              <div className="">
                <h3 className="text-xll font-bold text-gray-500">{trader.legalName}</h3>
                <h5 className="text-mdl font-medium text-gray-000">{CountryList[trader.countryOfRegistration]}</h5>
                <div className="pt-8 md:pt-16">
                  <p className="text-mdl mb-6 font-bold">
              About {trader.legalName}
                  </p>
                  <p className="text-mdl mb-2 font-medium">
                    {trader.description}
                  </p>
                </div>
                <div className="pt-10">
                  <p className="text-mdl mb-4 font-medium">
              Range Starts from
                  </p>
                  <p className="text-xl font-bold">
                  ${trader.rangeStartsFrom}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <PortfolioCarousel portfolios={trader.portfolios} id={index} />
          </div>
        </div>
        <div className="mt-10 flex justify-between">
          <Link className="flex items-center" to="/explore/collections" state={{trader}}>
            <button type="button" 
              className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-medium text-xs leading-tight rounded-full">
              Explore Deals
            </button>
          </Link>
          {trader.portfolios.length > 3 && <div 
            className="font-bold text-lg text-gray-500 mr-5 cursor-pointer" 
            data-bs-target={`#carouselExampleCaptions${index}`}
            data-bs-slide="next">
              See more 
            <FontAwesomeIcon 
              icon={faArrowRightLong} 
              className="w-4 h-4 p-1 absolute" 
            /> 
          </div>}
        </div>
      </section>)} 
    </div>);
  }

  return (<div>
    <div className="flex justify-center">
      <div className="m-10 xl:w-96 relative">
        <input
          type="search"
          className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-200
        bg-white bg-clip-padding
        border border-solid border-gray-300
        placeholder-gray-200
        rounded-full
        transition
        ease-in-out
        m-0
        focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none
      "
          id="search"
          placeholder="Search"
          onKeyDown={search}
        />
        <div className="m-0.5">
          <span 
            aria-hidden="true">
            <FontAwesomeIcon 
              icon={faMagnifyingGlass} 
              className="w-4 h-4 absolute right-4 cursor-pointer bottom-3.5" 
            />
          </span> 
        </div>
      </div>
    </div>
    <div className="m-10 md:mx-36">
      <div className="md:flex justify-end">
        <ul className="text-mdl font-semiBold">
          {alphabeticSearch()}
        </ul>
        <img src={filterIcon} alt="filter block" className="md:mr-7 md:ml-32" />
        <div className="md:w-56">
          <select className="form-select appearance-none w-full
                  form-control block px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                  text-base
                  font-normal
                  bg-white bg-no-repeat
                  focus:bg-white" 
          aria-label="type"
          defaultValue="0"
          onChange={changeSortByOptions}
          >
            <option value="" disabled hidden selected className="text-gray-200">Sort by:</option>
            {sortByOptions.map((item, index)=>
              <option value={item} key={index}><span className="text-gray-200">{selectedSortByOptions === item ? "Sort by:" : ""}</span> {item} </option>
            )} 
          </select>
        </div>
      </div>
    </div>
    <div>
      {renderProductList()}
    </div>
    <div className="flex justify-center">
      <div className="m-10 xl:w-96 relative text-center">
        <button type="button" className="load-more" onClick={loadMore}>Load More</button>
      </div>
    </div>
    <ScrollToTop />
  </div>);   
}

export default Explore;