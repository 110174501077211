import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import InnerElementRequirement from "../inner-element-requirement";
import { ListedRqmtOrSvc } from "../../../../api";
import { DateTime } from "luxon";
import { CountryList } from "../../../../helpers/countryList";

interface ITableRow {
  requirement: ListedRqmtOrSvc;
  onDelete: () => void;
}

const TableRow = ({requirement, onDelete}: ITableRow) => {
  const [showInnerTable, setShowInnerTable] = useState(false);

  const toggleShowInnerTable = () => {
    setShowInnerTable(!showInnerTable)
  }

  return (
    <React.Fragment>
      <tr className="border-b text-sm font-regular text-gray-500">
        <td className="py-4 text-center" onClick={toggleShowInnerTable} aria-hidden="true">
          { showInnerTable ? <FontAwesomeIcon 
            icon={faAngleDown} 
            className="w-4 h-4 cursor-pointer" 
          /> : <FontAwesomeIcon 
            icon={faAngleRight} 
            className="w-4 h-4 cursor-pointer" />}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {DateTime.fromISO(requirement.publishedOn?.toString()).toLocaleString()}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {CountryList[requirement.country]}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
        15000
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
        Material Type
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
        Requirment
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
        Lorem ipsum dolor sit
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {DateTime.fromISO(requirement.deadline?.toString()).toLocaleString()}
        </td>
      </tr>
      {showInnerTable && <tr>
        <td colSpan={9}>
          <InnerElementRequirement onDelete={onDelete} />
        </td>
      </tr>}
    </React.Fragment>
  );   
}

export default TableRow;