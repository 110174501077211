// import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import {   CreateApi, CreateAssetRequest, EditApi, EditAssetRequest } from "../../../../api";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CountryList } from "../../../../helpers/countryList";
import AuthService from "../../../../services/auth-service";
import { AssetContext } from "../../../../services/asset-context";
import SelectAssetCategory from "../helper-components/select-asset-category";
import FileUpload from "../helper-components/file-upload";
import WarehouseLocations from "../helper-components/warehouse-locations";
import CertificateUpload from "../helper-components/certificate-upload";
import Errors from "../../../helpers/errors";
import FileService from "../../../../services/file-service";
import ErrorBlock from "../../../helpers/error-block";
import SuccessBlock from "../../../helpers/success-block";
import Util from "../../../../helpers/util";
import FieldsForSalesType from "./fields-for-sales-type";
import { useLocation } from "react-router-dom";

const CreateAssets = () => {

  type AssetCreateForm = {
    assetName: string;
    assetType: string;
    assetCategory: string;
    assetSubCategory: string;
    assetDescription: string;
    country: number;
    metalPurity: string;
    units: string;
    qty: number;
    amount: number;
    currency: string;
    salesType: number;
    price: number;
    testingLab: string;
    documentType: string;
    paymentTerms: string;
    moq: number;
    shippingMethod: string;
    deliveryTerm: string;
    warehouseLocation: string;
    pincode: string;
    confirm: boolean;
    hsn: number;
  };

  const token = AuthService.getBearerToken();
  const createApi = new CreateApi(token);
  const editApi = new EditApi(token);
  const location = useLocation();

  const defaultValues = {
    assetName: "",
    assetType: "",
    assetCategory: "",
    assetSubCategory: "",
    assetDescription: "",
    country: 0,
    metalPurity: "",
    units:"",
    qty: 0,
    amount: 0,
    currency: "",
    salesType: 0,
    price: 0,
    testingLab: "",
    documentType: "",
    paymentTerms: "",
    moq: 0,
    shippingMethod: "",
    deliveryTerm: "",
    warehouseLocation: "",
    pincode: "",
    confirm: false,
    hsn: 0,
  }

  const { metalPurities, 
    units ,documentTypes,
    shippingMethods,deliveryTerms, allCountries
  } = useContext(AssetContext);
  const [selectedCertificateFile, setSelectedCertificateFile] = useState<any>();
  const [oldCertificateFile, setOldCertificateFile] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [hsn, setHsn] = useState<number>();
  const [qty, setQty] = useState<number>();
  const [duration, setDuration]  = useState<number>();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [asset, setAsset] = useState<any>({});
  const [assetId, setAssetId] = useState("");
  const [placeholder, setPlaceholder] = useState("Weight");
  
  const validationSchema = Yup.object().shape({
    assetName: Yup.string().required("Asset Name is required"),
    assetDescription: Yup.string().required("Asset Description is required"),
    assetType: Yup.string().required("Asset Type is required"),
    assetCategory: Yup.string().required("Asset Category is required"),
    assetSubCategory: Yup.string().required("Asset Sub Category is required"),
    country: Yup.number().required("Country is required"),
    metalPurity: Yup.string().required("Value is required"),
    units: Yup.string().required("Unit is required"),
    qty: Yup.number().default(0).required("qty is required"),
    currency: Yup.string().required("Currency is required"),
    salesType: Yup.number().required("Sales type is required"),
    documentType: Yup.string().required("Document type is required"),
    testingLab: Yup.string().required("Testing lab is required"),
    paymentTerms: Yup.string().when("salesType",{ is: (salesType) => salesType == 1 || salesType == 2, then: Yup.string().required("Payment term is required")}),
    shippingMethod: Yup.string().required("Shipping method is required"),
    deliveryTerm: Yup.string().required("Delivery term is required"),
    warehouseLocation: Yup.string().required("Country is required"),
    pincode: Yup.string().required("Pincode is required"),
    confirm: Yup.boolean().required("The terms and conditions must be accepted.").oneOf([true], "The terms and conditions must be accepted."),
    moq: Yup.number()
      .default(0)
      .when("salesType",{ is: (salesType) => salesType == 0 || salesType == 1, then: Yup.number().required("MOQ is required")})
      .max(Yup.ref("qty"), "MOQ cannot be greated than Weight/Quantity"),
    price: Yup.number().default(0).required("Amount is required"),
  });

  const { register,reset, handleSubmit, formState: {errors} } = useForm<AssetCreateForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onSubmit"
  });

  //0 = Outright    1 = Bid    2 = MadeToOrder    3 = Inventory
  const getOptionsBySalesType = (data: any) => {
    if (data.salesType === 0) {
      return {
        outrightOptions: { 
          moq: +data.moq,
          amount: {
            currencyType: data.currency,
            amount: data.price,
          },
        },
      }
    }
    if (data.salesType === 1) {
      return {
        bidOptions: {
          paymentTerms: data.paymentTerms,
          minPrice: {
            currencyType: data.currency,
            amount: data.price,
          },
          durationDays: duration || 0, 
        },
      }
    }
    if (data.salesType === 2) {
      return {
        madeToOrderOptions: {
          paymentTerms: data.paymentTerms,
          moq: +data.moq,
          durationDays: duration || 0, 
          amount: {
            currencyType: data.currency,
            amount: data.price,
          },
        },
      }
    }
    if (data.salesType === 3) {
      return {
        inventoryOptions: {
          amount: {
            currencyType: data.currency,
            amount: data.price,
          },
        },
      }
    }
  }

  const onSubmit = async (data: AssetCreateForm) => {
    Util.backToTop();
    let certificateUrl;
    let files = [];
    if(selectedCertificateFile.name) {
      certificateUrl = await FileService.fileUpload(selectedCertificateFile);
    }
    else {
      certificateUrl = oldCertificateFile;
    }

    const newFiles = selectedFiles.filter( file => !!file.fileData );
    const uploadedFiles = await FileService.uploadFiles(newFiles);
    
    const preUploaded = selectedFiles.filter( file => !file.fileData );
    files.push(...preUploaded);
    files.push(...uploadedFiles);

    const body:CreateAssetRequest = {
      name: data.assetName,
      description: data.assetDescription,
      categorisation: {
        type: data.assetType,
        category: data.assetCategory,
        subcategory: data.assetSubCategory,
      },
      hsn: hsn,
      country: data.country,
      purity: data.metalPurity,
      salesType: data.salesType,
      documentType: data.documentType,
      certificate: certificateUrl || "",
      files: files,
      testingLab: data.testingLab,
      otherInformation: { 
        shippingMethod: data.shippingMethod,
        deliveryTerms: data.deliveryTerm,
        warehouseLocation: +data.warehouseLocation,
        pin: +data.pincode,
      },
      stock: {
        unit: data.units,
        qty: data.qty,
      },
      agreeToTermsAndConditions: data.confirm,
    }

    setErrorMessage("");

    if(assetId) {
      const finalBody: EditAssetRequest = {...body, ...getOptionsBySalesType(data), assetId}
      await editApi.apiEditAssetPost(finalBody).then((response) => {
        setSuccessMessage("Asset updation successful")
        return response;
      }).catch(async (error) => {
        console.log("in error")
        const e = await error.json();
        console.log(e);
        setErrorMessage("Asset updation failed");
      }) 
    }
    else {
      const finalBody: CreateAssetRequest = {...body, ...getOptionsBySalesType(data)}
      await createApi.apiCreateAssetPost(finalBody).then((response) => {
        setSuccessMessage("Asset creation successful")
        return response;
      })
        .catch(async (error) => {
          console.log(error.json());
          setErrorMessage("Asset creation failed");
        }) 
  
    }
  };

  const getEditValueBasedOnSalesType = (data: EditAssetRequest) => {
    if (data.salesType === 0) {
      return { 
        moq: data.outrightOptions.moq,
        amount: data.outrightOptions.amount.amount,
        currency: data.outrightOptions.amount.currencyType,
      }
    }
    if (data.salesType === 1) {
      return { 
        paymentTerms: data.bidOptions.paymentTerms,
        amount: data.bidOptions.minPrice.amount,
        currency: data.bidOptions.minPrice.currencyType,
      }
    }
    if (data.salesType === 2) {
      return { 
        paymentTerms: data.madeToOrderOptions.paymentTerms,
        moq: data.madeToOrderOptions.moq,
        duration: +data.madeToOrderOptions.durationDays,
        amount: data.madeToOrderOptions.amount.amount,
        currency: data.madeToOrderOptions.amount.currencyType,
      }
    }
    if (data.salesType === 3) {
      return { 
        amount: data.inventoryOptions.amount.amount,
        currency: data.inventoryOptions.amount.currencyType,
      }
    }
  }

  const getEditValues = async (data) => {
    const editAsset: EditAssetRequest = await editApi.apiEditCurrentAssetGet(data.id);
    console.log(editAsset)

    setAssetId(data.id)
    const certificateUrl = editAsset.certificate;
    const certificate = certificateUrl.substring(certificateUrl.lastIndexOf("/") + 1);
    setSelectedCertificateFile(certificate)
    setSelectedFiles(editAsset.files)
    setOldCertificateFile(certificateUrl);

    const salesTypeValues = getEditValueBasedOnSalesType(editAsset);
    setDuration(salesTypeValues.duration)
    const newAsset = {
      assetName: editAsset.name,
      assetType: editAsset.categorisation.type,
      assetDescription: editAsset.description,
      assetCategory: editAsset.categorisation.category,
      assetSubCategory: editAsset.categorisation.subcategory,
      country: editAsset.country,
      metalPurity: editAsset.purity,
      units: editAsset.stock.unit,
      qty: +editAsset.stock.qty,
      currency: salesTypeValues.currency || "",
      salesType: editAsset.salesType,
      documentType: editAsset.documentType,
      testingLab: editAsset.testingLab,
      paymentTerms: salesTypeValues.paymentTerms,
      shippingMethod: editAsset.otherInformation.shippingMethod,
      deliveryTerm: editAsset.otherInformation.deliveryTerms,
      warehouseLocation: editAsset.otherInformation.warehouseLocation,
      pincode: editAsset.otherInformation.pin,
      confirm: false,
      moq: +salesTypeValues.moq,
      price: salesTypeValues.amount,
      duration: salesTypeValues.duration || 0
    };
    setAsset(newAsset);
  }

  const setPlaceholderBasedOnUnit = (unit: string) => {
    if (unit === "Number") {
      setPlaceholder("Quantity")
    }
    else {
      setPlaceholder("Weight")
    }
  }

  const onCancel = () => {
    reset(defaultValues)
    setSelectedFiles([])
    Util.backToTop();
  }

  useEffect(() => {
    if (!asset.name && location.state && location.state.type === "asset") {
      getEditValues(location.state.item)
    }
  },[])
  
  useEffect(() => {
    if(asset.assetName) {
      reset(asset);
    }
  }, [asset])

  return (<div className="mx-8">
    <ErrorBlock errorMessage={errorMessage} />
    <SuccessBlock successMessage={successMessage} />
    {/* <div className="">
      <h3 className="h3-m-xl-neutral-1000">Bulk Asset Upload</h3>
      <div className="mt-8 w-full md:w-3/12">
        <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000-row"> Select CSV file to upload <span className="text-primary-008 flex px-2">Download Template</span> </label>
        <label htmlFor="dropzone-file-certificate" className="label-n-lg-gray-200-row">
          <div className="flex flex-col items-end">
            <p className=" text-sm text-gray-500 dark:text-gray-400">
              <FontAwesomeIcon 
                icon={faArrowUpFromBracket} 
                className="my-1 w-4 h-4 text-gray-500 cursor-pointer" 
              />
            </p>
          </div>
          <input id="dropzone-file-certificate" type="file" className="hidden" />
        </label>
      </div>
    </div> */}
    {/* <hr className="border-gray-300 my-10" /> */}
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <div>
        <h3 className="h3-m-xl-neutral-1000">Individual Asset Upload</h3>
        <div className="mt-8 w-full md:w-3/12">
          <label htmlFor="customer-country" className="label-b-sm-neutral-1000"> Merge/create from exiting assets </label>
          <select className="select-n-lg-gray-500" 
            aria-label="type"
            defaultValue="0"
            id="customer-country"
          >
            <option value={1} key={1}>Select Asset </option>
          </select>
        </div>
      </div> */}
      <div>
        {/* <h3 className="text-lg font-bold text-neutral-1000">Or Create new</h3> */}
        <SelectAssetCategory register={register} errors={errors} hsn={hsn} setHsn={setHsn} asset={asset} />
      </div>
      <div className="mt-10">
        <h3 className="h3-b-sm-neutral-1000">Asset Description</h3>
        <div className="mb-3 mt-8">
          <textarea
            className="textarea-n-mdl-gray-500"
            id="exampleFormControlTextarea1"
            rows={3}
            placeholder="Input your product requirement here, be as specific as you can about what you need"
            {...register("assetDescription")}
            maxLength={2000}
          />
          <Errors value={errors.assetDescription} />
        </div>
      </div>
      <div>
        <h3 className="h3-m-xl-neutral-1000">Asset Detail</h3>
        <div className="mt-8 w-full md:w-3/12">
          <label htmlFor="customer-country" className="label-b-sm-neutral-1000">Country of Origin</label>
          <select className="select-n-lg-gray-500" 
            aria-label="type"
            defaultValue="0"
            id="customer-country"
            {...register("country")}
          >
            <option disabled={true} selected value="">
                    Country of Origin*
            </option>
            {allCountries.map((item, index)=>
              <option value={item} key={index}>{CountryList[item]} </option>
            )} 
          </select>
          <Errors value={errors.country} />
        </div>
      </div>
      <div className="mt-2">
        <div className="md:flex w-full md:space-x-6">
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="purity" className="label-b-sm-neutral-1000"> Purity </label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="purity"
              {...register("metalPurity")}
            >
              <option disabled={true} selected value="">
                    Metal Purity
              </option>
              {metalPurities.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )} 
            </select>
            <Errors value={errors.metalPurity} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="unit" className="label-b-sm-neutral-1000"> Unit </label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="unit"
              {...register("units")}
              onChange={(event) => {setPlaceholderBasedOnUnit(event.target.value)}}
            >
              <option disabled={true} selected value="">
                    Unit
              </option>
              {units.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )}
            </select>
            <Errors value={errors.units} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="weight" className="label-b-sm-neutral-1000"> {placeholder} </label>
            <input id="weight"
              type="number"
              placeholder={placeholder}
              {...register("qty",{
                valueAsNumber: true,
              })}
              onChange={(event) => setQty(parseInt(event.target.value))}
              value={qty}
              className="label-n-lg-gray-200-row" />
            <Errors value={errors.qty} />
          </div>
        </div>
      </div>
      <FieldsForSalesType register={register} errors={errors} duration={duration} setDuration={setDuration} salesTypeValue={asset?.salesType || 0} />
      <div className="mt-8">
        <h3 className="h3-m-xl-neutral-1000">Supporting Documents</h3>
        <div className="mt-8 w-full md:w-3/12">
          <label htmlFor="document-type" className="label-b-sm-neutral-1000">Select Document Type*</label>
          <select className="select-n-lg-gray-500" 
            aria-label="type"
            defaultValue="0"
            id="document-type"
            {...register("documentType")}
          >
            <option disabled={true} selected value="">
                    Document Type
            </option>
            {documentTypes.map((item, index)=>
              <option value={item} key={index}>{item} </option>
            )}
          </select>
          <Errors value={errors.documentType} />
        </div>
      </div>
      <CertificateUpload 
        register={register} 
        errors={errors} 
        setSelectedCertificateFile={setSelectedCertificateFile} 
        selectedCertificateFile={selectedCertificateFile}
      />
      <FileUpload selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} id="create-requirement" />
      <div className="mt-10">
        <h3 className="h3-m-xl-neutral-1000">Other Information</h3>
        <div className="md:flex w-full md:space-x-6">
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="shipping-method" className="label-b-sm-neutral-1000"> Shipping method* </label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="shipping-method"
              {...register("shippingMethod")}
            >
              <option disabled={true} selected value="">
                    Shipping Method
              </option>
              {shippingMethods.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )}
            </select>
            <Errors value={errors.shippingMethod} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="customer-state" className="label-b-sm-neutral-1000"> Delivery terms*</label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="customer-state"
              {...register("deliveryTerm")}
            >
              <option disabled={true} selected value="">
                Delivery terms
              </option>
              {deliveryTerms.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )}
            </select>
            <Errors value={errors.deliveryTerm} />
          </div>
        </div>
        <WarehouseLocations register={register} errors={errors} asset={asset} />
      </div>
      <div className="flex flex-col mb-6 mt-6">
        <div className="relative">
          <input 
            type="checkbox"
            id="exampleCheck2"
            {...register("confirm")}
            className="radio-n-xl-gray-300" />
          <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
        I agree to disply my company name on the quotation and it will be visible to Ethically members.
          </label>
          <Errors value={errors.confirm} />
        </div>
      </div>
      <div className="mb-20">
        <p className="mb-2">
          <button type="submit" className="submit-b-lg-neutral-000">Publish</button>
          <button type="button" className="cancel-b-lg-gray-500" onClick={onCancel}>Cancel</button>
        </p>
      </div>
    </form>
  </div>);   
}

export default CreateAssets;