import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/ethicallyLogo.svg";
import { UserContext } from "../../services/user-context";
import ExplorerItem from "./explorer-item";
import GetStartedItem from "./get-started-item";
import LikesAndCartItem from "./likes-and-cart-item";
import LoginItem from "./login-item";
import ProfileItem from "./profile-item";

const TopNavbar = () => {
  const { user, dashboard, getDashboard } = useContext(UserContext);
  const location = useLocation();
  const [selectedElement, setSelectedElement] = useState("");

  const path = location.pathname;
  var pathSplit = path.split("/")

  useEffect(() => {
    if(["like","cart"].includes(pathSplit[2])){
      setSelectedElement(pathSplit[2]);
    }
    else { 
      setSelectedElement("")
    }
  },[pathSplit])

  useEffect(()=> {
    getDashboard()
  },[])
  

  return(
    <nav className="
  w-full
  font-raleway
  font-regular
  flex flex-wrap
  items-center
  justify-between
  p-10
  py-6
  bg-neutral-000 
  shadow-lg
  navbar navbar-expand-lg navbar-light
  sticky top-0 z-50
  ">
      <div className="container-fluid w-full flex flex-wrap items-center justify-between">
        <button className="
      navbar-toggler
      border-0
      hover:shadow-none hover:no-underline
      bg-transparent
      focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline
    " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars"
            className="w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
            </path>
          </svg>
        </button>
        <Link className="flex items-center" to="/">
          <img
            src={logo}
            alt="Company logo"
            className="w-auto h-auto max-w-[200px] max-h-[60px]"
          />
        </Link>
        <div className="flex items-center relative">
          <div className="navbar-collapse flex-grow items-center" id="navbarSupportedContent">
            <ul className="navbar-nav flex flex-col list-style-none">
              <li className="nav-item mr-6 mt-2">
                <ExplorerItem />
              </li>
              { user && <>
                <li className="nav-item mr-6">
                  <LikesAndCartItem selectedElement={selectedElement} dashboard={dashboard} />
                </li>
                <li className="nav-item mt-2">
                  <ProfileItem />
                </li>
              </>}
              { !user && 
              <>
                <li className="nav-item mr-10 mt-2">
                  <GetStartedItem />
                </li>
                <li className="nav-item md-10 mt-2">
                  <LoginItem />
                </li>
              </>
              }
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )};

export default TopNavbar;
