import React from "react";
import AuthService from "../../../../services/auth-service";
import { EditAssetInLineRequest, ManageApi } from "../../../../api";

interface IConfirmPublishModal {
  assetID: string;
  stockStatus: number;
  openingStock: number;
  closingStock: number;
  assetStatus: number;
  countriesToPublishTo: Array<any>;
}

const ConfirmPublishModal = ({assetID, stockStatus, openingStock, closingStock, countriesToPublishTo}: IConfirmPublishModal) => {

  const token = AuthService.getBearerToken();
  const manageApi = new ManageApi(token);
  
  const publishItem = async () => {
    console.log(stockStatus);
    let countries = countriesToPublishTo.map(country => +country.value);
    
    const publishRequest: EditAssetInLineRequest = {
      assetId: assetID,
      stockStatus: ""+stockStatus,
      openingStock: openingStock,
      closingStock: closingStock,
      countriesToPublishTo: countries
    }
    try{
      await manageApi.apiManagePublishAssetPost(publishRequest);
    }
    catch(error: any) {
      console.log(error)
    }

    
    const myModalEl = document.getElementById("button-close");
    myModalEl.click();
  }
  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={`ModalConfirmPublish-${assetID}`} tabIndex={-1} aria-labelledby={`ModalConfirmPublish-${assetID}`} aria-modal="true" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current text-center">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4">
            <button type="button"
              id="button-close"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body relative m-6 space-y-4">
            <div className="my-5">
              <p className="mb-2">Are you sure you want to Publish this item?</p>
              <p className="mb-2">
                <button type="submit" className="submit-b-lg-neutral-000" onClick={publishItem}>Publish</button>
                <button type="button" className="cancel-b-lg-gray-500" data-bs-dismiss="modal">Cancel</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );   
}

export default ConfirmPublishModal;