import { faFacebookSquare, faInstagram, faLinkedinIn, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/ethicallyFooterLogo.png";

const Footer = () => (
  <footer className="text-center lg:text-left bg-gray-000">
    <div className="flex justify-center items-center lg:justify-between p-10 pt-6">
      <div className="mr-12">
        <a
          href="www"
          className="flex items-center h-full"
        >
          <img
            src={logo}
            alt="Company logo"
            className="sm:w-28 sm:h-28"
          />
        </a>
      </div>
      <div className="order-last">
        <div className="flex sm:justify-center">
          <a href="https://www.facebook.com/vonmuziris" className="w-8 h-8 text-center hover:text-white py-1 ml-2">
            <FontAwesomeIcon icon={faFacebookSquare} className="text-xl text-neutral-000" />
          </a>
          <a href="www" className="w-8 h-8 text-center hover:text-white py-1 ml-2">
            <FontAwesomeIcon icon={faInstagram} className="text-xl text-neutral-000" />
          </a>
          <a href="https://www.linkedin.com/company/von-muziris/" className="w-8 h-8 text-center hover:text-white py-1 ml-2">
            <FontAwesomeIcon icon={faLinkedinIn} className="text-xl text-neutral-000" />
          </a>
          <a href="www" className="w-8 h-8 text-center hover:text-white py-1 ml-2">
            <FontAwesomeIcon icon={faTwitter} className="text-xl text-neutral-000" />
          </a>
          <a href="www" className="w-8 h-8 text-center hover:text-white py-1 ml-2">
            <FontAwesomeIcon icon={faYoutube} className="text-xl text-neutral-000" />
          </a>
        </div>
      </div>
    </div>
    <div className="p-10 bg-white rounded-lg shadow md:flex md:items-center md:justify-between text-neutral-000 font-medium text-lg">
      <span className="sm:text-center">
        <span>© <a href="www" className="hover:underline">Von Muziris Oy ®</a>2022</span> <span className="px-8">|</span> 
        <span><a href="www" className="hover:underline">admin@ethicallyplus.com</a></span>
      </span>
      <ul className="flex flex-wrap items-center mt-3 sm:mt-0">
        <li>
          <Link to="/faq" className="mr-4 hover:underline md:mr-6 ">FAQ</Link>
        </li>
        <li>
          <a href="www" className="mr-4 hover:underline md:mr-6">Privacy Statement</a>
        </li>
        <li>
          <Link to="/terms-of-use" className="mr-4 hover:underline md:mr-6">Terms of Use</Link>
        </li>
        <li>
          <a href="https://www.ethicallyplus.com/contact" className="hover:underline">Contact Us</a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
