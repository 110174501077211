import React from "react";
import { Link } from "react-router-dom";

interface ISideNavElement {
    name: string;
    icon: string;
    selected?: boolean;
    goTo: string;
  }

const SideNavElement = (data : ISideNavElement) => {
  const selectedStyle = "bg-primary-700 rounded-l rounded-full";
  return (
    <li>
      <Link className="relative" to={data.goTo}>
        <a className={`flex items-center text-md py-4 px-6 h-12 overflow-hidden font-bold text-gray-500 text-ellipsis whitespace-nowrap hover:text-gray-900 hover:bg-gray-100 hover:rounded-l hover:rounded-full space-x-2.5 ${data.selected? selectedStyle : ""}`} href="#!" data-mdb-ripple="true" data-mdb-ripple-color="dark">
          <img src={data.icon} alt={data.name} />
          <span>{data.name}</span>
        </a>
      </Link>
    </li>
  );
}

export default SideNavElement;