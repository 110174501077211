import { yupResolver } from "@hookform/resolvers/yup";
import React, {  useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { CreateApi, CreateServiceRequest } from "../../../../api";
import Util from "../../../../helpers/util";
import { AssetContext } from "../../../../services/asset-context";
import AuthService from "../../../../services/auth-service";
import FileService from "../../../../services/file-service";
import ErrorBlock from "../../../helpers/error-block";
import Errors from "../../../helpers/errors";
import SuccessBlock from "../../../helpers/success-block";
import FileUpload from "../helper-components/file-upload";
import SelectAssetCategory from "../helper-components/select-asset-category";

const CreateService = () => {

  type ServiceCreateForm = {
    category: string;
    assetType: string;
    assetCategory: string;
    assetSubCategory: string;
    assetDescription: string;
    amount: number;
    currencyType: string;
    agreeToTermsAndConditions: boolean;
    serviceCostType:string;
    serviceDuration: string;
  }

  const token = AuthService.getBearerToken();
  const createApi = new CreateApi(token);

  const { currencies } = useContext(AssetContext);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [hsn, setHsn] = useState<number>();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [duration, setDuration]  = useState(0);

  const validationSchema = Yup.object().shape({
    assetType: Yup.string().required("Asset Type is required"),
  });

  const onSubmit = async (data: ServiceCreateForm) => {
    const files = await FileService.uploadFiles(selectedFiles);

    const body:CreateServiceRequest = {
      description: data.assetDescription,
      type: data.assetType,
      category: data.assetCategory,
      subcategory: data.assetSubCategory,
      serviceCost: {
        currencyType: data.currencyType,
        amount: data.amount,
        type: data.serviceCostType,
      },
      agreeToTermsAndConditions: data.agreeToTermsAndConditions,
      files: files,
      hsn: hsn.toString(),
      serviceDurationDays: duration
    }

    setErrorMessage("");

    const responseBody = await createApi.apiCreateServicePost(body).then((response) => {
      console.log("in success");
      setSuccessMessage("Asset creation successful")
      return response;
    })
      .catch(async (error) => {
        console.log("in error" + error);
        setErrorMessage("Asset creation failed");
      }) 

    Util.backToTop();
    console.log(responseBody)
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ServiceCreateForm>({
    resolver: yupResolver(validationSchema)
  });

  return (<div className="m-8">
    <ErrorBlock errorMessage={errorMessage} />
    <SuccessBlock successMessage={successMessage} />
    <div className="mt-10">
      <h3 className="h3-m-xl-neutral-1000">Describe the service you are offering</h3>
      <h3 className="h3-b-sm-neutral-1000">Please be as detailed as possible:</h3>
      <div className="mb-3 mt-8">
        <textarea
          className="
        textarea-n-mdl-gray-500
      "
          id="exampleFormControlTextarea1"
          rows={3}
          placeholder="Input your product requirement here, be as specific as you can about what you need"
          {...register("assetDescription")}
        />
      </div>
    </div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <FileUpload selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} id="create-service" />
      <div className="mt-10">
        <h3 className="h3-m-xl-neutral-1000">Choose a category</h3>
        <SelectAssetCategory register={register} errors={errors} hsn={hsn} setHsn={setHsn} showCategory={true} asset={null} />
      </div>
      <div className="mt-10">
        <h3 className="text-mdl font-bold text-neutral-1000">Add service cost</h3>
        <div className="md:flex w-full md:space-x-6">
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Cost </label>
            <input id="warehouse-address"
              type="text"
              placeholder="Cost"
              {...register("amount")}
              className="label-n-lg-gray-200-row" />
            <Errors value={errors.amount} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="warehouse-country" className="label-b-sm-neutral-1000"> Currency </label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="warehouse-country"
              {...register("currencyType")}
            >
              <option disabled={true} selected value="">
                    Currency
              </option>
              {currencies.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )} 
            </select>
            <Errors value={errors.currencyType} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="warehouse-state" className="label-b-sm-neutral-1000"> Type</label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="warehouse-state"
              {...register("serviceCostType")}
            >
              <option value={1} key={1}>Per hour</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="text-mdl font-bold text-neutral-1000">Once you offer the service, what will be the service duration?</h3>
        <div className="md:flex w-full md:space-x-6">
          <div className="mt-8 w-full md:w-4/12 space-x-2">
            <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Select </label>
            <button type="button" className={duration === 1 ? "submit-b-lg-neutral-000" : "cancel-b-lg-gray-500"} onClick={() =>(setDuration(1))}>24 Hours</button>
            <button type="button" className={duration === 3 ? "submit-b-lg-neutral-000" : "cancel-b-lg-gray-500"} onClick={() =>(setDuration(3))}>3 Days</button>
            <button type="button" className={duration === 7 ? "submit-b-lg-neutral-000" : "cancel-b-lg-gray-500"} onClick={() =>(setDuration(7))}>7 Days</button>
            <p className="inline-block px-6 py-2 text-neutral-1000 font-bold text-sm leading-tight rounded-full">Or</p>
          </div>
        
          <div className="mt-8 w-full md:w-1/12">
            <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Other </label>
            <input id="warehouse-address"
              type="number"
              placeholder="Type here"
              className="label-n-lg-gray-200-row" onChange={(event) => {setDuration(+event.target.value)}} />
          </div>
        </div>
      </div>
      <div className="flex flex-col mb-6 mt-8">
        <div className="relative">
          <input 
            type="checkbox"
            id="exampleCheck2"
            {...register("agreeToTermsAndConditions")}
            className="radio-n-xl-gray-300" />
          <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
        I agree to disply my company name on the quotation and it will be visible to Ethically members.
          </label>
          <Errors value={errors.agreeToTermsAndConditions} />
        </div>
      </div>
      <div className="flex flex-col mb-6 mt-8">
        <p className="text-gray-200 font-medium text-sm">
      Disclaimer: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </p>
      </div>
      <div className="mb-20">
        <p className="mb-2">
          <button type="submit" className="submit-b-lg-neutral-000">Publish</button>
          <button type="button" className="cancel-b-lg-gray-500 mr-2.5">Send offer</button>
          <button type="button" className="cancel-b-lg-gray-500">Cancel</button>
        </p>
      </div>
    </form>
  </div>);   
}

export default CreateService;