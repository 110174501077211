import React, { useEffect, useState } from "react";
import TableRow from "./table-row";
import filterIcon from "../../../../assets/filterIcon.svg";
import { ListedPortfolio, ServiceProviderApi } from "../../../../api";
import AuthService from "../../../../services/auth-service";

const PortfolioTable = () => {

  const token = AuthService.getBearerToken();
  const serviceProviderApi = new ServiceProviderApi(token);

  const [portfolios, setPortfolios] = useState<ListedPortfolio[]>([]);

  const getPortfolios = async () => {
    try{
      const response: ListedPortfolio[] = await serviceProviderApi.apiServiceProviderListPortfoliosGet();
      setPortfolios(response)
    }
    catch(error: any) {
      console.log(error)
    }
  }

  const onDelete = () => {
    getPortfolios();
  }

  useEffect(() => {
    getPortfolios();
  }, [])

  return (
    <div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 md:-mx-6 lg:-mx-8 ">
          <div className="flex flex-row justify-between space-x-1 text-gray-500 font-bold text-sm px-2">
            <div className="flex">
              <button type="button" className="flex px-6 py-2 space-x-1 leading-tight rounded-full" data-bs-toggle="modal" data-bs-target="#ModalAddToPortfolio">
                <span>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7 9.5L7 16.5H9V9.5L16 9.5V7.5L9 7.5V0.5H7L7 7.5L0 7.5V9.5L7 9.5Z" fill="#161416"/>
                  </svg>
                </span>
                <span>Add Portfolio</span>
              </button>
              <button type="button" className="flex px-6 py-2 space-x-1 leading-tight rounded-full">
                <span className="-mt-1">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 9.5V19.5H8V9.5H16ZM14.5 3.5H9.5L8.5 4.5H5V6.5H19V4.5H15.5L14.5 3.5ZM18 7.5H6V19.5C6 20.6 6.9 21.5 8 21.5H16C17.1 21.5 18 20.6 18 19.5V7.5Z" fill="#161416"/>
                  </svg>
                </span>
                <span>Delete</span>
              </button>
              <button type="button" className="flex px-6 py-2 space-x-1 leading-tight rounded-full" data-bs-toggle="modal" data-bs-target="#CreateFromExistingAssetModal">
                <span className="-mt-1">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 9V5L21 12L14 19V14.9C9 14.9 5.5 16.5 3 20C4 15 7 10 14 9Z" fill="#161416"/>
                  </svg>
                </span>
                <span>Send</span>
              </button>
            </div>
            <div className="flex px-2">
              <ul className="text-mdl font-semiBold">
              </ul>
              <img src={filterIcon} alt="filter block" className="md:mr-7 md:ml-32" />
              <div className="md:w-56 filter">
                <select className="form-select appearance-none w-full
                  form-control block px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                  text-base
                  font-normal
                  bg-white bg-no-repeat
                  focus:bg-white" 
                aria-label="type"
                defaultValue="0"
                >
                  <option value="" disabled hidden selected className="text-gray-200">Sort by:</option>
                  <option value={""} >Value</option>
                </select>
              </div>
            </div>
          </div>
          <div className="py-2 inline-block w-full px-4">
            <div className="">
              <table className="w-full">
                <thead className="border-b-2 border-gray-000 bg-primary-006 text-sm font-bold text-gray-500">
                  <tr>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                
                    </th> 
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                      <div className="form-check">
                        <input className="form-check-input appearance-none h-4 w-4 border bg-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" />
                      </div>
                    </th> 
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Code
                    </th>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Portfolio Name
                    </th>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Status
                    </th>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Transaction Status
                    </th>
                    <th scope="col" className="px-6 pt-12 pb-4"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    portfolios.map((portfolio, index) => (
                      <TableRow portfolio={portfolio} key={index} onDelete={onDelete} />
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );   
}

export default PortfolioTable;