import React, { useContext, useState } from "react";
import { CreateApi, CreateRequirementsRequest } from "../../../../api";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CountryList } from "../../../../helpers/countryList";
import AuthService from "../../../../services/auth-service";
import { AssetContext } from "../../../../services/asset-context";
import Errors from "../../../helpers/errors";
import SelectAssetCategory from "../helper-components/select-asset-category";
import CertificateFileUpload from "../helper-components/certificate-upload/certificate-file-upload";
import FileService from "../../../../services/file-service";
import Util from "../../../../helpers/util";
import ErrorBlock from "../../../helpers/error-block";
import SuccessBlock from "../../../helpers/success-block";

const CreateRequirement = () => {
  type AssetRequirementForm = {
    assetName: string;
    assetType: string;
    assetCategory: string;
    assetSubCategory: string;
    sourcingDestination: number;
    productRequirement: string;
    quantity: number;
    units: string;
    paymentTerms: string;
    paymentMethods: string;
    min: number;
    max: number;
    currency: string;
    openForNegotiation: boolean;
    shippingMethod: string;
    deliveryTerms: string;
    leadTime: string;
    agreeToTermsAndConditions: boolean;
  };

  const token = AuthService.getBearerToken();
  const createApi = new CreateApi(token);
  const [hsn, setHsn] = useState<number>();
  const [selectedCertificateFile, setSelectedCertificateFile] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {  eligibleCountries, units,
    paymentTerms,shippingMethods,deliveryTerms, paymentMethods
  } = useContext(AssetContext);

  const validationSchema = Yup.object().shape({
    assetName: Yup.string().required("Asset Name is required"),
    assetType: Yup.string().required("Asset Type is required"),
    assetCategory: Yup.string().required("Asset Category is required"),
    assetSubCategory: Yup.string().required("Asset Sub category is required"),
    sourcingDestination: Yup.number().required("Sourcing Destination is required"),
    productRequirement: Yup.string().required("Product requirement is required"),
    quantity: Yup.number().required("Quantity is required"),
    units: Yup.string().required("Unit is required"),
    paymentTerms: Yup.string().required("Payment terms is required"),
    paymentMethods: Yup.string().required("Payment methods is required"),
    min: Yup.number().required("Min is required"),
    max: Yup.number().required("Max is required"),
    shippingMethod: Yup.string().required("Shipping method is required"),
    deliveryTerms: Yup.string().required("Delivery terms is required"),
    leadTime: Yup.string().required("Lead time is required"),
    agreeToTermsAndConditions: Yup.boolean().required("The terms and conditions must be accepted.").oneOf([true], "The terms and conditions must be accepted."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AssetRequirementForm>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: AssetRequirementForm) => {
    console.log(data)

    const certificateUrl = await FileService.fileUpload(selectedCertificateFile);

    const body:CreateRequirementsRequest = {
      name: data.assetName,
      productRequirement: data.productRequirement,
      type: data.assetType,
      category: data.assetCategory,
      subcategory: data.assetSubCategory,
      sourcingDestination: data.sourcingDestination,
      unit: data.units,
      attachment: certificateUrl || "",
      paymentTerms: data.paymentTerms,
      quantity: data.quantity,
      paymentMethods: data.paymentMethods,
      priceRange: { 
        currency: "EUR",
        min: data.min,
        max: data.max
      },
      openForNegotiation: data.openForNegotiation,
      shippingMethod: data.shippingMethod,
      deliveryTerms: data.deliveryTerms,
      leadTime: data.leadTime,
      agreeToTermsAndConditions: data.agreeToTermsAndConditions,
    }

    const responseBody = await createApi.apiCreateRequirementsPost(body).then((response) => {
      console.log("in success");
      setSuccessMessage("Asset creation successful")
      return response;
    })
      .catch(async (error) => {
        console.log("in error" + error);
        setErrorMessage("Asset creation failed");
      }) 

    Util.backToTop();
    console.log(responseBody)
  };

  return (<div className="m-8">
    <ErrorBlock errorMessage={errorMessage} />
    <SuccessBlock successMessage={successMessage} />
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-10">
        <h3 className="h3-m-xl-neutral-1000">About the product</h3>
        <SelectAssetCategory register={register} errors={errors} hsn={hsn} setHsn={setHsn} asset={null} />
        <div>
          <div className="mt-8 w-full md:w-3/12">
            <label htmlFor="customer-country" className="label-b-sm-neutral-1000">Sourcing destination</label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="customer-country"
              {...register("sourcingDestination")}
            >
              <option disabled={true} selected value="">
                    Country of Registration*
              </option>
              {eligibleCountries.map((item, index)=>
                <option value={item} key={index}>{CountryList[item]} </option>
              )} 
            </select>
            <Errors value={errors.sourcingDestination} />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="h3-b-sm-neutral-1000">Product requirement</h3>
        <div className="mb-3 mt-2">
          <textarea
            className="
        textarea-n-mdl-gray-500
      "
            id="exampleFormControlTextarea1"
            rows={3}
            placeholder="Input your product requirement here, be as specific as you can about what you need"
            {...register("productRequirement")}
          />
          <Errors value={errors.productRequirement} />
        </div>
      </div>
      <CertificateFileUpload setSelectedCertificateFile={setSelectedCertificateFile} id="create-requirement" selectedCertificateFile={selectedCertificateFile} />
      <div className="mt-10">
        <h3 className="h3-m-xl-neutral-1000">Other requirements</h3>
        <div className="md:flex w-full md:space-x-6">
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="customer-pincode" className="label-b-sm-neutral-1000"> Quantity* </label>
            <input id="customer-pincode"
              type="text"
              className="label-n-lg-gray-200-row" 
              {...register("quantity")}
            />
            <Errors value={errors.quantity} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="customer-state" className="label-b-sm-neutral-1000"> Unit* </label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="customer-state"
              {...register("units")}
            >
              <option disabled={true} selected value="">
                    Unit
              </option>
              {units.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )}
            </select>
            <Errors value={errors.units} />
          </div>
        </div>
        <div className="md:flex w-full md:space-x-6">
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="customer-state" className="label-b-sm-neutral-1000"> Payment terms* </label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="customer-state"
              {...register("paymentTerms")}
            >
              <option disabled={true} selected value="">
                    Payment terms
              </option>
              {paymentTerms.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )}
            </select>
            <Errors value={errors.paymentTerms} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="customer-state" className="label-b-sm-neutral-1000"> Payment methods*</label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="customer-state"
              {...register("paymentMethods")}
            >
              <option disabled={true} selected value="">
                    Payment Methods
              </option>
              {paymentMethods.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )}
            </select>
            <Errors value={errors.paymentMethods} />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="text-xl font-bold text-neutral-1000">Preferred price range</h3>
        <div className="md:flex w-full md:space-x-6">
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000">Min*</label>
            <input id="warehouse-address"
              type="text"
              placeholder="Min"
              className="label-n-lg-gray-200-row" 
              {...register("min")}
            />
            <Errors value={errors.min} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Max*</label>
            <input id="warehouse-address"
              type="text"
              placeholder="Max"
              className="label-n-lg-gray-200-row" 
              {...register("max")}
            />
            <Errors value={errors.max} />
          </div>
          <div className="mt-8 w-full md:w-3/12">
            <div className="relative flex md:mt-12 space-x-2">
              <span className="flex font-bold text-sm text-neutral-1000">EUR</span>
              <input 
                type="checkbox"
                id="exampleCheck2"
                className="radio-n-xl-gray-300"
                {...register("openForNegotiation")}
              />
              <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
                Open for negotiation
              </label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 className="h3-m-xl-neutral-1000 mt-10">Shipping and delivery</h3>
      </div>
      <div className="mt-2">
        <div className="md:flex w-full md:space-x-6">
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="warehouse-country" className="label-b-sm-neutral-1000"> Shipping method* </label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="warehouse-country"
              {...register("shippingMethod")}
            >
              <option disabled={true} selected value="">
                    Payment terms
              </option>
              {shippingMethods.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )}
            </select>
            <Errors value={errors.shippingMethod} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="warehouse-state" className="label-b-sm-neutral-1000"> Delivery terms* </label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="warehouse-state"
              {...register("deliveryTerms")}
            >
              <option disabled={true} selected value="">
                Delivery terms
              </option>
              {deliveryTerms.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )}
            </select>
            <Errors value={errors.deliveryTerms} />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="md:flex w-full md:space-x-6">
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Lead time* </label>
            <input id="warehouse-address"
              type="text"
              placeholder="Amount"
              className="label-n-lg-gray-200-row" 
              {...register("leadTime")}
            />
            <Errors value={errors.paymentMethods} />
          </div>
        </div>
      </div>

      <div className="flex flex-col mb-6 mt-8">
        <div className="relative">
          <input 
            type="checkbox"
            id="exampleCheck2"
            className="radio-n-xl-gray-300"
            {...register("agreeToTermsAndConditions")}
          />
          <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
        I agree to disply my company name on the quotation and it will be visible to Ethically members.
          </label>
          <Errors value={errors.agreeToTermsAndConditions} />
        </div>
      </div>
      <div className="flex flex-col mb-6 mt-8">
        <p className="text-gray-200 font-medium text-sm">
      Disclaimer: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </p>
      </div>
      <div className="mb-20">
        <p className="mb-2">
          <button type="submit" className="submit-b-lg-neutral-000">Publish</button>
          <button type="button" className="cancel-b-lg-gray-500 mr-2.5">Send offer</button>
          <button type="button" className="cancel-b-lg-gray-500">Cancel</button>
        </p>
      </div>
    </form>
  </div>);   
}

export default CreateRequirement;