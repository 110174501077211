import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const ExplorerItem = () => {
  return(
    <Link to="/explore" className="pp-nav-item">
      <FontAwesomeIcon icon={faMagnifyingGlass} className="px-2 text-md" />
      <span className="font-bold text-lg">Explorer</span>
    </Link>
  )};

export default ExplorerItem;
