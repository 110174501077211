import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Errors from "../../../helpers/errors";
import AuthService from "../../../../services/auth-service";
import { AuthResult, UserApi, UserChangePasswordRequest } from "../../../../api";
import ErrorBlock from "../../../helpers/error-block";
import SuccessBlock from "../../../helpers/success-block";
import { UserContext } from "../../../../services/user-context";

const UserCredentials = () => {  
  type UserSubmitForm = {
    username: string;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  };

  const token = AuthService.getBearerToken();
  const userApi = new UserApi(token);

  const { user } = useContext(UserContext);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("User Name is required"),
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .required("Password is required")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .max(40, "Password must not exceed 40 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword"), null], "Confirm Password does not match")
  });


  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: UserSubmitForm) => {
    setErrorMessage("");
    setSuccessMessage("");
    const authenticationDetails = await AuthService.getAuthDetails();
    const request: UserChangePasswordRequest = {
      email: data.username,
      password: data.oldPassword,
      newPassword: data.newPassword,
      tokens: authenticationDetails.authentication as AuthResult
    };
    await userApi.apiUserChangePasswordPost(request).then((response) => {
      setSuccessMessage("Password update successful")
      return response;
    })
      .catch(async (error) => {
        console.log(error.json());
        setErrorMessage("Password update failed");
      }) 
  };

  return (<div className="m-8">
    <ErrorBlock errorMessage={errorMessage} />
    <SuccessBlock successMessage={successMessage} />
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <h3 className="h3-m-xl-neutral-1000">User name*</h3>
        <input id="legalNameFormInput"
          type="text"
          value={user.email}
          placeholder="User name will be here"
          {...register("username")}
          readOnly
          className="read form-control block w-full md:w-3/12 mt-8 px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 m-0 read-only:border-gray-300 focus:border-gray-300 focus:outline-none" />
        <Errors value={errors.username} />
      </div>
      <div className="mt-8">
        <h3 className="h3-m-xl-neutral-1000">Change password</h3>
        <div className="mt-8 w-full md:w-3/12">
          <label htmlFor="old-password" className="label-b-sm-neutral-1000"> Old Password* </label>
          <input id="old-password"
            type="password"
            {...register("oldPassword")}
            className="label-n-lg-gray-200-row" />
          <Errors value={errors.oldPassword} />
        </div>
        <div className="mt-8 w-full md:w-3/12">
          <label htmlFor="new-password" className="label-b-sm-neutral-1000"> New Password* </label>
          <input id="new-password"
            type="password"
            {...register("newPassword")}
            className="label-n-lg-gray-200-row" />
          <Errors value={errors.newPassword} />
        </div>
        <div className="mt-8 w-full md:w-3/12">
          <label htmlFor="confirm-password" className="label-b-sm-neutral-1000"> Confirm Password* </label>
          <input id="confirm-password"
            type="password"
            {...register("confirmPassword")}
            className="label-n-lg-gray-200-row" />
          <Errors value={errors.confirmPassword} />
        </div>
      </div>
      <div className="mt-10">
        <p className="mb-2">
          <button type="submit" className="submit-b-lg-neutral-000">Save</button>
          <button type="button" className="cancel-b-lg-gray-500">Cancel</button>
        </p>
      </div>
      <div className="flex flex-col mb-6 mt-8">
        <div className="relative">
          <h3 className="text-lg font-medium text-gray-500">If you want to delete your account please click here <a href="www" className="underline cursor-pointer font-bold">Delete Account</a></h3>
        </div>
      </div>
    </form>
  </div>);   
}

export default UserCredentials;