import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ListedPortfolio } from "../../../../api";
import InnerElementPortfolio from "../inner-element-portfolio";
import { Link } from "react-router-dom";

interface ITableRow {
  portfolio: ListedPortfolio;
  onDelete: () => void;
}

const TableRow = ({portfolio, onDelete}: ITableRow) => {
  const [showInnerTable, setShowInnerTable] = useState(false);
  const [portfolioStatus, setPortfolioStatus] = useState<string>(portfolio.transactionStatus);
  
  const toggleShowInnerTable = () => {
    setShowInnerTable(!showInnerTable)
  }
  
  const onDeleteAsset = () => {
    setShowInnerTable(false);
    onDelete();
  }

  return (
    <React.Fragment>
      <tr className="border-b text-sm font-regular text-gray-500">
        <td className="py-4 text-center" onClick={toggleShowInnerTable} aria-hidden="true">
          { showInnerTable ? <FontAwesomeIcon 
            icon={faAngleDown} 
            className="w-4 h-4 cursor-pointer" 
          /> : <FontAwesomeIcon 
            icon={faAngleRight} 
            className="w-4 h-4 cursor-pointer" />}
        </td>
        <td className="text-center">
          <div className="form-check">
            <input className="form-check-input appearance-none h-4 w-4 border bg-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" />
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {portfolio.code}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {portfolio.name}
        </td>
        <td className="py-4 whitespace-nowrap">
          <select className="form-select appearance-none
                  form-control block w-28 px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                  text-base
                  font-normal
                  bg-white bg-no-repeat
                  focus:bg-white" 
          aria-label="type"
          defaultValue="0"
          id="customer-country"
          value={portfolioStatus}
          onChange={(event) => setPortfolioStatus(event.target.value)}
          >
            <option value="published" key={0}>Published</option>
            <option value="only_for_me" key={0}>Only for me</option>
            
          </select>
        </td>

        <td className="px-6 py-4 whitespace-nowrap">
          {portfolio.transactionStatus}
        </td>
        <td className="px-6 py-4 whitespace-nowrap flex space-x-2 font-medium">
          <Link to={{pathname: `/homepage/create/${portfolio.id}`}} state={ {item: portfolio, type: "portfolio"} }>
            <button 
              type="button" 
              className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold leading-tight rounded-full mr-2.5" 
            >
            Edit
            </button>
          </Link>
        </td>
      </tr>
      {showInnerTable && <tr>
        <td colSpan={9}>
          <InnerElementPortfolio portfolio={portfolio} key={portfolio.id} onDelete={onDeleteAsset} />
        </td>
      </tr>}
    </React.Fragment>
  );   
}

export default TableRow;