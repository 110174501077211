import React from "react";
import PortfolioTable from "../portfolio-table";

const MyPortFolios = () => {
  return (
    <div className="justify-center m-10">
      <PortfolioTable />
    </div>
  );   
}

export default MyPortFolios;