import React from "react";
import ProductCard from "./product-card";

const ProductCards = () => {
  return (<>
    <div className="grid md:grid-cols-2 gap-10">
      <ProductCard />
      <ProductCard />
    </div>
    <div className="flex justify-center">
      <div className="mt-10 xl:w-96 relative text-center">
        <button type="button" className="load-more">Load More</button>
      </div>
    </div>
  </>);
}

export default ProductCards;