import React from "react";
import { PortfolioPreview } from "../../../api";

interface IPortfolioProps {
  portfolios: PortfolioPreview[];
}

const PortfolioContent = ({portfolios}: IPortfolioProps) => {
  console.log(portfolios);
  return(
    <>
      <div
        className="grid md:grid-cols-3 gap-6">
        {portfolios.map((portfolio)=> {return portfolio.files.map((file, index)=>
          <div className="block bg-white w-auto" key={index}>
            <div className="overflow-hidden border-white mx-auto bg-white">
              <img key={index} src={file} alt={file} className="w-96 md:h-440" />
            </div>
          </div>
        )} )}
      </div>
    </>
  )};

export default PortfolioContent;
