import React from "react";
import Rating from "../../../helpers/rating";

interface IRating {
  totalRating: number;
  ratingCount: number;
  answersCount: number;
  productId: string;
}

const RatingBlock = ({totalRating = 0, ratingCount = 0, answersCount = 0, productId}: IRating) => {
  return (
    <div className="flex items-center">
      <Rating totalRating={totalRating} id="rating-block" productId={productId} />
      <div className="flex items-center font-bold text-lg text-neutral-1000">
        <span className="px-6">{ratingCount} ratings</span>
        <div className="flex space-x-px">
        </div>
        <div className="pl-2 leading-none underline cursor-pointer">
          {answersCount} answered questions
        </div>
      </div>
    </div>);
}

export default RatingBlock;