import { DateTime } from "luxon";
import React, { useState } from "react";
import { BidRequest, ProductDetailResult, SalesApi } from "../../../../api";
// import info from "../../../../assets/info-green.svg";
import { CountryList } from "../../../../helpers/countryList";
import AuthService from "../../../../services/auth-service";

interface IBidding {
  product: ProductDetailResult;
}

const Bidding = ({product}: IBidding) => {

  const [auctionQuantity, setAuctionQuantity] = useState(0);
  const [auctionAmount, setAuctionAmount] = useState(0);

  const token = AuthService.getBearerToken();
  const salesApi = new SalesApi(token);

  const onSubmitAuction = async () => {
    if (auctionQuantity > 0 && auctionAmount > 0) {
      const requestBody: BidRequest = {
        productId: product.id,
        qty: auctionQuantity,
        offerPrice: {
          amount: auctionAmount,
          currencyType: product.bidOptions.reservePrice.currencyType
        }
      }
      try{ 
        const response = await salesApi.apiSalesBidPost(requestBody);
        console.log(response)
      }
      catch(error: any) {
        console.log(error)
      }
    }
  };

  const outRightOptions = () => {
    return (<div>
      <div className="mt-6 leading-relaxed bg-primary-800 p-4">
        <p className="font-regular text-xl text-gray-500">{product.outrightOptions.stock} &nbsp; <span className="font-bold">available in stock</span></p>
        <p className="font-medium text-mdl text-gray-000 pt-2">You haven’t purchased this product yet</p>
      </div>
      <div className="mt-6 leading-relaxed">
        <p className="font-bold text-xl text-gray-500">`price` <span className="font-medium text-mdl text-primary-900">{product.outrightOptions.moq} MOQs</span></p>
        {/* <p className="font-medium text-sm text-gray-500 pt-2 flex">Retail estimate  &nbsp;
          <span className="font-bold flex">${product.retailEstimate.min} - ${product.retailEstimate.max}
            <span className="ml-2 mt-1 cursor-pointer"> 
              <img src={info} alt="info" />
            </span>
          </span>
        </p> */}
        <p className="font-medium text-sm text-gray-500 pt-2">Its great time to buy!</p>
      </div>
      <hr className="border-gray-300 mt-6" />
      <p className="font-medium text-mdl text-neutral-1000 pt-4 underline cursor-pointer">
         Price exclude the platform fee and (shipping) costs. 
      </p>
      <p className="font-medium text-sm text-gray-500 pt-2">Seller’s location: &nbsp; 
        <span className="font-bold">{CountryList[product.traderInfo.country]}</span>
      </p>
      <p className="font-medium text-sm text-gray-500 pt-2">Platform fee: 5% of the price</p>
      <div className="my-6 leading-relaxed flex space-x-8">
        <div className="w-6/12">
          <p className="font-bold text-mdl text-gray-500">Enter quantity</p>
          <div className="mt-4 flex flex-row space-x-2">
            <input
              type="text"
              className="form-control block w-5/12 px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800 border-red-500"
              id="workEmailFormInput"
            />
            <span className="font-bold text-mdl text-primary-900 w-7/12 mt-1">Available quantity `nil`</span>
          </div>
        </div>
      </div>
      <div className="w-5/12">
        <p className="font-bold text-mdl text-gray-500">Buy directly</p>
        <div className="mt-4 flex flex-row space-x-2">
          <input
            type="text"
            className="form-control block w-1/2 px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800 border-red-500"
            id="workEmailFormInput"
          />
          <button className="w-1/2 px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold text-lg leading-tight rounded-full mr-2.5">Buy now</button>
        </div>
      </div>
      <hr className="border-gray-300 mt-6" />
      <p className="font-regular text-mdl my-4 text-gray-500">{product.nBuyersAlsoBoughtThis} Buyers also bought this</p>
    </div>)
  }

  const madeToOrderOptions = () => {
    return (<div>
      <div className="mt-6 leading-relaxed bg-primary-800 p-4">
        <p className="font-regular text-xl text-gray-500">
          <span className="font-bold">
            Delivered in {product.madeToOrderOptions.deliveryEstimate.min} to {product.madeToOrderOptions.deliveryEstimate.max} week</span></p>
        <p className="font-medium text-mdl text-gray-000 pt-2">You haven’t purchased this product yet</p>
      </div>
      <div className="mt-6 leading-relaxed">
        <p className="font-bold text-xl text-gray-500">`price` <span className="font-medium text-mdl text-primary-900">{product.outrightOptions.moq} MOQs</span></p>
        {/* <p className="font-medium text-sm text-gray-500 pt-2 flex">Retail estimate  &nbsp;
          <span className="font-bold flex">${product.retailEstimate.min} - ${product.retailEstimate.max}
            <span className="ml-2 mt-1 cursor-pointer"> 
              <img src={info} alt="info" />
            </span>
          </span>
        </p> */}
        <p className="font-medium text-sm text-gray-500 pt-2">Its great time to buy!</p>
      </div>
      <hr className="border-gray-300 mt-6" />
      <p className="font-medium text-sm text-gray-500 pt-2">Seller’s location: &nbsp; 
        <span className="font-bold">{CountryList[product.traderInfo.country]}</span>
      </p>
      <p className="font-medium text-sm text-gray-500 pt-2">Platform fee: 5% of the price</p>
      <div className="my-6 leading-relaxed flex space-x-8">
        <div className="w-6/12">
          <p className="font-bold text-mdl text-gray-500">Enter quantity</p>
          <div className="mt-4 flex flex-row space-x-2">
            <input
              type="text"
              className="form-control block w-5/12 px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800 border-red-500"
              id="workEmailFormInput"
            />
            <span className="font-bold text-mdl text-primary-900 w-7/12 mt-1">Available quantity `nil`</span>
          </div>
        </div>
      </div>
      <div className="w-full mb-8">
        <p className="font-bold text-mdl text-gray-500">Place order directly</p>
        <div className="mt-4 flex flex-row space-x-2">
          <input
            type="text"
            className="form-control block w-4/12 px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800 border-red-500"
            id="workEmailFormInput"
          />
          <button className="w-4/12 px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold text-lg leading-tight rounded-full mr-2.5">Place order now</button>
          <button className="w-4/12 px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold text-lg leading-tight rounded-full mr-2.5">Send Inquiry</button>
        </div>
      </div>
      <hr className="border-gray-300 mt-6" />
      <p className="font-regular text-mdl my-4 text-gray-500">{product.nBuyersAlsoBoughtThis} Buyers also bought this</p>
    </div>)
  }

  const bidOptions = () => {
    return (<div>
      <div className="mt-6 leading-relaxed bg-primary-800 p-4">
        <p className="font-regular text-xl text-gray-500">Closes in <span className="font-bold"> {product.bidOptions.bidCloseDateTime && DateTime.fromISO(product.bidOptions.bidCloseDateTime.toString()).toLocaleString()}</span></p>
        <p className="font-medium text-mdl text-gray-000 pt-2">You haven’t bid on this lot yet.</p>
      </div>
      <div className="mt-6 leading-relaxed">
        <p className="font-bold text-xl text-gray-500">
          Current bid {product.bidOptions.currentBid.currencyType} {product.bidOptions.currentBid.amount} 
          <span className="font-medium text-mdl text-primary-900">No reserve price</span>
        </p>
        {/* <p className="font-medium text-sm text-gray-500 pt-2 flex">Retail estimate  &nbsp;
          <span className="font-bold flex">${product.retailEstimate.min} - ${product.retailEstimate.max}
            <span className="ml-2 mt-1 cursor-pointer"> 
              <img src={info} alt="info" />
            </span>
          </span>
        </p> */}
        <p className="font-medium text-sm text-gray-500 pt-2">Its great time to bid!</p>
        <p className="font-medium text-sm text-gray-500 pt-2">The current bid is still under the estimate.</p>
      </div>
      <hr className="border-gray-300 mt-6" />
      <div className="my-6 leading-relaxed">
        <p className="font-bold text-mdl text-gray-500">Next minimum bid {product.bidOptions.nextMinimumBid?.currencyType} {product.bidOptions.nextMinimumBid?.amount} </p>
        <p className="font-medium text-mdl text-neutral-1000 pt-2 underline cursor-pointer">
          Bids exclude the auction fee and (shipping) costs. 
        </p>
        <p className="font-medium text-sm text-gray-500 pt-2">Seller’s location: &nbsp; 
          <span className="font-bold">{CountryList[product.traderInfo.country]}</span>
        </p>
        <p className="font-medium text-sm text-gray-500 pt-2">Auction fee: {product.bidOptions.auctionFeePercentage}% of the winning bid</p>
      </div>
      {/* <div className="my-6 leading-relaxed">
        <p className="font-bold text-mdl text-gray-500">Quick bid</p>
        <div className="space-x-2 mt-4">
          <button type="button" className="inline-block px-6 py-2 border-2 border-gray-500 text-gray-500 font-bold text-lg leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">$25</button>
          <button type="button" className="inline-block px-6 py-2 border-2 border-gray-500 text-gray-500 font-bold text-lg leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">$27</button>
          <button type="button" className="inline-block px-6 py-2 border-2 border-gray-500 text-gray-500 font-bold text-lg leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">$29</button>
        </div>
      </div> */}
      <div className="my-6 leading-relaxed flex space-x-8">
        <div className="w-6/12">
          <p className="font-bold text-mdl text-gray-500">Enter quantity</p>
          <div className="mt-4 flex flex-row space-x-2">
            <input
              type="number"
              className="form-control block w-5/12 px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800 border-red-500"
              id="workEmailFormInput"
              value={auctionQuantity}
              onChange={(event) => setAuctionQuantity(+event.target.value)}
            />
            <span className="font-bold text-mdl text-primary-900 w-7/12 mt-1">Available quantity `nil`</span>
          </div>
        </div>
      </div>
      <div className="my-6 leading-relaxed flex space-x-8">
        <div className="w-5/12">
          <p className="font-bold text-mdl text-gray-500">Bid directly</p>
          <div className="mt-4 flex flex-row space-x-2">
            <input
              type="text"
              className="form-control block w-1/2 px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800 border-red-500"
              id="workEmailFormInput"
              value={auctionAmount}
              onChange={(event) => setAuctionAmount(+event.target.value)}
            />
            <button 
              className="w-1/2 px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold text-lg leading-tight rounded-full mr-2.5"
              onClick={onSubmitAuction}
            >Place bid</button>
          </div>
        </div>
        {/* <div className="w-7/12">
          <p className="font-bold text-mdl text-gray-500 flex">Place an automatic bid 
            <span className="ml-2 mt-1 cursor-pointer"> 
              <img src={info} alt="info" />
            </span></p>
          <div className="mt-4 flex flex-row space-x-2">
            <input
              type="text"
              className="form-control block w-1/2 px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800 border-red-500"
              id="workEmailFormInput"
            />
            <button className="w-1/2 px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold text-lg leading-tight rounded-full mr-2.5">Automatic bid</button>
          </div>
        </div> */}
      </div>
    </div>)
  }

  const inventoryOptions = () => {
    return (<></>)
  }

  return (
    <>
      {product.saleType === 0 && outRightOptions()}
      {product.saleType === 1 && bidOptions()}
      {product.saleType === 2 && madeToOrderOptions()}
      {product.saleType === 3 && inventoryOptions()}
    </>
  );
}

export default Bidding;