import React from "react";
import gems from "../../../../assets/gems.jpg";

const InnerElement = () => {
  return (
    <div className="m-8">
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="flex flex-row space-x-6">
            <div className="flex w-1/4">
              <img src={gems} alt="first block" className="h-72 w-full md:w-96" />
            </div>
            <div className="w-1/4 space-y-4">
              <p className="font-bold text-xl text-neutral-1000">
                Artisan Link Earring
              </p>
              <p className="font-medium text-sm text-gray-500">
              Seller name will be here
              </p>
              <p className="font-medium text-sm text-gray-500">
              Asset ID: #123456
              </p>
              <p className="font-medium text-sm text-gray-500">
              Beautiful artisan earring crafted in 18 Karat Yellow Gold
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );   
}

export default InnerElement;