import React from "react";
import { QuestionAndAnswers } from "../../../../../api";
import AddAnswer from "./add-answer";
interface IQAndAs {
  questionAndAnswers: QuestionAndAnswers[];
  getQuestionAndAnswers: () => void
}

const QAndA = (data : IQAndAs) => {
  return (<div>
    {data.questionAndAnswers.map((item,i) => <div key={i}>
      <div className="flex text-mdl mb-6">
        <div className="relative flex flex-col items-start w-1/12">
          Question:
        </div>
        <div className="relative flex flex-col items-start w-11/12">
          {item.question.subject}
        </div>
      </div>
      <div className="flex text-mdl mb-6">
        <div className="relative flex flex-col items-start w-1/12">
          Answer:
        </div>
        <div className="relative flex flex-col items-start w-6/12">
          {item.answer && item.answer.map((a,i)=> 
            <div className="relative flex flex-col items-start w-11/12 font-medium" key={i}>
              {a.body}
            </div>)}
        </div>
      </div>
      
      <div className="flex text-mdl">
        <div className="relative flex flex-col items-start w-1/12 mb-6">
        </div>
        <div className="relative flex flex-col items-start w-11/12 font-medium  mb-6">
          <AddAnswer question={item.question} id={i+""} getQuestionAndAnswers={data.getQuestionAndAnswers} />
        </div>
      </div>
    </div>)}
  </div>);
}

export default QAndA;