import React, { useEffect, useState } from "react";
import { CreateQuestionRequest, ProductApi, QuestionAndAnswers } from "../../../../api";
import AuthService from "../../../../services/auth-service";
import QAndA from "./q-and-a";
interface IQuestionAnswers {
  productId: string
}

const QuestionAnswers = ({productId}: IQuestionAnswers) => {

  const token = AuthService.getBearerToken();
  const productApi = new ProductApi(token);

  const [questionAndAnswers, setQuestionAndAnswers] = useState<QuestionAndAnswers[]>([]);
  const [question, setQuestion] = useState("");

  const addQuestion = async (event) => {
    try{
      const createQuestionRequest: CreateQuestionRequest = {
        subject: event.target.value,
        productId,
        messageTimestamp: new Date()
      }
      const response = await productApi.apiProductCreateQuestionPost(
        createQuestionRequest
      );
      console.log(response)
      setQuestion("");
      getQuestionAndAnswers();
    }
    catch(error: any) {
      console.log(error)
    }
  }

  const getQuestionAndAnswers = async () => {
    if(productId) {
      try{
        const response: QuestionAndAnswers[] = await productApi.apiProductQnAGet(
          productId
        );
        console.log(response)
        setQuestionAndAnswers(response);
        console.log(questionAndAnswers)
      }
      catch(error: any) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    getQuestionAndAnswers();
  }, [productId])


  return (<div className="font-bold text-neutral-1000 mb-10">
    <div className="mb-10">
      <h3 className="text-xl">Customer questions & answers</h3>
    </div>
    {questionAndAnswers.length > 0 && <QAndA questionAndAnswers={questionAndAnswers} getQuestionAndAnswers={getQuestionAndAnswers} />}
    {questionAndAnswers.length > 0 && <div className="text-start">
      <div className="font-bold text-lg text-gray-500 mr-5 underline cursor-pointer">See more </div>
    </div> }
    <div className="my-6 leading-relaxed flex space-x-8">
      <div className="w-6/12">
        <p className="font-bold text-sm text-neutral-1000">Add your question</p>
        <div className="flex flex-col mb-6">
          <div className="relative w-9/12">
            <input id="legalNameFormInput"
              type="text"
              value={question}
              onChange={(event) => {setQuestion(event.target.value)}}
              onKeyDown={event => {
                if (event.key === "Enter") {
                  addQuestion(event)
                }
              }}
              className="form-control block w-full px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800" />
            <div className="absolute flex border border-transparent right-0 top-0 h-full w-10">
              <div className="flex items-center justify-center z-10 text-lg h-full w-full group">
                <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.14857 2.0637L5.44 3.9037L1.14286 3.33228L1.14857 2.0637ZM5.43429 7.04656L1.14286 8.88656V7.61799L5.43429 7.04656ZM0.00571428 0.332275L0 4.33228L8.57143 5.47513L0 6.61799L0.00571428 10.618L12 5.47513L0.00571428 0.332275Z" fill="black"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default QuestionAnswers;