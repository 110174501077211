import React, { useEffect, useState } from "react";
import { CreateApi, CreatePortfolioRequest, EditApi, EditPortfolioRequest } from "../../../../api";
import AuthService from "../../../../services/auth-service";
import FileService from "../../../../services/file-service";
import ErrorBlock from "../../../helpers/error-block";
import SuccessBlock from "../../../helpers/success-block";
import PortfolioSingle from "./portfolio-single";
import * as Yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Errors from "../../../helpers/errors";
import { useLocation } from "react-router-dom";

const CreatePortfolio = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [errorFiles, setErrorFiles] = useState({});
  const [hasError, setHasError] = useState(false);
  const [portfolio, setPortfolio] = useState<any>({});
  const [portfolioId, setPortfolioId] = useState("");

  const location = useLocation();

  const token = AuthService.getBearerToken();
  const createApi = new CreateApi(token);
  const editApi = new EditApi(token);

  let globalId = 0;

  const formSchema = {
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    code: Yup.string().required("Id is required"),
  };
  
  const validationSchema = Yup.object().shape({
    portfolios: Yup
      .array()
      .of(Yup.object().shape(formSchema))
      .required("Must have fields")
      .min(1, "Minimum of 1 field"),
    agreeToTermsAndConditions: Yup.boolean().required("The terms and conditions must be accepted.").oneOf([true], "The terms and conditions must be accepted.")
  });

  const { register, control, reset, handleSubmit, formState: {errors} } = useForm({
    defaultValues: {
      portfolios: [{ name: "", description: "", code:"0" }],
      agreeToTermsAndConditions: false,
    },
    resolver: yupResolver(validationSchema),
    mode: "onSubmit"
  });

  const { fields, append } = useFieldArray(
    {
      control,
      name: "portfolios"
    }
  );

  const postCreatePortfolio = async (body: CreatePortfolioRequest) => {
    const responseBody = await createApi.apiCreatePortfolioPost(body).then((response) => {
      setSuccessMessage("Portfolio creation successful")
      return response;
    })
      .catch(async (error) => {
        console.log("in error" + error);
        setErrorMessage("Portfolio creation failed");
      }) 
    console.log(responseBody);
  }

  const postEditPortfolio = async (body: EditPortfolioRequest) => {
    const responseBody = await editApi.apiEditPortfolioPost(body).then((response) => {
      setSuccessMessage("Portfolio update successful")
      return response;
    })
      .catch(async (error) => {
        console.log("in error" + error);
        setErrorMessage("Portfolio update failed");
      }) 
    console.log(responseBody);
  }


  const createPortfolioBody = async (portfolio, agreeToTermsAndConditions, selectedFilesParam) => {
    let files = [];
    console.log(selectedFilesParam)
    if (portfolioId) {
      const newFiles = selectedFilesParam.filter( file => !!file.fileData );
      const uploadedFiles = await FileService.uploadFiles(newFiles);
      const preUploaded = selectedFilesParam.filter( file => !file.fileData );
      files.push(...preUploaded);
      files.push(...uploadedFiles);
    }
    else {
      files = await FileService.uploadFiles(selectedFilesParam);
    }
    
    return {
      portfolioName: portfolio.name,
      description: portfolio.description,
      agreeToTermsAndConditions: agreeToTermsAndConditions,
      files: files,
      publishAsNFT: portfolio.publishAsNFT
    }
  }

  const validateFiles = (data) => {
    data.portfolios.forEach((portfolio) => {
      if(!selectedFiles[portfolio.id] || selectedFiles[portfolio.id].length === 0) {
        errorFiles[portfolio.id] = "files required";
        setErrorFiles({...errorFiles})
        setHasError(true);
      }
    });
  }

  const onSubmit = async (data) => {
    setErrorFiles({})
    validateFiles(data)
    if(hasError) {
      return;
    }

    if(portfolioId) {
      const body = await createPortfolioBody(portfolio, data.agreeToTermsAndConditions, selectedFiles[portfolio.code]);
      postEditPortfolio({...body, portfolioId});
    }
    else {
      await Promise.all(data.portfolios.map(async (portfolio) => {
        const body :CreatePortfolioRequest = await createPortfolioBody(portfolio, data.agreeToTermsAndConditions, selectedFiles[portfolio.id])
        postCreatePortfolio(body);
      }));
    }
  }

  const getEditValues = async (data) => {
    const editPortfolio: EditPortfolioRequest = await editApi.apiEditCurrentPortfolioGet(data.code);
    let filesLocation = {};
    filesLocation[editPortfolio.portfolioId] = editPortfolio.files;
    setSelectedFiles(filesLocation)

    const newPortfolio = {
      name: editPortfolio.portfolioName,
      description: editPortfolio.description,
      code: editPortfolio.portfolioId,
    }
    setPortfolioId(editPortfolio.portfolioId)
    setPortfolio(newPortfolio)
  }

  useEffect(() => {
    if (!portfolio.name && location.state && location.state.type === "portfolio") {
      getEditValues(location.state.item)
    }
  },[])

  useEffect(() => {
    if(portfolioId) {
      const newValue = {portfolios: [portfolio]};
      reset(newValue);
    }
  }, [portfolio])

  return (<div className="m-8">
    <ErrorBlock errorMessage={errorMessage} />
    <SuccessBlock successMessage={successMessage} />
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-10">
        <h3 className="h3-m-xl-neutral-1000">Describe the Portfolio</h3>
        <h3 className="h3-b-sm-neutral-1000">Please be as detailed as possible:</h3>
      </div>
      {fields.map((item, index) => {
        return (
          <PortfolioSingle 
            portfolio={item} 
            key={index} 
            id={index} 
            register={register} 
            errors={errors} 
            selectedFiles={selectedFiles} 
            setSelectedFiles={setSelectedFiles}
            errorFiles={errorFiles}
          />
        );
      })}
        
      {!portfolioId && <div className="flex items-center py-4 mt-6">
        <button type="button" onClick={() => {
          append({ name: "", description: "", code: ++globalId + "", });
        }} className="flex" >
          <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="48" height="48" rx="24" fill="#161416"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M23 25L23 32H25V25L32 25V23L25 23V16H23L23 23L16 23V25L23 25Z" fill="white"/>
            </svg>
          </span>
          <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold mt-2">
            <span>Add More</span>
          </span>
        </button>
        <div className="flex-grow h-px bg-gray-500"></div>
      </div>}
      <div className="flex flex-col mb-6 mt-8">
        <div className="relative">
          <input 
            type="checkbox"
            {...register("agreeToTermsAndConditions")}
            id="exampleCheck2"
            className="radio-n-xl-gray-300" />
          <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
        I agree to disply my company name on the quotation and it will be visible to Ethically members.
          </label>
          <Errors value={errors.agreeToTermsAndConditions} />
        </div>
      </div>
      <div className="flex flex-col mb-6 mt-8">
        <p className="text-gray-200 font-medium text-sm">
      Disclaimer: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </p>
      </div>
      <div className="mb-20">
        <p className="mb-2">
          <button type="submit" className="submit-b-lg-neutral-000">Publish</button>
          <button type="button" className="cancel-b-lg-gray-500">Cancel</button>
        </p>
      </div>
    </form> 
  </div>);   
}

export default CreatePortfolio;