import React from "react";
// import diamonds from "../../../../assets/diamonds.jpg";
// import metal from "../../../../assets/metal.jpg";
// import designs from "../../../../assets/designs.jpg";
// import Product from "../../product";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MoreFromMaker = () => {
  return (<div className="mb-10">
    <div className="mb-10">
      <h3 className="text-xl font-bold text-neutral-1000">More from this maker</h3>
    </div>
    <div className="col-span-3">
      <div className="grid md:grid-cols-4 gap-4">
        {/* <Product image={diamonds} header="Recycled Paper Dolphin..." innerText="Log in or create account to view WSP" />
        <Product image={metal} header="Artisan Link Earring" innerText="Log in or create account to view WSP" />
        <Product image={designs} header="Recycled Paper Reindee..." innerText="Log in or create account to view WSP" />
        <Product image={diamonds} header="Recycled Paper Dolphin..." innerText="Log in or create account to view WSP" /> */}
      </div>
    </div>
    <div className="mt-10 text-end">
      <div className="font-bold text-lg text-gray-500 mr-5">See more Collections 
        <FontAwesomeIcon 
          icon={faArrowRightLong} 
          className="w-4 h-4 p-1 absolute cursor-pointer" 
        /> 
      </div>
    </div>
  </div>);
}

export default MoreFromMaker;