import { yupResolver } from "@hookform/resolvers/yup";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Address, CompanyDescription, MasterApi, ProfileApi, UpdateCompanyDescription, UserApi } from "../../../../api";
import * as Yup from "yup";
import Errors from "../../../helpers/errors";
import { CountryList } from "../../../../helpers/countryList";
import AuthService from "../../../../services/auth-service";
import Warehouse from "./warehouse";
import SingleFileUpload from "../../create/helper-components/single-file-upload";
import FileService from "../../../../services/file-service";
import Util from "../../../../helpers/util";
import ErrorBlock from "../../../helpers/error-block";
import SuccessBlock from "../../../helpers/success-block";

const CompanyDescriptionBlock = () => {

  const [companyDescription, setCompanyDescription] = useState<Partial<CompanyDescription>>({});
  const [warehouses, setWarehouses] = useState<Partial<Address[]>>([]);
  const [companyLogo, setCompanyLogo] = useState<any>({});
  const [coverImage, setCoverImage] = useState<any>({});
  const [defaultSelectedGroups, setDefaultSelectedGroups] = useState([]);

  type CompanyDescriptionForm = {
    companyName: string;
    description: string;
    addressLine: string;
    country: number;
    state: string;
    city: string;
    pin: number;
    allowCompanyNameVisibleToMembers: boolean;
  };

  const stateSchema = {
    addressLine: Yup.string().required("Address is required"),
    country: Yup.number().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    pin: Yup.number().required("Pin is required"),
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    description: Yup.string().required("Company Description is required"),
    addressLine: Yup.string().required("Address is required"),
    country: Yup.number().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    pin: Yup.number().required("Pin is required"),
    warehouses: Yup
      .array()
      .of(Yup.object().shape(stateSchema)),
    allowCompanyNameVisibleToMembers: Yup.boolean().required("Allow company name should exist"),
  });

  const { register, control, handleSubmit, reset, formState: {errors} } = useForm({
    defaultValues: {
      warehouses: companyDescription.warehouses?.length > 0 ? warehouses :[{ addressLine: "", country: 0, id:0, state: "", city: "", pin: 0 }],
      companyName: companyDescription.companyName || "",
      description: companyDescription.description || "",
      addressLine: companyDescription.address?.addressLine || "",
      country: companyDescription.address?.country || 0,
      state: companyDescription.address?.state || "",
      city: companyDescription.address?.city || "",
      pin: parseInt(companyDescription.address?.postalCode) || 0,
      allowCompanyNameVisibleToMembers: false,
    },
    resolver: yupResolver(validationSchema),
    mode: "onSubmit"
  });

  const { fields, append, remove } = useFieldArray(
    {
      control,
      name: "warehouses"
    }
  );

  const [listGroups, setListGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [eligibleCountries, setEligibleCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  

  let globalId = 0;

  const token = AuthService.getBearerToken();
  const masterApi = new MasterApi(token);
  const profileApi = new ProfileApi(token);

  const getEligibleCountries = async () => {
    const response = await masterApi.apiMasterEligibleCountriesGet();
    setEligibleCountries(response)
  }

  const getStates = async (event) => {
    const response = await masterApi.apiMasterStatesGet(event.target.value);
    setStates(response)
  }

  const updateSelectedGroups = (item) => {
    var selectedArray = item.map(function (el) { return el.code; });
    setSelectedGroups(selectedArray);
  }

  const getListGroups = async () => {
    const api = new UserApi();
    const response: any = await api.apiUserListGroupsGet();
    setListGroups(response)
  }

  const onSubmit = async (data: CompanyDescriptionForm) => {
    setErrorMessage("");
    let companyLogoUrl;
    let coverImageUrl;
    if(companyLogo) {
      console.log(companyLogo);
      companyLogoUrl = await FileService.fileUpload(companyLogo.fileData);
    }
    if(coverImage) {
      coverImageUrl = await FileService.fileUpload(coverImage.fileData);
    }

    const body: UpdateCompanyDescription = {
      original: { 
        companyName: companyDescription.companyName,
        description: companyDescription.description,
        companyLogo: companyDescription.companyLogo,
        coverImage: companyDescription.coverImage,
        skills: companyDescription.skills,
        address: companyDescription.address,
        warehouses: companyDescription.warehouses,
        allowCompanyNameVisibleToMembers: companyDescription.allowCompanyNameVisibleToMembers,
      }, 
      updated: { 
        companyName: data.companyName,
        description: data.description,
        companyLogo: companyLogoUrl,
        coverImage: coverImageUrl,
        skills: selectedGroups,
        address: { 
          addressLine: data.addressLine,
          country: data.country,
          state: data.state,
          postalCode: data.pin + "",
          city: data.city
        },
        warehouses: companyDescription.warehouses,
        allowCompanyNameVisibleToMembers: companyDescription.allowCompanyNameVisibleToMembers,
      },
    }

    console.log(body)

    await profileApi.apiProfileUpdateCompanyDescriptionPost(body).then((response) => {
      console.log("in success");
      setSuccessMessage("Company update successful")
      return response;
    })
      .catch(async (error) => {
        console.log("in error" + error);
        setErrorMessage("Company update failed");
      }) 

    Util.backToTop();
    getCompanyDescription();
  }

  const getCompanyDescription = async () => {
    const response: CompanyDescription = await profileApi.apiProfileCompanyDescriptionGet();
    setCompanyDescription(response)
    if (response.warehouses.length > 0) {
      setWarehouses(response.warehouses);
    }
    
    if(response.companyLogo) {
      setCompanyLogo(response.companyLogo);
    }

    if(response.coverImage) {
      setCoverImage(response.coverImage);
    }

    if (response.skills.length  > 0) {
      const newSelectedGroups = response.skills.map(group =>{
        let obj = listGroups.find(o => o.code === group);
        const newGroup = {
          code: group, 
          name: obj.name
        }
        return newGroup
      })
  
      setDefaultSelectedGroups(newSelectedGroups)
    }
  }

  const deleteWarehouse = (warehouseId: number) => {
    remove(warehouseId)
  }

  useEffect(() => {
    getListGroups();
    getEligibleCountries();
  }, [])

  useEffect(() => {
    if(listGroups.length === 0) return;
    getCompanyDescription();
  }, [listGroups])

  useEffect(() => {
    reset(companyDescription);
  }, [companyDescription])

  return (<div className="m-8">
    <ErrorBlock errorMessage={errorMessage} />
    <SuccessBlock successMessage={successMessage} />
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <h3 className="h3-m-xl-neutral-1000">Company name*</h3>
        <input id="legalNameFormInput"
          type="text"
          placeholder="Company name will be here"
          className="form-control block w-full md:w-3/12 mt-8 px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800" 
          {...register("companyName")}
        />
        
        <Errors value={errors.companyName} />
      </div>
      <div className="mt-10">
        <h3 className="h3-m-xl-neutral-1000">Describe your company</h3>
        <h3 className="text-mdl font-medium text-gray-500 mt-4">Please be as detailed as possible:</h3>
        <div className="mb-3 mt-8">
          <textarea
            className="
        textarea-n-mdl-gray-500
      "
            id="exampleFormControlTextarea1"
            rows={3}
            placeholder="Input your product requirement here, be as specific as you can about what you need"
            {...register("description")}
          />
          <Errors value={errors.description} />
        </div>
      </div>
      <SingleFileUpload selectedFile={companyLogo} setSelectedFile={setCompanyLogo} id="company-logo" message="Add company logo" />
      <SingleFileUpload selectedFile={coverImage} setSelectedFile={setCoverImage} id="cover-image" message="Add cover image" />
      <div className="mt-10">
        <h3 className="h3-m-xl-neutral-1000">Add your skills</h3>
        <div className="mb-6 mt-8 w-full">
          <Multiselect
            options={listGroups}
            displayValue="name"
            placeholder="Select groups"
            className="form-select
                form-control block md:w-4/6 px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding border border-solid rounded-lg border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                text-base
                font-normal
                bg-white bg-no-repeat
                focus:bg-white" 
            hidePlaceholder
            avoidHighlightFirstOption
            onSelect={updateSelectedGroups}
            onRemove={updateSelectedGroups}
            selectedValues={defaultSelectedGroups}
          />     
        </div>
      </div>
      <div className="">
        <h3 className="h3-m-xl-neutral-1000">Add address*</h3>
        <div className="md:flex w-full md:space-x-6">
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="customer-address" className="label-b-sm-neutral-1000"> Address </label>
            <input id="customer-address"
              type="text"
              placeholder="Address line will be here"
              className="label-n-lg-gray-200-row" 
              {...register("addressLine")}
            />
            <Errors value={errors.addressLine} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="customer-country-main" className="label-b-sm-neutral-1000"> Country </label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="customer-country-main"
              {...register("country")}
              onChange={(event) => getStates(event)}
            >
              <option disabled={true} selected value="">
                    Country
              </option>
              {eligibleCountries.map((item, index)=>
                <option value={item} key={index}>{CountryList[item]} </option>
              )} 
            </select>
            <Errors value={errors.country} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="customer-state" className="label-b-sm-neutral-1000"> State </label>
            <select className="select-n-lg-gray-500" 
              aria-label="type"
              defaultValue="0"
              id="customer-state-main"
              {...register("state")}
            >
              <option disabled={true} selected value="">
                      States
              </option>
              {states.map((item, index)=>
                <option value={item} key={index}>{item} </option>
              )} 
            </select>
            <Errors value={errors.state} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="customer-city" className="label-b-sm-neutral-1000"> City </label>
            <input id="customer-city"
              type="text"
              placeholder="City will be here"
              className="label-n-lg-gray-200-row"
              {...register("city")}
            />
            <Errors value={errors.city} />
          </div>
          <div className="mt-8 w-full md:w-2/12">
            <label htmlFor="customer-pincode" className="label-b-sm-neutral-1000"> Pin </label>
            <input id="customer-pincode"
              type="number"
              placeholder="Pincode will be here"
              className="label-n-lg-gray-200-row"
              {...register("pin")}
            />
            <Errors value={errors.pin} />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="h3-m-xl-neutral-1000">Add warehouse (Optional)</h3>
        {fields.map((item, index) => {
          return (
            <Warehouse 
              warehouse={item} 
              key={index} 
              id={index} 
              register={register} 
              errors={errors} 
              eligibleCountries={eligibleCountries}
              deleteWarehouse={deleteWarehouse}
            />
          );
        })}
      </div>
      <div className="flex items-center py-4 mt-2">
        <button type="button" onClick={() => {
          append({ addressLine: "", country: 0, state: "", city: "", pin: 0 , id: ++globalId });
        }} className="flex" >
          <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="48" height="48" rx="24" fill="#161416"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M23 25L23 32H25V25L32 25V23L25 23V16H23L23 23L16 23V25L23 25Z" fill="white"/>
            </svg>
          </span>
          <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold mt-2">
            <span>Add More Warehouse</span>
          </span>
        </button>
        <div className="flex-grow h-px bg-gray-500"></div>
      </div>
      {/* <div className="mt-10">
        <h3 className="h3-m-xl-neutral-1000">Invite suppliers/buyers</h3>
        <div className="mt-8 md:w-4/12">
          <label htmlFor="invite-email" className="label-b-sm-neutral-1000"> Email ID </label>
          <div className="md:flex md:space-x-4">
            <input id="invite-email"
              type="email"
              placeholder="Email ID will be here"
              className="form-control block px-4 py-2 text-lg w-3/4 font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800" />
            <button className="inline-block px-6 py-2.5 mt-6 md:mt-0 bg-gray-500 text-neutral-000 font-medium text-xs leading-tight rounded-full">Send</button>
          </div>
        </div>
      </div> */}
      <div className="flex flex-col mb-6 mt-12">
        <div className="relative">
          <input 
            type="checkbox"
            id="exampleCheck2"
            className="radio-n-xl-gray-300" 
            {...register("allowCompanyNameVisibleToMembers")}
          />
          <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
          I agree to display my company name to Ethically + members.
          </label>
          <Errors value={errors.allowCompanyNameVisibleToMembers} />
        </div>
      </div>
      <div className="mb-20">
        <p className="mb-2">
          <button type="submit" className="submit-b-lg-neutral-000">Save</button>
          <button type="button" className="cancel-b-lg-gray-500">Cancel</button>
        </p>
      </div>
    </form>
  </div>);   
}

export default CompanyDescriptionBlock;