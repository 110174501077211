import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const DeliverTheServiceModal = () => {
  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="ModalDeliverTheService" tabIndex={-1} aria-labelledby="ModalDeliverTheService" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-8 text-xl text-neutral-1000 font-bold rounded-t-md">
            <p>Deliver the Service</p>
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div className="modal-body relative p-8 pt-0 space-y-4">
            <div className="">
              <div className="w-full md:w-6/12">
                <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Transaction ID </label>
                <input id="warehouse-address"
                  type="text"
                  placeholder="Transaction ID"
                  className="label-n-lg-gray-200-row" />
              </div>
              <div className="w-full md:w-6/12 mt-8">
                <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Buyer Name </label>
                <input id="warehouse-address"
                  type="text"
                  placeholder="Buyer Name"
                  className="label-n-lg-gray-200-row" />
              </div>
              <div className="mt-6">
                <div className="mb-3 mt-3">
                  <textarea
                    className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-mdl
        font-normal
        text-gray-500
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded-lg
        transition
        ease-in-out
        m-0
        focus:bg-white focus:border-blue-600 focus:outline-none
      "
                    id="exampleFormControlTextarea1"
                    rows={3}
                    placeholder="Please be as detailed as possible:"
                  />
                </div>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-bold text-gray-500">Attach files</h3>
                <h3 className="h3-m-mdl-gray-500">Upload JPG, PNG format</h3>
                <div className="mb-3 mt-8">
                  <div className="flex justify-center items-center md:w-3/12">
                    <label htmlFor="dropzone-file" className="dropzone-n-lg-gray-50">
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
              
                        <p className="p-n-sm-gray-500 mb-2"><FontAwesomeIcon 
                          icon={faPlus} 
                          className="mb-3 w-10 h-10 text-gray-500" 
                        /></p>
                        <p className="p-n-sm-gray-500">Add file</p>
                      </div>
                      <input id="dropzone-file" type="file" className="hidden"  />
                    </label>
                  </div> 
                </div>
              </div>
            </div>
            <div className="my-20">
              <p className="mb-2">
                <button type="submit" className="submit-b-lg-neutral-000">Publish</button>
                <button type="button" className="cancel-b-lg-gray-500">Cancel</button>
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );   
}

export default DeliverTheServiceModal;