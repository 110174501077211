import React from "react";

interface IDescriptionProps {
  description: string;
}

const AboutProduct = ({description}: IDescriptionProps) => {
  return (<div>
    <div className="mb-10">
      <h3 className="text-xl font-bold text-neutral-1000">About Product</h3>
    </div>
    <p className="text-mdl font-medium text-neutral-1000 mb-6 pb-2 md:mb-12 md:pb-0">
      {description}
    </p>
  </div>);
}

export default AboutProduct;