import React from "react";

interface IFilter {
    last: boolean;
  }

const Filters = (filter : IFilter) => {
  return(<div className="accordion accordion-flush pr-10" id="accordionFlushExample">
    <div className={`accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-300 ${filter.last && "border-b-0"}`}>
      <h2 className="accordion-header mb-0" id="flush-headingOne">
        <button className="accordion-button
        relative
        flex
        items-center
        w-full
        py-4
        text-base text-gray-500 text-left
        bg-white
        border-0
        rounded-none
        transition font-bold text-lg
        focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
        aria-expanded="false" aria-controls="flush-collapseOne">
        Filter Category 1
        </button>
      </h2>
      <div id="flush-collapseOne" className="accordion-collapse border-0 collapse show"
        aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
        <div className="text-mdl font-medium pb-12">
          <div className="flex justify-start">
            <div>
              <div className="form-check">
                <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" />
                <label className="form-check-label inline-block text-gray-800" htmlFor="flexCheckDefault">
               Filter Option
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckChecked" />
                <label className="form-check-label inline-block text-gray-800" htmlFor="flexCheckChecked">
               Filter Option
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
};

export default Filters;
