import React from "react";
import AssetTable from "../asset-table";

const MyAssets = () => {
  return (
    <div className="justify-center m-10">
      <AssetTable />
    </div>
  );   
}

export default MyAssets;