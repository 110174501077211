import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../helpers/breadcrumbs";

const PricingPlans = () => {
  const breadcrumbs = [
    {name: "Home", link:"/"},
    {name: "Pricing Plans", link:"/pricing-plans"},
  ]
  return (<div>
    <Breadcrumbs crumbs={breadcrumbs} />
    <div className="md:mx-36 my-16 text-neutral-1000">
      <h3 className="text-x3l font-bold">Our Pricing Plans</h3>
    </div>
    <div className="flex md:flex-col">
      <div className="sm:-mx-6 lg:-mx-8">
        <div className="md:inline-block md:min-w-full sm:px-6 lg:px-8">
          <div className="md:overflow-hidden">
            <table className="table-auto md:min-w-full">
              <thead className="bg-white border-b border-primary-300">
                <tr>
                  <th className="md:w-3/12"></th>
                  <th scope="col" className="px-6 py-4 text-left">
                    <div className="mt-6 leading-relaxed">
                      <p className="font-bold text-x3l text-gray-500">Standard</p>
                      <p className="font-bold text-xl text-gray-500 mt-6">For Buyers, Sellers and Traders</p>
                      <p className="font-medium text-xl text-gray-500 mt-10">Lorem ipsum dolor sit amet</p>
                      <p className="font-bold text-x4l text-primary-600 mt-6">€ 29.99<span className="text-xl text-gray-500">/mo</span></p>
                      <Link to="/get-started" className="inline-block px-6 py-4 bg-gray-500 text-neutral-000 font-bold text-lg leading-tight rounded-full mt-10">Get Started</Link>
                      <p className="font-medium text-xl text-gray-500 mt-10">Start your <span className="font-bold">60 days</span> trial period now</p>
                    </div>
                  </th>
                  <th scope="col" className="md:pl-28 border-l border-dashed border-gray-200 px-6 ml-10 py-4 text-left">
                    <div className="mt-6 leading-relaxed">
                      <p className="font-bold text-x3l text-gray-500">Standard</p>
                      <p className="font-bold text-xl text-gray-500 mt-6">For Service Providers</p>
                      <p className="font-medium text-xl text-gray-500 mt-10">Lorem ipsum dolor sit amet</p>
                      <p className="font-bold text-x4l text-primary-600 mt-6">€ 9.99<span className="text-xl text-gray-500">/mo</span></p>
                      <Link to="/get-started" className="inline-block px-6 py-4 bg-gray-500 text-neutral-000 font-bold text-lg leading-tight rounded-full mt-10">Get Started</Link>
                      <p className="font-medium text-xl text-gray-500 mt-10">Start your <span className="font-bold">60 days</span> trial period now</p>
                    </div>
                  </th>
                  <th className="md:w-2/12"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-primary-800">
                  <td></td>
                  <td className="px-6 py-4 whitespace-nowrap font-medium text-xl text-gray-500 text-left">
                    <p className="mt-6">Lorem ipsum dolor sit amet</p>
                    <p className="mt-6">Lorem ipsum dolor sit amet</p>
                    <p className="mt-6">Lorem ipsum dolor sit amet</p>
                    <p className="mt-6">Lorem ipsum dolor sit amet</p>
                  </td>
                  <td className="md:pl-28 border-l border-dashed border-gray-200 px-6 py-4 whitespace-nowrap font-medium text-xl text-gray-500 text-left">
                    <p className="mt-6">Lorem ipsum dolor sit amet</p>
                    <p className="mt-6">Lorem ipsum dolor sit amet</p>
                    <p className="mt-6">Lorem ipsum dolor sit amet</p>
                    <p className="mt-6">Lorem ipsum dolor sit amet</p>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default PricingPlans;