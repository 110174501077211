import React, { createContext, useEffect, useState } from "react";
import { UserDetails } from "../types/user-details";
import AuthService from "./auth-service";
import { DashboardData, HomeApi } from "../api";

const UserContext = createContext<any>({} as any);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState<UserDetails>();
  const [dashboard, setDashboard] = useState<DashboardData>();

  const getCurrentUser = async () => {
    return await AuthService.getCurrentUser();
  };

  const updateUser = (user) => {
    setUser(user)
  }

  const removeUser = () => {
    setUser(null)
  }

  const getDashboard = async () => {
    const token = AuthService.getBearerToken();
    const homeApi = new HomeApi(token);
    const response: DashboardData = await homeApi.apiHomeDashboardGet();
    setDashboard(response);
  
  }

  useEffect(() => {
    const getUser = async () => {
      const user = await getCurrentUser();
      if (user.email) {
        setUser(user);
      }
      else {
        removeUser();
      }
    };
    getUser();

    getDashboard();
    
    const interval = setInterval(() =>{
      getUser();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser, removeUser, dashboard, getDashboard }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };