import React, { useContext, useEffect, useState } from "react";
import { AssetContext } from "../../../../../services/asset-context";
import Errors from "../../../../helpers/errors";

const FieldsForSalesType = ({register, errors, duration, setDuration, salesTypeValue}) => {

  const [salesType, setSalesType] = useState<number>();

  const [moq, setMoq]  = useState<number>();
  const [price, setPrice]  = useState<number>();

  const {currencies, paymentTerms} = useContext(AssetContext);

  useEffect(() => {
    setSalesType(salesTypeValue)
  }, [salesTypeValue])

  const showAmountAndCurrency = (title = "Amount") => {
    return (<div>
      <div className="md:flex w-full md:space-x-6">
        <div className="mt-2 w-full md:w-2/12">
          <label htmlFor="amount" className="label-b-sm-neutral-1000"> {title} </label>
          <input id="amount"
            type="number"
            placeholder="Amount"
            {...register("price",{
              valueAsNumber: true,
            })}
            onChange={(event) => setPrice(parseInt(event.target.value))}
            value={price}
            className="label-n-lg-gray-200-row" />
          <Errors value={errors.price} />
        </div>
        <div className="mt-2 w-full md:w-2/12">
          <label htmlFor="currency" className="label-b-sm-neutral-1000"> Currency </label>
          <select className="select-n-lg-gray-500" 
            aria-label="type"
            defaultValue="0"
            id="currency"
            {...register("currency")}
          >
            <option disabled={true} selected value="">
                Currency
            </option>
            {currencies.map((item, index)=>
              <option value={item} key={index}>{item} </option>
            )} 
          </select>
          <Errors value={errors.currency} />
        </div>
      </div>
    </div>);
  }

  const showPaymentTerms = () => {
    return (<div className="mt-8 w-full md:w-2/12">
      <label htmlFor="payment-terms" className="label-b-sm-neutral-1000"> Payment terms* </label>
      <select className="select-n-lg-gray-500" 
        aria-label="type"
        defaultValue="0"
        id="payment-terms"
        {...register("paymentTerms")}
      >
        <option disabled={true} selected value="">
            Payment terms
        </option>
        {paymentTerms.map((item, index)=>
          <option value={item} key={index}>{item} </option>
        )}
      </select>
      <Errors value={errors.paymentTerms} />
    </div>)
  }

  const showMOQ = () => {
    return (<div className="mt-2">
      <div className="md:flex w-full md:space-x-6">
        <div className="w-full md:w-2/12">
          <label htmlFor="moq" className="label-b-sm-neutral-1000"> MOQ </label>
          <input id="moq"
            type="number"
            placeholder="MOQ"
            {...register("moq",{
              valueAsNumber: true,
            })}
            className="label-n-lg-gray-200-row" 
            value={moq || 0}
            onChange={(event) => setMoq(parseInt(event.target.value))}
          />
          <Errors value={errors.moq} />
        </div>
      </div>
    </div>)
  }

  const showDuration = () => {
    return (<div className="md:flex w-full md:space-x-6">
      <div className="mt-8 w-full md:w-4/12 space-x-2">
        <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Select </label>
        <button type="button" className={duration === 1 ? "submit-b-lg-neutral-000" : "cancel-b-lg-gray-500"} onClick={() =>(setDuration(1))}>24 Hours</button>
        <button type="button" className={duration === 3 ? "submit-b-lg-neutral-000" : "cancel-b-lg-gray-500"} onClick={() =>(setDuration(3))}>3 Days</button>
        <button type="button" className={duration === 7 ? "submit-b-lg-neutral-000" : "cancel-b-lg-gray-500"} onClick={() =>(setDuration(7))}>7 Days</button>
        <p className="inline-block px-6 py-2 text-neutral-1000 font-bold text-sm leading-tight rounded-full">Or</p>
      </div>

      <div className="mt-8 w-full md:w-1/12">
        <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Other </label>
        <input id="warehouse-address"
          type="number"
          placeholder="Type here"
          className="label-n-lg-gray-200-row" 
          value={duration}
          onChange={(event) => {setDuration(+event.target.value)}} />
      </div>
    </div>)
  }
 
  //0 = Outright    1 = Bid    2 = MadeToOrder    3 = Inventory
  const showFieldsBasedOnSalesType = () => {
    if (salesType === 0) {
      return (
        <>
          {showAmountAndCurrency()}
          {showMOQ()}
        </>
      );
    }
    if (salesType === 1) {
      return (
        <>
          {showAmountAndCurrency("Set Min Price")}
          {showPaymentTerms()}
          {showDuration()}
        </>
      );
    }
    if (salesType === 2) {
      return (<>
        {showAmountAndCurrency()}
        {showPaymentTerms()}
        {showMOQ()}
        {showDuration()}
      </>)
    }

    if (salesType === 3) {
      return(<>
        {showAmountAndCurrency()}
      </>)
    }
  }
  
  return (<>
    <div className="mt-8">
      <div className="md:flex w-full md:space-x-6">
        <div className="w-full md:w-8/12">
          <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Sales Type </label>
          <div className="flex flex-row space-x-4">
            <div className="flex flex-col mb-6 mt-2">
              <div className="relative">
                <input 
                  type="radio" name="inlineRadioOptions" id="inlineRadio0" value="0"
                  className="radio-n-xl-gray-300" 
                  {...register("salesType")}
                  onChange={()=> {setSalesType(0)}}
                  checked={salesType === 0}
                />
                <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
                    Outright
                </label>
              </div>
            </div>
            {/* <div className="flex flex-col mb-6 mt-2">
              <div className="relative">
                <input 
                  type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1"
                  className="radio-n-xl-gray-300" 
                  {...register("salesType")}
                  onChange={()=> {setSalesType(1)}}
                  checked={salesType === 1}
                />
                <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
                    Auction
                </label>
              </div>
            </div>
            <div className="flex flex-col mb-6 mt-2">
              <div className="relative">
                <input 
                  type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2"
                  className="radio-n-xl-gray-300" 
                  {...register("salesType")}
                  onChange={()=> {setSalesType(2)}}
                  checked={salesType === 2}
                />
                <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
                    Made to Order
                </label>
              </div>
            </div>
            <div className="flex flex-col mb-6 mt-2">
              <div className="relative">
                <input 
                  type="radio" name="inlineRadioOptions" id="inlineRadio3" value="3"
                  className="radio-n-xl-gray-300" 
                  {...register("salesType")}
                  onChange={()=> {setSalesType(3)}}
                  checked={salesType === 3}
                />
                <label className="label-m-md-gray-500" htmlFor="exampleCheck3">
                    Inventory
                </label>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    {showFieldsBasedOnSalesType()}
  </>);
}

export default FieldsForSalesType;