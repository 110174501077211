import React from "react";

interface IImage {
    image: any;
}

const ProductImage = ({image} : IImage) => 
  (<div className="w-32 h-32 border-2 cursor-pointer border-gray-900/10 hover:border-teal-500">
    <img src={image} alt="" className="w-32 h-32"  />
  </div>);


export default ProductImage;