import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth-service";
import { UserContext } from "../../services/user-context";
import Errors from "../helpers/errors";
import ErrorBlock from "../helpers/error-block";

interface ILogin {
  isModal?: boolean;
  loggedIn?: () => void
}

const Login = (loginData : ILogin) => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const { updateUser } = useContext(UserContext);
  const navigate = useNavigate();

  type UserSubmitForm = {
    email: string;
    password: string;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: UserSubmitForm) => {
    const createUserBody = {
      email: data.email,
      password: data.password,
    }

    try{ 
      const userDetails = await AuthService.login(createUserBody);
      updateUser(userDetails);
      if(loginData.isModal) {
        loginData.loggedIn();
      }
      else {
        navigate("/homepage")
      }
    }
    catch(error: any) {
      const errorString = await error.text();
      const errorJSON = JSON.parse(errorString);
      setError(errorJSON.message);
    }
  };

  return (<div >
    <section className="h-screen">
      <div className="px-6 h-full text-gray-800">
        <div
          className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6"
        >
          <div className={` w-10/12 mb-12 md:mb-0 ${loginData.isModal ? "" : "xl:ml-20 md:w-1/4" }`}>
            <ErrorBlock errorMessage={error} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-row items-center justify-center my-4">
                <p className="text-xl text-neutral-1000 font-bold mb-0 mr-4">Login</p>
              </div>
              <div className="mb-6">
                <input
                  type="text"
                  className="form-control block w-full px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800"
                  id="exampleFormControlInput2"
                  placeholder="Business or Work Email ID*"
                  {...register("email")}
                />
                <Errors value={errors.email} />
              </div>

              <div className="flex flex-col mb-6">
                <div className="relative">
                  <input 
                    id="passwordFormInputLogin"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password*"
                    {...register("password")}
                    className="form-control block w-full px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800" />
                  <div className="absolute flex border border-transparent right-0 top-0 h-full w-10">
                    <div className="flex items-center justify-center z-10 text-lg h-full w-full">
                      { showPassword ? <span 
                        onClick={() => setShowPassword(false)} 
                        aria-hidden="true">
                        <FontAwesomeIcon 
                          icon={faEyeSlash} 
                          className="w-4 h-4 absolute right-4 cursor-pointer bottom-3.5" 
                        />
                      </span> : <span 
                        onClick={() => setShowPassword(true)} 
                        aria-hidden="true">
                        <FontAwesomeIcon 
                          icon={faEye} 
                          className="w-4 h-4 absolute right-4 cursor-pointer bottom-3.5" /> 
                      </span>}
                    </div>
                  </div>
                </div>
                <Errors value={errors.password} />
              </div>
              <div className="text-center">
                <p className="mb-2">
                  <button type="submit" className="submit-b-lg-neutral-000">Login</button>
                  <Link to="/" className="cancel-b-lg-gray-500">Cancel</Link>
                </p>
                <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                  <Link to="/forgot-password" className="text-gray-800 underline font-medium text-lg">Forgot password?</Link>
                </p>
                {loginData.isModal && <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                  <Link className="pp-nav-item font-medium text-mdl text-gray-500 underline" to="/get-started">
                    Dont have an account? <span className="font-bold">Get Started</span>
                  </Link>
                </p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>);   
}

export default Login;