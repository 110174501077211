import React, { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth-service";
import Errors from "../helpers/errors";

const ForgotPassword = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  type UserSubmitForm = {
    email: string;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: UserSubmitForm) => {
    AuthService.forgotPasswordCode(data.email).then(() => {
      navigate("/change-password",{state:{email:data.email}});
    })
      .catch((error) => {
        setError(error.data);
      }); 
  };

  return (<div >
    <section className="h-screen">
      <div className="px-6 h-full text-gray-800">
        <div
          className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6"
        >
          <div className="xl:ml-20 w-10/12 md:w-1/4 mb-12 md:mb-0">
            {error && <div className="flex flex-row items-center justify-center my-4 bg-warning-100 rounded-lg py-5 px-6 mb-4 text-base text-warning-200" role="alert">
              {error}
            </div>}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-row items-center justify-center my-4">
                <p className="text-xl text-neutral-1000 font-bold mb-0 mr-4">Forgot Password?</p>
              </div>
              <div className="mb-6">
                <input
                  type="text"
                  className="form-control block w-full px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800"
                  id="exampleFormControlInput2"
                  placeholder="Business or Work Email ID*"
                  {...register("email")}
                />
                <Errors value={errors.email} />
              </div>
              <div className="text-center">
                <p className="mb-2">
                  <button type="submit" className="submit-b-lg-neutral-000">Send verification code</button>
                  <Link to="/" className="cancel-b-lg-gray-500">Cancel</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>);   
}

export default ForgotPassword;