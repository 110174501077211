import React from "react";
import { AssetOtherInformation } from "../../../../api";
import { CountryList } from "../../../../helpers/countryList";
import SpecInfo from "./spec-info";

interface IOtherInformationProps {
  otherInformation: AssetOtherInformation;
}

const OtherInformation = ({otherInformation}: IOtherInformationProps) => {
  const assetOtherInformation = [
    // {
    //   title: "Payment terms*",
    //   specification: ""
    // },
    // {
    //   title: "MOQ",
    //   specification: ""
    // },
    {
      title: "Shipping method*",
      specification: otherInformation?.shippingMethod
    },
    {
      title: "Delivery terms*",
      specification: otherInformation?.deliveryTerms
    },
    {
      title: "Warehouse location*",
      specification: CountryList[otherInformation?.warehouseLocation]
    },
    {
      title: "PIN Code*",
      specification: `${otherInformation?.pin}`
    }
  ]
  return (<div>
    <div className="mb-10">
      <h3 className="text-xl font-bold text-neutral-1000">Other Information</h3>
    </div>
    <div className="text-mdl font-medium text-neutral-1000 mb-6 pb-2 md:mb-12 md:pb-0 flex flex-row space-x-4">
      {
        assetOtherInformation.map((data, index) => (
          <div key={index} className="">
            <SpecInfo title={data.title} specification={data.specification}  />
          </div>
        ))
      }
    </div>
  </div>);
}

export default OtherInformation;