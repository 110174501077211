
import React, { useContext, useEffect, useState } from "react";
import { CreateApi, MasterApi } from "../../../../../api";
import AuthService from "../../../../../services/auth-service";
import { AssetContext } from "../../../../../services/asset-context";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Errors from "../../../../helpers/errors";

const SelectAssetCategory = ({register, errors, hsn, setHsn, showCategory = false, asset}) => {

  const token = AuthService.getBearerToken();
  const masterApi = new MasterApi(token);
  const createApi = new CreateApi(token);

  const { assetTypes } = useContext(AssetContext);

  const [assetCategories, setAssetCategories] =  useState([]);
  const [assetSubCategories, setAssetSubCategories] = useState([]);
  const [assetType, setAssetType] = useState("");
  const [assetCategory, setAssetCategory] = useState("");
  const [assetSubCategory, setAassetSubCategory] = useState("");
  const [edit, setEdit] = useState(false);

  const getAssetCategories = async (value) => {
    setAssetType(value);
    const response = await masterApi.apiMasterAssetCategoriesGet(value);
    setAssetCategories(response)
  }
  
  const getAssetSubCategories = async (value) => {
    setAssetCategory(value);
    const response = await masterApi.apiMasterAssetSubcategoriesGet(value);
    setAssetSubCategories(response)
  }
    
  const getAssetHSNCode = async (value) => {
    setAassetSubCategory(value);
    const response = await createApi.apiCreateAssetHSNandAttributesGet(assetType, assetCategory, value);
    setHsn(response.hsn);
  }

  useEffect(() => {
    if(asset && asset.assetType) {
      getAssetCategories(asset.assetType);
      getAssetSubCategories(asset.assetCategory)
    }
  }, [asset])

  useEffect(() => {
    if(asset && asset.assetType) {
      getAssetHSNCode(asset.assetSubCategory)
    }
  }, [assetCategory])
  
  return (
    <div className="md:flex w-full md:space-x-6">
      {showCategory ? <div className="mt-8 w-full md:w-2/12">
        <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Category </label>
        <select className="select-n-lg-gray-500" 
          aria-label="type"
          defaultValue="0"
          id="warehouse-state"
          {...register("category")}
        >
          <option value="jobwork" key={1}>Jobwork</option>
          <option value="freelance" key={2}>Freelance</option>
          <option value="consultancy" key={3}>Consultancy</option>
        </select>
        <Errors value={errors.assetName} />
      </div> :
        <div className="mt-8 w-full md:w-2/12">
          <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Asset name </label>
          <input id="warehouse-address"
            type="text"
            placeholder="My Asset"
            className="label-n-lg-gray-200-row" 
            {...register("assetName")}
            maxLength="70"
          />
          <Errors value={errors.assetName} />
        </div>}
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor="asset-type" className="label-b-sm-neutral-1000"> Asset type </label>
        <select className="select-n-lg-gray-500" 
          aria-label="type"
          defaultValue="0"
          id="asset-type"
          {...register("assetType")}
          value={assetType}
          onChange={(e) => getAssetCategories(e.target.value)}
        >
          <option disabled={true} selected value="">
                    Asset type
          </option>
          {assetTypes.map((item, index)=>
            <option value={item} key={index}>{item} </option>
          )} 
        </select>
        <Errors value={errors.assetType} />
      </div>
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor="asset-category" className="label-b-sm-neutral-1000"> Asset category </label>
        <select className="select-n-lg-gray-500" 
          aria-label="type"
          defaultValue="0"
          id="asset-category"
          {...register("assetCategory")}
          value={assetCategory}
          onChange={(e) => getAssetSubCategories(e.target.value)}
        >
          <option disabled={true} selected value="">
                    Asset category
          </option>
          {assetCategories.length > 0 && assetCategories.map((item, index)=>
            <option value={item} key={index}>{item} </option>
          )} 
        </select>
        <Errors value={errors.assetCategory} />
      </div>
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor="asset-sub-category" className="label-b-sm-neutral-1000"> Asset sub category</label>
        <select className="select-n-lg-gray-500" 
          aria-label="type"
          defaultValue="0"
          id="asset-sub-category"
          {...register("assetSubCategory")}
          value={assetSubCategory}
          onChange={(e) => getAssetHSNCode(e.target.value)}
        >
          <option disabled={true} selected value="">
                    Asset sub category
          </option>
          {assetSubCategories.length > 0 && assetSubCategories.map((item, index)=>
            <option value={item} key={index}>{item} </option>
          )} 
        </select>
        <Errors value={errors.assetSubCategory} />
      </div>
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor="hsn-code" className="label-b-sm-neutral-1000"> HSN </label>
        {edit && <span className="flex">
          <input id="hsn-code"
            type="number"
            placeholder="HSN"
            className="label-n-lg-gray-200-row" 
            onChange={e => setHsn(+e.target.value)}
            value={hsn}
          /> 
        </span>}
        {!edit && <div className="mt-3">
          {hsn} 
          <FontAwesomeIcon icon={faPen} className="px-2 text-md" onClick={() => setEdit(true)} />
        </div>}
        <Errors value={errors.hsn} />
      </div>
    </div>
  );   
}

export default SelectAssetCategory;