import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import info from "../../assets/info.svg";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate } from "react-router-dom";
import { CountryList } from "../../helpers/countryList";
import { MasterApi, UserApi } from "../../api";
import Errors from "../helpers/errors";

const GetStarted = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [listGroups, setListGroups] = useState([]);
  const [eligibleCountries, setEligibleCountries] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [multiselectError, setMultiselectError] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [terms, setTerms] = React.useState(false);
  const navigate = useNavigate();

  type UserSubmitForm = {
    legalName: string;
    email: string;
    country: number;
    password: string;
    confirmPassword: string;
    acceptTerms: boolean;
    plan: number;
  };

  const validationSchema = Yup.object().shape({
    legalName: Yup.string().required("Legal Name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    country: Yup.number().typeError("Country is required").min(1, "Country is required").required("Country is required"),
    plan: Yup.number().typeError("Plan is required").min(0, "Plan is required").required("Plan is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .max(40, "Password must not exceed 40 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
    acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required")
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: UserSubmitForm) => {
    if (selectedGroups.length === 0) {
      setMultiselectError("Please select atleast 1 group");
      return;
    }
    setMultiselectError("");
    const createUserBody = {
      password: data.password,
      email: data.email,
      legalName: data.legalName,
      countryOfRegistration: data.country,
      groups: selectedGroups,
      pricingPlan: data.plan
    }

    try {
      const api = new UserApi();
      await api.apiUserCreatePost(createUserBody);
      setSuccess("User created successfully")
      navigate("/login")
    }
    catch(error: any) {
      const json = await error.json();

      setError(json.message ? json.message : json);
    }   
  };

  const updateSelectedGroups = (item) => {
    var selectedArray = item.map(function (el) { return el.code; });
    setSelectedGroups(selectedArray);
  }

  const getListGroups = async () => {
    const api = new UserApi();
    const response: any = await api.apiUserListGroupsGet();
    setListGroups(response)
  }

  const getEligibleCountries = async () => {
    const api = new MasterApi();
    const response = await api.apiMasterEligibleCountriesGet();
    setEligibleCountries(response)
  }

  useEffect(() => {
    getListGroups();
    getEligibleCountries();
  }, [])

  return (<div >
    <section className="h-screen">
      <div className="px-6 h-full text-gray-800">
        <div
          className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6"
        >
          <div className="xl:ml-20 w-10/12 md:w-1/4 mb-12 md:mb-0">
            {error && <div className="flex flex-row items-center justify-center my-4 bg-warning-100 rounded-lg py-5 px-6 mb-4 text-base text-warning-200" role="alert">
              {error}
            </div>}
            {success && <div className="flex flex-row items-center justify-center my-4 bg-success-500 rounded-lg py-5 px-6 mb-4 text-base text-success-600" role="alert">
              {success}
            </div>}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-row items-center justify-center my-4">
                <p className="text-xl text-neutral-1000 font-bold mb-0 mr-4">Get Started</p>
              </div>
              <div className="flex justify-center">
                <div className="mb-6 w-full">
                  <Multiselect
                    options={listGroups}
                    displayValue="name"
                    placeholder="Select groups"
                    className="form-select
                form-control block w-full px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                text-base
                font-normal
                bg-white bg-no-repeat
                focus:bg-white" 
                    hidePlaceholder
                    avoidHighlightFirstOption
                    onSelect={updateSelectedGroups}
                    onRemove={updateSelectedGroups}
                  />
                  <span className="flex items-center font-medium tracking-wide text-error-500 text-xs mt-1 ml-1">
                    {multiselectError ? multiselectError : ""}
                  </span>      
                </div>
              </div>
              <div className="flex justify-center">
                <div className="mb-6 w-full">
                  <select className="select-n-lg-gray-500" 
                    aria-label="type"
                    {...register("plan")}
                  >
                    <option disabled={true} selected value="">
                    Choose your plan
                    </option>
                    <option value={0}>Standard </option>
                    <option value={1}>Premium </option>
                  </select>
                  <Errors value={errors.plan} />
                </div>
              </div>
              <div className="mb-6">
                <input
                  type="email"
                  className="form-control block w-full px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800 border-red-500"
                  id="workEmailFormInput"
                  placeholder="Business or Work Email*"
                  {...register("email")}
                />
                <Errors value={errors.email} />
              </div>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <input id="legalNameFormInput"
                    type="text"
                    placeholder="Legal Company Name*"
                    {...register("legalName")}
                    className="form-control block w-full px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800" />
                  <div className="absolute flex border border-transparent right-0 top-0 h-full w-10">
                    <div className="flex items-center justify-center z-10 text-lg h-full w-full group">
                      <img
                        src={info}
                        className="w-4 h-4 absolute right-4 cursor-pointer"
                        alt="ring"
                      />
                      <span className="absolute z-50 hidden p-3 px-6 py-2 -mt-40 ml-24 text-xs font-regular text-center bg-gray-500 text-neutral-000 rounded tooltip-text group-hover:block">Please input the legal or registered business name of your company</span>
                    </div>
                  </div>
                </div>
                <Errors value={errors.legalName} />
              </div>
              <div className="flex justify-center">
                <div className="mb-6 w-full">
                  <select className="select-n-lg-gray-500" 
                    aria-label="type"
                    defaultValue="0"
                    {...register("country")}
                  >
                    <option disabled={true} selected value="">
                    Country of Registration*
                    </option>
                    {eligibleCountries.map((item, index)=>
                      <option value={item} key={index}>{CountryList[item]} </option>
                    )} 
                  </select>
                  <Errors value={errors.country} />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <input 
                    id="passwordFormInputGetStarted"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password*"
                    {...register("password")}
                    className="form-control block w-full px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800" />
                  <div className="absolute flex border border-transparent right-0 top-0 h-full w-10">
                    <div className="flex items-center justify-center z-10 text-lg h-full w-full">
                      { showPassword ? <span 
                        onClick={() => setShowPassword(false)} 
                        aria-hidden="true">
                        <FontAwesomeIcon 
                          icon={faEyeSlash} 
                          className="w-4 h-4 absolute right-4 cursor-pointer bottom-3.5" 
                        />
                      </span> : <span 
                        onClick={() => setShowPassword(true)} 
                        aria-hidden="true">
                        <FontAwesomeIcon 
                          icon={faEye} 
                          className="w-4 h-4 absolute right-4 cursor-pointer bottom-3.5" /> 
                      </span>}
                    </div>
                  </div>
                </div>
                <Errors value={errors.password} />
              </div>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <input 
                    id="confirmPasswordFormInput"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password*"
                    {...register("confirmPassword")}
                    className="form-control block w-full px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800" />
                  <div className="absolute flex border border-transparent right-0 top-0 h-full w-10">
                    <div className="flex items-center justify-center z-10 text-lg h-full w-full">
                      { showConfirmPassword ? <span 
                        onClick={() => setShowConfirmPassword(false)} 
                        aria-hidden="true">
                        <FontAwesomeIcon 
                          icon={faEyeSlash} 
                          className="w-4 h-4 absolute right-4 cursor-pointer bottom-3.5" 
                        />
                      </span> : <span 
                        onClick={() => setShowConfirmPassword(true)} 
                        aria-hidden="true">
                        <FontAwesomeIcon 
                          icon={faEye} 
                          className="w-4 h-4 absolute right-4 cursor-pointer bottom-3.5" /> 
                      </span>}
                    </div>
                  </div>
                </div>
                <Errors value={errors.confirmPassword} />
              </div>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <input 
                    type="checkbox"
                    id="exampleCheck2"
                    {...register("acceptTerms")}
                    onChange={(event) => setTerms(event.target.checked)}
                    className="radio-n-xl-gray-300" />
                  <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
                    I consent to the Use of personal data for direct marketing as described in the Privacy Policy.
                  </label>
                </div>
                <Errors value={errors.acceptTerms} />
              </div>
              <div className="text-base text-sm font-light leading-relaxed text-gray-500">
                <p className="mt-0 mb-4">
              By clicking <span className="font-bold">“Register”</span> below:
                </p>
                <p className="mt-0 mb-4">
                I agree to the Terms of Use and Privacy Policy for myself and/or on behalf of the business that I represent.
                </p>
                <p className="mt-0 mb-4">
                I declare to the best of my knowledge that I am not, and the business and its offiliates that I represent are not, Sactioned Persons or located Sanctioned Territories.
                </p>
              </div>
              <div className="text-center">
                <p className="mb-2">
                  <button type="submit" className="submit-b-lg-neutral-000 disabled:opacity-75" disabled={!terms}>Register</button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>);   
}

export default GetStarted;