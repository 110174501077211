import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/EngagementOnModel.jpg";

const Element4 = () => (
  <section className="px-6 pt-6 md:px-12 md:pt-12 md:pr-32 text-center md:text-left bg-neutral-100">
    <div className="justify-center">
      <div className="block mt-4">
        <div>
          <div className="wrap mx-auto">
            <div className="md:grid md:grid-cols-12">
              <div className="md:row-span-full md:col-span-5 md:col-end-12 self-center md:w-790">
                <img
                  src={logo}
                  className="shadow-md object-cover"
                  alt="ring"
                />
              </div>
              <div className="md:row-span-full md:col-start-1 md:col-span-6 self-center md:relative md:left-24 md:top-16">
                <div>
                  <h3 className="text-x3l font-bold text-gray-500">Our Service Providers</h3>
                  <div className="h-1 w-1/12 bg-primary-600" />
                </div>
                <div className="bg-primary-700 md:top-4 p-10 md:relative">
                  <p className="text-xl md:text-xll mb-2 font-medium">
                    Ethically+ is on a mission to create a global synergistic network of Artists and Service Providers. 
                    No matter where you are located, if you are a Designer or a Photographer in the jewelry industry, we provide you the platform to showcase your skills, expertise and mint NFTs.
                  </p>
                  <p className="text-xl mb-6 font-bold">Join our growing community of Service Providers.</p>
                  <p className="mb-2">
                    <Link to="/faq" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-medium text-xs leading-tight rounded-full m-2.5 ml-0">FAQ</Link>
                    <Link to="/pricing-plans" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-medium text-xs leading-tight rounded-full m-2.5">Pricing Plans</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Element4;


