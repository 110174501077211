
import React, { useEffect } from "react";
import { faCircleXmark, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SingleFileUpload = ({selectedFile, setSelectedFile, id, message = "Attach files"}) => {

  const fileUpload = async (event) => {
    const fileObject = {
      fileData: event.target.files[0],
      url: URL.createObjectURL(event.target.files[0])
    }
    setSelectedFile(fileObject);
  }

  const removeItem = () => {
    setSelectedFile({});
  }

  const renderFile = (item) => {
    if(item.url) {
      return <img src={item.url} alt="" className="w-full h-48" />
    }
    return <img src={item} alt="" className="w-full h-48" />
  }

  useEffect(() => {
  }, [selectedFile])

  return (
    <div className="mt-10">
      <h3 className="h3-m-xl-neutral-1000">{message}</h3>
      <h3 className="h3-m-mdl-gray-500">Upload JPG, PNG, PDF format</h3>
      <div className="mb-3 mt-8 flex space-x-3">
        { selectedFile
    && Object.keys(selectedFile).length > 0  && <div className="justify-center items-center md:w-2/12">
          <div className="relative z-0">
            <button type="button" className="absolute -top-2 -right-2 z-10" onClick={removeItem}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="w-6 h-6 text-gray-500 cursor-pointer" />
            </button>
            <div className="dropzone-n-lg-gray-50">
              {renderFile(selectedFile)}
            </div>
          </div>
        </div>}
        {selectedFile
    && Object.keys(selectedFile).length === 0 && <div className="flex justify-center items-center md:w-2/12">
          <label htmlFor={`dropzone-file-${id}`} className="dropzone-n-lg-gray-50">
            <div className="flex flex-col justify-center items-center pt-5 pb-6">
              <p className="p-n-sm-gray-500 mb-2">
                <FontAwesomeIcon
                  icon={faPlus}
                  className="mb-3 w-10 h-10 text-gray-500" />
              </p>
              <p className="p-n-sm-gray-500">Add file</p>
            </div>
            <input id={`dropzone-file-${id}`} 
              type="file" 
              accept="image/*" 
              className="hidden" 
              onChange={fileUpload} 
              multiple={false}
            />
          </label>
        </div>}
      </div>
    </div>
  );   
}

export default SingleFileUpload;