import React from "react";
import SupplierCard from "../supplier-card";

const MyFavourites = () => {
  return (
    <div className="justify-center">
      <SupplierCard />
      <SupplierCard />
      <div className="flex justify-center">
        <div className="m-10 xl:w-96 relative text-center">
          <button type="button" className="load-more">Load More</button>
        </div>
      </div>
    </div>
  );   
}

export default MyFavourites;