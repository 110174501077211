import React, { useState, useRef, useEffect } from "react"
import Feed from "./feed"

interface IFeed {
  caption: string;
  id: string;
  media_type: string;
  media_url: string;
}

interface IPaging {
    next: string;
}
interface IFeeds {
  data: IFeed[];
  paging: IPaging;
}


const InstaFeeds = ({token, ...props}) => {
  const [feed1, setFeedData1] = useState<IFeeds>();
  const [feed2, setFeedData2] = useState<IFeeds>();
  const [feed3, setFeedData3] = useState<IFeeds>();
  const [nextUrl, setNextUrl] = useState();
  const [prevUrl, setPrevUrl] = useState();
  //use useRef to store the latest value of the prop without firing the effect
  const tokenProp = useRef(token);
  tokenProp.current = token;

  const fetchInstagramPost = async () => fetch(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${props.limit}&access_token=${tokenProp.current}`)
    .then(response => response.json())
    .then(data => data)
    .catch(err => console.log(err));

  const getPostData = async (url) => fetch(url)
    .then(response => response.json())
    .then(data => data)

  const clearData = () => {
    setPrevUrl(null)
    setNextUrl(null)
    setFeedData2(null)
    setFeedData3(null)
  }

  const navigatePost = async (url) => {
    const feedData1 = await getPostData(url);
    clearData()
    
    setFeedData1(feedData1)
    setNextUrl(feedData1.paging.next)
    if (feedData1.paging.previous) {
      setPrevUrl(feedData1.paging.previous)
      getFeed2(feedData1.paging.previous)
    }
    if (feedData1.paging.next) {
      setNextUrl(feedData1.paging.next)
      getFeed3(feedData1.paging.next)
    }
  }    
   
  const getFeed1 = async () => {
    const feedData1 = await fetchInstagramPost();
    if(!feedData1.error){
      setFeedData1(feedData1)
      if (feedData1.paging.previous) {
        setPrevUrl(feedData1.paging.previous)
        getFeed2(feedData1.paging.previous)
      }
      if (feedData1.paging.next) {
        setNextUrl(feedData1.paging.next)
        getFeed3(feedData1.paging.next)
      }
    }
  };

  const getFeed2 = async (url) => {
    const feedData2 = await getPostData(url);
    setFeedData2(feedData2)
  };

  const getFeed3 = async (url) => {
    const feedData3 = await getPostData(url);
    setFeedData3(feedData3)
  };

  useEffect(() => {
    // this is to avoid memory leaks
    const abortController = new AbortController();  
  
    getFeed1();
    return () => {
      // cancel pending fetch request on component unmount
      abortController.abort(); 
    };
  }, [props.limit])

  return (
    <section className="overflow-hidden">
      <div className="container px-5 py-2 mx-auto lg:pt-24 lg:px-24">
        <div className="flex flex-wrap -m-1 md:-m-2">
          <div className="flex flex-wrap w-3/4">
            <div className="w-full md:w-820 md:h-120 p-1 md:p-2">
              {
                feed1 && feed1.data.map((feed) => (
                  <Feed key={feed.id} feed={feed} />
                ))
              }
            </div>
          </div>
          <div className="flex flex-wrap w-1/4">
            <div className="w-full md:w-72 md:h-72 p-1 md:py-8 md:px-4">
              {prevUrl &&<span className="text-md mb-4 font-bold underline cursor-pointer" onClick={() => navigatePost(prevUrl)}  aria-hidden="true">
                  Back
              </span>}
              {
                feed2 && feed2.data.map((feed) => (
                  <Feed key={feed.id} feed={feed} />
                ))
              }
            </div>
            <div className="w-full md:w-72 md:h-72 p-1 md:py-8 md:px-4">
              {nextUrl &&<span className="text-md mb-4 font-bold underline text-end cursor-pointer" onClick={() => navigatePost(nextUrl)}  aria-hidden="true">
                  Next
              </span>}
              {
                feed3 && feed3.data.map((feed) => (
                  <Feed key={feed.id} feed={feed} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InstaFeeds;