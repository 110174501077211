import React from "react";
import MyPortFolios from "../my-space/my-portfolios";

const MyPortfolio = () => {

  return (<div className="w-full">
    <div className="m-8">
      <h3 className="text-xll font-medium text-gray-500">My Portfolio</h3>
    </div>
    <MyPortFolios />
  </div>);   
}

export default MyPortfolio;