import React from "react";
import { Link } from "react-router-dom";
import { DashboardData } from "../../../api";

interface IItemClicked {
  selectedElement: string;
  dashboard: DashboardData;
}

const LikesAndCartItem = (iItemClicked: IItemClicked) => {

  return(
    <div className="flex space-x-4 text-gray-500 font-bold text-xs leading-tight">
      <Link className={`w-full text-center flex px-2 py-2 border-2 border-gray-500 rounded-full ${iItemClicked.selectedElement === "like" ? "bg-primary-005 border-primary-005" : "" }`} to="/homepage/like">
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.5 0.5C12.76 0.5 11.09 1.31 10 2.59C8.91 1.31 7.24 0.5 5.5 0.5C2.42 0.5 0 2.92 0 6C0 9.78 3.4 12.86 8.55 17.54L10 18.85L11.45 17.53C16.6 12.86 20 9.78 20 6C20 2.92 17.58 0.5 14.5 0.5ZM10.1 16.05L10 16.15L9.9 16.05C5.14 11.74 2 8.89 2 6C2 4 3.5 2.5 5.5 2.5C7.04 2.5 8.54 3.49 9.07 4.86H10.94C11.46 3.49 12.96 2.5 14.5 2.5C16.5 2.5 18 4 18 6C18 8.89 14.86 11.74 10.1 16.05Z" fill="#161416"/>
        </svg>
        <span className="flex text-mdl font-normal pl-2">{iItemClicked.dashboard?.bubble?.likeCount}</span>
      </Link>
      <Link className={`w-full text-center flex px-2 py-2 border-2 border-gray-500 rounded-full ${iItemClicked.selectedElement === "cart" ? "bg-primary-700 border-primary-700" : "" }`} to="/homepage/cart">
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.00044 0.0606689C7.11536 0.0606689 5.5872 1.58882 5.5872 3.4739V4H2.55873H1.62729L1.56124 4.92909L0.570274 18.8684L0.494141 19.9393H1.56776H16.4323H17.5059L17.4298 18.8684L16.4388 4.92909L16.3728 4H15.4413H12.4137V3.4739C12.4137 1.58882 10.8855 0.0606689 9.00044 0.0606689ZM10.9137 6V7.8125H12.4137V6H14.5099L15.3587 17.9393H2.64137L3.49016 6H5.5872V7.8125H7.0872V6H10.9137ZM10.9137 4V3.4739C10.9137 2.41725 10.0571 1.56067 9.00044 1.56067C7.94379 1.56067 7.0872 2.41725 7.0872 3.4739V4H10.9137Z" fill="#161416"/>
        </svg>
        <span className="flex text-mdl font-normal pl-2">{iItemClicked.dashboard?.bubble?.cartCount}</span>
      </Link>
    </div>
  )};

export default LikesAndCartItem;
