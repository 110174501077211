import React from "react";
import { ProductApi, RatingRequest } from "../../../api";
import AuthService from "../../../services/auth-service";
import Star from "./star";

interface IRating {
  totalRating: number;
  id: string;
  productId: string;
}

const Rating = ({totalRating, id, productId}: IRating) => {
  if(productId==="NA") {
    return;
  }
  const token = AuthService.getBearerToken();
  const productApi = new ProductApi(token);

  const rate = async (star) => {
    const body: RatingRequest = {
      productId: productId,
      ratingValue: 5 - star
    }
    try{
      const response = await productApi.apiProductRatePost(
        body
      );
      console.log(response)
    }
    catch(error: any) {
      console.log(error)
    }
  }

  const renderStar = (star, x) => {
    return (<div className="cursor-pointer" onClick={() => rate(star)} aria-hidden="true" data-key={star}>
      <Star amount={x} key={star} id={`${id}-${star}`}/>
    </div>);
  }

  const renderStars = () => {
    var stars = [];
    let rating = totalRating;
    let star = 4;
    while(star >= 0) {
      let x = rating >= 1 ? 1 : rating;
      stars.push(renderStar(star, x));
      rating = rating >= 1 ? rating - 1 : 0;
      star--;
    }
    return stars;
  }

  return (
    <>
      <ul className="flex justify-center">
        {renderStars()}
      </ul>
    </>);
}

export default Rating;