import React from "react";
import gems from "../../../assets/gems.jpg";
import diamonds from "../../../assets/diamonds.jpg";
import metal from "../../../assets/metal.jpg";
import designs from "../../../assets/designs.jpg";
import OurProduct from "./our-product";

const Element3 = () => {
  return (<section className="my-20 text-gray-500 md:mx-36">
    <div className="md:w-9/12">
      <div className="mb-6">
        <h3 className="text-x3l font-bold text-gray-500">Our Products</h3>
        <div className="h-1 w-20 bg-primary-600" />
      </div>
      <p className="text-md font-medium mb-6 pb-2 md:mb-12 md:pb-0">
        Browse through the collections from our Suppliers of Gemstones, Diamonds, Precious Metals and Jewelry. Or place a requirement of your choice to receive quotes from them.Browse through the collections from our Suppliers of Gemstones, Diamonds, Precious Metals and Jewelry. Or place a requirement of your choice to receive quotes from them.
      </p>
    </div>

    <div className="grid md:grid-cols-4 gap-16 text-center">
      <OurProduct title="Gemstones" image={gems} />
      <OurProduct title="Diamonds" image={diamonds} />
      <OurProduct title="Precious Metals" image={metal} />
      <OurProduct title="Jewellery Designs" image={designs} />
    </div>
  </section>);
}

export default Element3;