import React from "react";
import Rating from "../../helpers/rating";

const SupplierProfileModal = () => {
  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="ModalSupplierProfile" tabIndex={-1} aria-labelledby="ModalSupplierProfile" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 text-xl text-neutral-1000 font-bold rounded-t-md">
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div className="modal-body relative p-6 pt-0 space-y-4">
            <div className="md:flex-col w-full">
              <div className="mt-2 mb-4 flex flex-col justify-start space-y-5">
                <div className="flex items-center">
                  <Rating totalRating={3} id="supplier-profile" productId="NA" />
                  <div className="flex items-center font-bold text-lg text-neutral-1000">
                    <span className="px-6">897 ratings</span>
                    <div className="flex space-x-px">
                    </div>
                  </div>
                </div>
                <div className="flex font-medium text-sm text-gray-500">
                  <div className="relative flex flex-row space-x-2 items-start">
                    <span className="flex">
                      <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.33594 8.71817C2.33594 5.52087 4.92786 2.92896 8.12515 2.92896C11.3224 2.92896 13.9144 5.52087 13.9144 8.71817C13.9144 9.68293 13.5642 10.801 12.9619 11.987C12.3655 13.1615 11.559 14.3305 10.7342 15.3776C9.91178 16.4217 9.08678 17.3251 8.46633 17.9681C8.34388 18.095 8.22961 18.2116 8.12515 18.3168C8.02069 18.2116 7.90642 18.095 7.78396 17.9681C7.16352 17.3251 6.33852 16.4217 5.51612 15.3776C4.6913 14.3305 3.88483 13.1615 3.28837 11.987C2.68611 10.801 2.33594 9.68293 2.33594 8.71817ZM7.44782 20.4481C7.44801 20.4483 7.44817 20.4484 8.12515 19.7124L7.44817 20.4484L8.12515 21.0711L8.80212 20.4484L8.12515 19.7124C8.80212 20.4484 8.80229 20.4483 8.80247 20.4481L8.80298 20.4477L8.80446 20.4463L8.80925 20.4419L8.82606 20.4263C8.84043 20.4129 8.86105 20.3936 8.88751 20.3686C8.94042 20.3187 9.01671 20.2461 9.11304 20.1527C9.30565 19.9659 9.57876 19.6956 9.90559 19.3569C10.5581 18.6806 11.4304 17.7259 12.3053 16.6152C13.1778 15.5075 14.0686 14.2247 14.7452 12.8925C15.4159 11.5718 15.9144 10.1282 15.9144 8.71817C15.9144 4.4163 12.427 0.928955 8.12515 0.928955C3.82329 0.928955 0.335938 4.4163 0.335938 8.71817C0.335938 10.1282 0.834417 11.5718 1.50513 12.8925C2.18165 14.2247 3.07248 15.5075 3.94497 16.6152C4.81987 17.7259 5.69218 18.6806 6.34471 19.3569C6.67154 19.6956 6.94465 19.9659 7.13725 20.1527C7.23359 20.2461 7.30988 20.3187 7.36279 20.3686C7.38925 20.3936 7.40987 20.4129 7.42424 20.4263L7.44105 20.4419L7.44584 20.4463L7.44732 20.4477L7.44782 20.4481ZM5.85278 8.40698C5.85278 7.15205 6.8701 6.13474 8.12503 6.13474C9.37995 6.13474 10.3973 7.15205 10.3973 8.40698C10.3973 9.6619 9.37995 10.6792 8.12503 10.6792C6.8701 10.6792 5.85278 9.6619 5.85278 8.40698ZM8.12503 5.13474C6.31782 5.13474 4.85278 6.59977 4.85278 8.40698C4.85278 10.2142 6.31782 11.6792 8.12503 11.6792C9.93224 11.6792 11.3973 10.2142 11.3973 8.40698C11.3973 6.59977 9.93224 5.13474 8.12503 5.13474Z" fill="black"/>
                      </svg>
                    </span>
                    <span>From:</span>
                    <span className="font-bold">
                      Koramangala Bangalore, 560095
                    </span>
                  </div>
                </div>
                <div className="flex font-medium text-sm text-gray-500">
                  <div className="relative flex flex-row space-x-2 items-start">
                    <span className="flex">
                      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.08115 6.03369C5.08115 4.35257 6.44397 2.98975 8.1251 2.98975C9.80622 2.98975 11.169 4.35257 11.169 6.03369C11.169 7.71481 9.80622 9.07763 8.1251 9.07763C6.44397 9.07763 5.08115 7.71481 5.08115 6.03369ZM8.1251 0.989746C5.33941 0.989746 3.08115 3.248 3.08115 6.03369C3.08115 7.53903 3.74059 8.89035 4.78636 9.81453C3.94316 10.2058 3.17449 10.7425 2.51599 11.401C1.7794 12.1376 1.19509 13.0121 0.79645 13.9745C0.397806 14.9369 0.192627 15.9684 0.192627 17.0102H2.19263C2.19263 16.2311 2.34607 15.4597 2.64421 14.7399C2.94234 14.0201 3.37933 13.3661 3.93021 12.8153C4.48109 12.2644 5.13508 11.8274 5.85484 11.5293C6.5746 11.2311 7.34604 11.0777 8.1251 11.0777C8.90417 11.0777 9.6756 11.2311 10.3954 11.5293C11.1151 11.8274 11.7691 12.2644 12.32 12.8153C12.8709 13.3661 13.3079 14.0201 13.606 14.7399C13.9041 15.4597 14.0576 16.2311 14.0576 17.0102H16.0576C16.0576 15.9684 15.8524 14.9369 15.4538 13.9745C15.0551 13.0121 14.4708 12.1376 13.7342 11.401C13.0757 10.7425 12.307 10.2058 11.4638 9.81453C12.5096 8.89035 13.169 7.53903 13.169 6.03369C13.169 3.248 10.9108 0.989746 8.1251 0.989746Z" fill="#161416"/>
                      </svg>
                    </span>
                    <span>Member Since:</span>
                    <span className="font-bold">
                    March 2018
                    </span>
                  </div>
                </div>
                <div className="flex font-medium text-sm text-gray-500">
                  <div className="relative flex flex-row space-x-2 items-start">
                    <span className="flex">
                      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.125 10C2.125 5.58172 5.70672 2 10.125 2C14.5433 2 18.125 5.58172 18.125 10C18.125 14.4183 14.5433 18 10.125 18C5.70672 18 2.125 14.4183 2.125 10ZM10.125 0C4.60215 0 0.125 4.47715 0.125 10C0.125 15.5228 4.60215 20 10.125 20C15.6478 20 20.125 15.5228 20.125 10C20.125 4.47715 15.6478 0 10.125 0ZM14.8853 11.5884C15.4376 11.5884 15.8853 11.1407 15.8853 10.5884C15.8853 10.0362 15.4376 9.58844 14.8853 9.58844H11.1528V4.08844C11.1528 3.53615 10.7051 3.08844 10.1528 3.08844C9.60054 3.08844 9.15283 3.53615 9.15283 4.08844V10.5884V11.5884H10.1528H14.8853Z" fill="black"/>
                      </svg>
                    </span>
                    <span>Avg. Response Time:</span>
                    <span className="font-bold">
                    1 hour
                    </span>
                  </div>
                </div>
                <div className="flex font-medium text-sm text-gray-500">
                  <div className="relative flex flex-row space-x-2 items-start">
                    <span className="flex">
                      <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.6705 3.72732H13.2159V0.45459H1.76136C0.861364 0.45459 0.125 1.19095 0.125 2.09095V11.091H1.76136C1.76136 12.4491 2.85773 13.5455 4.21591 13.5455C5.57409 13.5455 6.67045 12.4491 6.67045 11.091H11.5795C11.5795 12.4491 12.6759 13.5455 14.0341 13.5455C15.3923 13.5455 16.4886 12.4491 16.4886 11.091H18.125V7.00005L15.6705 3.72732ZM15.2614 4.95459L16.865 7.00005H13.2159V4.95459H15.2614ZM4.21591 11.9091C3.76591 11.9091 3.39773 11.541 3.39773 11.091C3.39773 10.641 3.76591 10.2728 4.21591 10.2728C4.66591 10.2728 5.03409 10.641 5.03409 11.091C5.03409 11.541 4.66591 11.9091 4.21591 11.9091ZM6.03227 9.45459C5.58227 8.9555 4.94409 8.63641 4.21591 8.63641C3.48773 8.63641 2.84955 8.9555 2.39955 9.45459H1.76136V2.09095H11.5795V9.45459H6.03227ZM14.0341 11.9091C13.5841 11.9091 13.2159 11.541 13.2159 11.091C13.2159 10.641 13.5841 10.2728 14.0341 10.2728C14.4841 10.2728 14.8523 10.641 14.8523 11.091C14.8523 11.541 14.4841 11.9091 14.0341 11.9091Z" fill="black"/>
                      </svg>
                    </span>
                    <span>Last Delivery:</span>
                    <span className="font-bold">
                    2 days
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-20">
              <p className="mb-2">
                <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold text-lg leading-tight rounded-full">View Supplier Profile</button>
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );   
}

export default SupplierProfileModal;