import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/theband-band-left.jpg";

const Element1 = () => (
  <section className="p-8 md:p-12 md:pt-32 text-center md:text-left">
    <div className="md:flex">
      <div className="md:flex md:flex-row md:justify-between">
        <div className="w-full md:w-8/12 md:py-23 md:pl-23 md:pr-12">
          <p className="text-xl md:text-xll mb-6 font-bold">
            We aggregate the true actors in the jewelry value chain such as the Artsinal Miners, Artisans, Designers, SME Manufacturers into one single secure and decentralized marketplace and help them and their inventory & talent to be discoverable.
          </p>
          <p className="text-xl mb-8 font-medium">We connect these dots so that they can trade among themselves and drive the industry to adopt more ethical and transparent practices, enabling the small retailers to curate unique stories of provenance to the modern conscious consumers. </p>
          <p className="mb-2">
            <Link to="/get-started" className="submit-b-lg-neutral-000">Get Started</Link>
            <Link to="/login" className="cancel-b-lg-gray-500">Login</Link>
          </p>
        </div>
        <div
          className="md:w-4/12 w-36 flex justify-center mb-6 lg:mb-0 mx-auto md:mx-0 md:mr-24"
        >
          <img
            src={logo}
            className="shadow-md"
            alt="ring"
          />
        </div>
      </div>
    </div>
  </section>
);

export default Element1;


