import React from "react";
import DeliverTheServiceModal from "../deliver-the-service-modal";
import InvoiceModal from "../invoice-modal";
import RequestForQuoteModal from "../../network/request-for-quote-modal";

const InnerTable = () => {
  return (
    <div className="m-8">
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-gray-500 text-sm">
                <thead className="bg-white border-b-2 border-gray-500 font-bold">
                  <tr>
                    <th scope="col" className="px-6 py-4 text-left">
                        #
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                        Transaction ID
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                        Date
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                        Buyer
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                        Bidding Price
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                        Action
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                        Status
                    </th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr className="font-regular">
                    <td className="px-6 py-4 whitespace-nowrap">01</td>
                    <td className="px-6 py-4 whitespace-nowrap">1234567890</td>
                    <td className="px-6 py-4 whitespace-nowrap">13/02/2022</td>
                    <td className="px-6 py-4 whitespace-nowrap font-medium">Buyer name will be here</td>
                    <td className="px-6 py-4 whitespace-nowrap font-medium">EUR <span className="font-bold">600</span></td>
                    <td className="px-6 py-4 whitespace-nowrap font-medium">
                      <div className="flex items-center justify-center w-full space-x-2">
                        <span className="pr-1.5">Reject</span>
                        <label 
                          htmlFor="toogleA"
                          className="flex items-center cursor-pointer"
                        >
                          <div className="relative">
                            <input id="toogleA" type="checkbox" className="sr-only" />
                            <div className="w-8 h-3 bg-gray-200 rounded-full shadow-inner"></div>
                            <div className="dot absolute w-6 h-6 bg-primary-005 rounded-full shadow -left-2 -top-1.5 transition"></div>
                          </div>
                        </label>
                        <span className="pl-1.5">Accept</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap flex space-x-2 font-medium">
                      <span className="align-middle mt-2 text-primary-900">Payment Done</span>
                      <span className="mt-1">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.11908 16.5832L6.0806 16.858L6.19916 16.443L5.97858 16.0721L5.11908 16.5832ZM4 20.5L3.03848 20.2253L2.54398 21.956L4.27472 21.4615L4 20.5ZM7.91677 19.3809L8.42791 18.5214L8.05699 18.3008L7.64205 18.4194L7.91677 19.3809ZM19 12.5C19 16.366 15.866 19.5 12 19.5V21.5C16.9706 21.5 21 17.4706 21 12.5H19ZM12 5.5C15.866 5.5 19 8.63401 19 12.5H21C21 7.52944 16.9706 3.5 12 3.5V5.5ZM5 12.5C5 8.63401 8.13401 5.5 12 5.5V3.5C7.02944 3.5 3 7.52944 3 12.5H5ZM5.97858 16.0721C5.35715 15.0271 5 13.8068 5 12.5H3C3 14.1765 3.45932 15.7487 4.25958 17.0944L5.97858 16.0721ZM4.96152 20.7747L6.0806 16.858L4.15755 16.3085L3.03848 20.2253L4.96152 20.7747ZM7.64205 18.4194L3.72528 19.5385L4.27472 21.4615L8.19149 20.3424L7.64205 18.4194ZM12 19.5C10.6932 19.5 9.47286 19.1428 8.42791 18.5214L7.40563 20.2404C8.7513 21.0407 10.3235 21.5 12 21.5V19.5Z" fill="#161416"/>
                          <circle cx="8.0748" cy="12.6471" r="0.9625" fill="#252A2A"/>
                          <circle cx="11.9996" cy="12.6471" r="0.9625" fill="#252A2A"/>
                          <circle cx="15.9244" cy="12.6471" r="0.9625" fill="#252A2A"/>
                        </svg>
                      </span>
                      <button type="button" className="inline-block px-6 py-2 border-2 border-gray-500 text-gray-500 font-bold leading-tight rounded-full" data-bs-toggle="modal" data-bs-target="#ModalInvoice">Invoice</button>
                      <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold leading-tight rounded-full mr-2.5" data-bs-toggle="modal" data-bs-target="#ModalDeliverTheService">Ship now</button>
                      <button type="button" className="inline-block px-6 py-2 border-2 border-gray-500 text-gray-500 font-bold leading-tight rounded-full">Cancel</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <InvoiceModal />
      <DeliverTheServiceModal />
      <RequestForQuoteModal />
    </div>
  );   
}

export default InnerTable;