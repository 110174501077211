import { FileApi } from "../api";
import AuthService from "./auth-service";

const fileUpload = async (file) => {
  const token = AuthService.getBearerToken();
  const fileApi = new FileApi(token);
  const s3Response = await fileApi.apiFileGetUploadUrlGet(file.name);
  const apiUrl = s3Response.fileUrl + "?" + s3Response.presignedUploadParameters;

  return await fetch(apiUrl, {
    method: "PUT",
    headers: {
      "Content-type": file.type
    },
    body: file
  }).then(() => {
    return s3Response.fileUrl;
  })
    .catch((error) => {
      console.log(error)
    })  
}

const uploadFiles = async (selectedFiles) => {
  const files = [];
  await Promise.all(
    selectedFiles.map(async (file) => {
      const fileUrl = await fileUpload(file.fileData);
      if(fileUrl){
        files.push(fileUrl);
      }
    })
  );
  return files;
}

const FileService = {
  fileUpload,
  uploadFiles
};
export default FileService;