import React, { useState } from "react";
import ProductImage from "./product-image";
import { Image } from "../../../../api";

interface IProductImageProps {
  productImages: Image[];
}

const ProductImages = ({productImages}: IProductImageProps) => {
  const [mainImage, setMainImage] = useState(productImages[0]);  

  return (<div className="flex md:flex-row items-start md:w-1/2">
    <div className="mr-20 space-y-10 w-1/5">
      {
        productImages.map((image, index) => (
          <div onClick={() => setMainImage(image)} aria-hidden="true" key={index}>
            <ProductImage image={image.thumbnail}  />
          </div>
        ))
      }
    </div>
    <div className="w-4/5">
      <img src={mainImage.fullSize} alt="" className=" w-660 h-660" />
    </div>
  </div>);
}

export default ProductImages;