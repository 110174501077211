import React from "react";

interface ISpecInfo {
    title: string;
    specification: string;
  }

const SpecInfo = (data : ISpecInfo) => {
  return (
    <div>
      <p className="font-bold text-sm">{data.title}</p>
      <p className="font-medium text-gray-500 mt-4">{data.specification}</p>
    </div>
  );
}

export default SpecInfo;