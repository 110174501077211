import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as heartEmpty } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import RequestForQuoteModal from "../request-for-quote-modal";
import Rating from "../../../helpers/rating";

const SupplierCard = () => {
  const liked = false;
  
  return (
    <div className="w-full">
      <div className="m-11">
        <section className="p-6 md:p-12 text-center md:text-left border-1 border-gray-300">
          <div >
            <div className="flex">
              <div className="flex-col w-1/2 justify-start">
                <p className="text-xll font-bold text-gray-500">Acacia Creations</p>
                <span className="font-medium text-mdl text-gray-000 flex">
                United States
                </span>
              </div>
              <div className="flex-col w-1/2 justify-end text-right">
                    Add as a Favorite Supplier
                <span className={`m-2 ${liked ? "text-primary-005": ""}`}>
                  <FontAwesomeIcon 
                    icon={liked ? faHeart : heartEmpty} 
                  />
                </span> 
              </div>
            </div>
            <div className="md:flex mt-8">
              <div className="md:flex-col w-full md:w-1/4 md:border-r-1">
                <p className="text-mdl font-bold text-gray-500">About Acacia Creations</p>
                <p className="text-sm font-medium text-gray-500 mt-4">
                    Acacia Creations is an ethical lifestyle brand designed for modern living. We work directly with thousands of artisans around the world to craft timeless home decor and jewelry pieces. 
                    We use only recycled and sustainably harvested natural materials. Each piece unites heritage and time-honored production techniques with functional modern design.
                </p>
                <div className="w-full md:w-3/4">
                  <div className="mt-8 flex flex-row space-x-1 font-bold text-sm">
                    <button className="w-full md:w-3/12 px-1 py-2 bg-gray-500 text-neutral-000 leading-tight rounded-full" data-bs-toggle="modal" data-bs-target="#ModalRequestForQuote">RFQ</button>
                    <button className="w-full md:w-5/12 px-2 py-2 bg-gray-500 text-neutral-000 leading-tight rounded-full">Send Offer</button>
                    <button type="button" className="w-full md:w-4/12 inline-block px-2 py-2 border-2 border-gray-500 text-gray-500 leading-tight rounded-full">Contact</button>
                  </div>
                </div>
              </div>
              <div className="md:flex-col w-full md:w-1/4 mt-8">
                <div className=" mx-8 mt-2 mb-4 flex flex-col justify-start space-y-5">
                  <div className="flex font-medium text-sm text-neutral-1000">
                    <div className="relative flex flex-col items-start w-4/12">
                        Vendor ID:
                    </div>
                    <div className="relative flex flex-col items-start w-8/12 font-bold">
                        ABCD123
                    </div>
                  </div>
                  <div className="flex font-medium text-sm text-neutral-1000">
                    <div className="relative flex flex-col items-start w-4/12">
                        Experience:
                    </div>
                    <div className="relative flex flex-col items-start w-8/12 font-bold">
                        15 Years
                    </div>
                  </div>
                  <div className="flex font-medium text-sm text-neutral-1000">
                    <div className="relative flex flex-col items-start w-4/12">
                        Location:
                    </div>
                    <div className="relative items-start w-6/12 font-bold">
                        Koramangala Bangalore, 560095
                      <span className="absolute ml-2">
                      </span>
                    </div>
                    
                  </div>
                  <Rating totalRating={3} id="supplier-card" productId="NA" />
                </div>
              </div>
              <div className="md:flex-col w-full md:w-1/4 bg-primary-800">
                <div className="mx-8 mt-9 mb-4 flex flex-col justify-start space-y-5">
                  <p className="font-bold font-mdl text-gray-500">
                My Association with Supplier
                  </p>
                  <div className="flex font-medium text-sm text-neutral-1000">
                    <div className="relative flex flex-col items-start w-5/12">
                    Completed P.O. :
                    </div>
                    <div className="relative flex flex-col items-start w-7/12 font-bold">
                        25
                    </div>
                  </div>
                  <div className="flex font-medium text-sm text-neutral-1000">
                    <div className="relative flex flex-col items-start w-5/12">
                    Open RFQ :
                    </div>
                    <div className="relative flex flex-col items-start w-7/12 font-bold">
                        03
                    </div>
                  </div>
                  <div className="flex font-medium text-sm text-neutral-1000">
                    <div className="relative flex flex-col items-start w-5/12">
                    Invited RFQ :
                    </div>
                    <div className="relative items-start w-7/12 font-bold">
                        02
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex-col w-full md:w-1/4 md:ml-10 bg-primary-800">
                <div className="mx-8 mt-9 mb-4 flex flex-col justify-start space-y-5">
                  <p className="font-bold font-mdl text-gray-500">
                    Ethically Status
                  </p>
                  <div className="flex font-medium text-sm text-neutral-1000">
                    <div className="relative flex flex-col items-start w-5/12">
                    Completed P.O. :
                    </div>
                    <div className="relative flex flex-col items-start w-7/12 font-bold">
                        25
                    </div>
                  </div>
                  <div className="flex font-medium text-sm text-neutral-1000">
                    <div className="relative flex flex-col items-start w-5/12">
                    Open RFQ :
                    </div>
                    <div className="relative flex flex-col items-start w-7/12 font-bold">
                        03
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="text-center md:text-left border-1 border-t-0 border-gray-300 p-6 md:p-7">
          <span className="font-mdl font-bold text-gray-500">Capabilities:</span>
          <span>
            <span className="w-full border-1 border-gray-300 md:w-1/12 m-2 text-center inline-block px-2 py-2 text-gray-500 leading-tight rounded-full">
                Welding
            </span>
          </span>
        </section>
      </div>
      <RequestForQuoteModal />
    </div>
  );   
}

export default SupplierCard;