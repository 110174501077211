import React from "react";
import MyAssets from "./my-assets";
import MyRequirements from "./my-requirements";

const MySpace = () => {

  return (<div className="w-full">
    <div className="m-8">
      <h3 className="text-xll font-medium text-gray-500">My Space</h3>
    </div>
    <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-0 shadow-3xl pl-0 mb-4" id="tabs-tab"
      role="tablist">
      <li className="nav-item" role="presentation">
        <a href="#tabs-home" className="
      nav-tabs-link
      active
    " id="tabs-home-tab" data-bs-toggle="pill" data-bs-target="#tabs-home" role="tab" aria-controls="tabs-home"
        aria-selected="true">My Assets</a>
      </li>
      <li className="nav-item" role="presentation">
        <a href="#tabs-profile" className="
      nav-tabs-link
    " id="tabs-profile-tab" data-bs-toggle="pill" data-bs-target="#tabs-profile" role="tab"
        aria-controls="tabs-profile" aria-selected="false">My Requirement/Service</a>
      </li>
    </ul>
    <div className="tab-content" id="tabs-tabContent">
      <div className="tab-pane fade show active" id="tabs-home" role="tabpanel" aria-labelledby="tabs-home-tab">
        <MyAssets />
      </div>
      <div className="tab-pane fade" id="tabs-profile" role="tabpanel" aria-labelledby="tabs-profile-tab">
        <MyRequirements />
      </div>
    </div>
  </div>);   
}

export default MySpace;