import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/carouselImage.jpg";

const Carousel = () => {
  return (<div id="carouselExampleCaptions" className="carousel slide relative" data-bs-ride="carousel">
    <div className="absolute font-bold md:leading-20 py-10 w-2/3 bottom-0 bg-blue-400 text-neutral-000 z-40 text-xs md:text-x4l top-10 md:top-48 left-10 md:left-28 text-left h-10 md:h-24">Creating Shared Values for miners, consumers and everyone in between in the Jewelry value chain <div className="h-1 w-1/12 bg-primary-600" /></div>
    <div className="carousel-inner relative w-full overflow-hidden md:h-128">
      <div className="carousel-item active relative float-left w-full">
        <img
          src={logo}
          className="block w-full"
          alt="..."
        />
        <div className="carousel-caption hidden md:block absolute text-center">
          <h5 className="text-xl">First slide label</h5>
          <p>Some representative placeholder content for the first slide.</p>
        </div>
      </div>
      <div className="carousel-item relative float-left w-full">
        <img
          src={logo}
          className="block w-full"
          alt="..."
        />
        <div className="carousel-caption hidden md:block absolute text-center">
          <h5 className="text-xl">Second slide label</h5>
          <p>Some representative placeholder content for the second slide.</p>
        </div>
      </div>
      <div className="carousel-item relative float-left w-full">
        <img
          src={logo}
          className="block w-full"
          alt="..."
        />
        <div className="carousel-caption hidden md:block absolute text-center">
          <h5 className="text-xl">Third slide label</h5>
          <p>Some representative placeholder content for the third slide.</p>
        </div>
      </div>
    </div>
    <div className="bg-gray-500 h-36">
      <div className="carousel-indicators p-6 border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline m-0 relative md:left-60 md:top-9 w-44">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <button
        className="carousel-control-prev p-6 border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline w-14 relative -top-24 md:-top-9"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <FontAwesomeIcon icon={faArrowLeftLong} className="px-2 text-xl" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next p-6 border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline w-14 relative -top-24 md:-top-9"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <FontAwesomeIcon icon={faArrowRightLong} className="px-2 text-xl" />
        <span className="visually-hidden">Next</span>
      </button>
      <div
        className="border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline w-96 h-24 md:h-44 -top-24 relative bg-neutral-000 float-right text-center p-16"
      >
        <Link className="pp-nav-item" to="/">
          <span className="text-xl font-bold text-gray-500">Watch Demo</span>
          <FontAwesomeIcon icon={faArrowRightLong} className="px-2 text-xl" />
        </Link>
      </div>
    </div>
  </div>);   
}

export default Carousel;