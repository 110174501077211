import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cart from "./cart";
import Create from "./create";
import Home from "./home";
import Like from "./like";
import Messages from "./messages";
import MySpace from "./my-space";
import MyTransactions from "./my-transactions";
import Network from "./network";
import Profile from "./profile";
import SideNav from "./side-nav";
import MyPortfolio from "./my-portfolio";

const Homepage = () => {
  const location = useLocation();
  const [current, setCurrent] = useState("");

  const path = location.pathname;
  var pathSplit = path.split("/")

  const updateTab = (item) => {
    if (item) {
      setCurrent(item);
    }
    else {
      setCurrent("home")
    }
  }

  const renderHomePageItem = (to) => {
    switch(to) {
    case "profile":
      return <Profile />;
    case "like":
      return <Like />;
    case "cart":
      return <Cart />;    
    case "create":
      return <Create />; 
    case "myspace":
      return <MySpace />; 
    case "myportfolio":
      return <MyPortfolio />; 
    case "transactions":
      return <MyTransactions />; 
    case "network":
      return <Network />; 
    case "messages":
      return <Messages />; 
    default:
      return <Home />;
    }
  }

  useEffect(() => {
    if(["profile","like","cart", "messages"].includes(pathSplit[2])){
      updateTab("home");
    }
    else {
      updateTab(pathSplit[2]);
    }
  },[pathSplit])
  
  return (<div className="relative flex">
    <SideNav current={current} />
    {renderHomePageItem(pathSplit[2])}
  </div>);   
}

export default Homepage;