import React from "react";
import Login from "..";

const LoginModal = ({setIsLoggedIn}) => {
  const loggedIn = () => {
    setIsLoggedIn();
    const myModalEl = document.getElementById("button-close");
    myModalEl.click();
  }
  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="ModalLogin" tabIndex={-1} aria-labelledby="ModalLogin" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 text-xl text-neutral-1000 font-bold rounded-t-md">
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal"
              id="button-close"
              aria-label="Close">
            </button>
          </div>
          <div className="modal-body relative p-6 pt-0 space-y-4">
            <Login isModal={true} loggedIn={loggedIn} />
          </div>
        </div>
      </div>
    </div>
  );   
}

export default LoginModal;