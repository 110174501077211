import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const LoginItem = () => (
  <Link className="pp-nav-item" to="/login">
    <FontAwesomeIcon icon={faUser} className="px-2 text-md" />
    <span className="font-bold text-lg">Login</span>
  </Link>
);

export default LoginItem;
