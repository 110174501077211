import React, { useState } from "react";
import { AddAnswerRequest, ProductApi, Question } from "../../../../../api";
import AuthService from "../../../../../services/auth-service";

interface IAddAnswer {
  question: Question;
  getQuestionAndAnswers: () => void;
  id: string;
}

const AddAnswer = (data : IAddAnswer) => {
  const token = AuthService.getBearerToken();
  const productApi = new ProductApi(token);
  const [answer, setAnswer] = useState("");

  const addAnswerToQuestion = async (event) => {
    try{
      const createQuestionRequest: AddAnswerRequest = {
        questionId: data.question.id,
        productId: data.question.productId,
        timeStamp: new Date(),
        body: event.target.value
      }
      const response = await productApi.apiProductAddAnswerToQuestionPost(
        createQuestionRequest
      );
      console.log(response)
      setAnswer("")
      data.getQuestionAndAnswers();
    }
    catch(error: any) {
      console.log(error)
    }
  }

  return (<div className="flex flex-col mb-6" id={data.id}>
    <div className="relative w-9/12">
      <input id="legalNameFormInput"
        type="text"
        value={answer}
        onChange={(event) => {setAnswer(event.target.value)}}
        onKeyDown={event => {
          if (event.key === "Enter") {
            addAnswerToQuestion(event)
          }
        }}
        className="form-control block w-full px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800" />
      <div className="absolute flex border border-transparent right-0 top-0 h-full w-10">
        <div className="flex items-center justify-center z-10 text-lg h-full w-full group">
          <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.14857 2.0637L5.44 3.9037L1.14286 3.33228L1.14857 2.0637ZM5.43429 7.04656L1.14286 8.88656V7.61799L5.43429 7.04656ZM0.00571428 0.332275L0 4.33228L8.57143 5.47513L0 6.61799L0.00571428 10.618L12 5.47513L0.00571428 0.332275Z" fill="black"/>
          </svg>
        </div>
      </div>
    </div>
  </div>);
}

export default AddAnswer;