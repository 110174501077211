import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import InnerTable from "./inner-table";

const TableRow = () => {
  const [showInnerTable, setShowInnerTable] = useState(false);

  const toggleShowInnerTable = () => {
    setShowInnerTable(!showInnerTable)
  }

  return (
    <React.Fragment>
      <tr className="border-b text-sm font-regular text-gray-500">
        <td className="py-4 text-center" onClick={toggleShowInnerTable} aria-hidden="true">
          { showInnerTable ? <FontAwesomeIcon 
            icon={faAngleDown} 
            className="w-4 h-4 cursor-pointer" 
          /> : <FontAwesomeIcon 
            icon={faAngleRight} 
            className="w-4 h-4 cursor-pointer" />}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            01
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            1234567890
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            13/02/2022
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            Order name will be here
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            8 Bids received
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            EUR <span className="font-bold">1200</span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <button type="button" className="inline-block px-6 py-2 border-2 border-gray-500 text-gray-500 font-bold text-sm leading-tight rounded-full">Cancel Bid</button>
        </td>
      </tr>
      {showInnerTable && <tr>
        <td colSpan={8}>
          <InnerTable />
        </td>
      </tr>}
    </React.Fragment>
  );   
}

export default TableRow;