import { faAngleRight, faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Picker, { SKIN_TONE_LIGHT } from "emoji-picker-react";

const Messages = () => {
  const [message, setMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false)

  const onEmojiClick = (event, emojiObject) => {
    setMessage(message + " " + emojiObject.emoji);
    setShowEmoji(false);
  };

  const handleOnChange = event => {
    const { value } = event.target;
    setMessage( value );
  };

  return (<div className="w-full">
    <div className="m-8">
      <h3 className="text-xll font-medium text-gray-500">Messages</h3>
    </div>
    <div className="flex flex-row">
      <div className="w-4/12 border-1 border-r-0 border-primary-300">
        <div className="bg-primary-100 p-5">
          <select className="form-select appearance-none w-1/2
                  form-control block px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding 
                  border border-solid rounded-full border-gray-200 transition ease-in-out
                  focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                  text-base
                  font-normal
                  bg-white bg-no-repeat
                  focus:bg-white" 
          aria-label="type"
          defaultValue="0"
          >
            <option value="" disabled hidden selected className="text-gray-200">General Inquiry</option>
          </select>
        </div>
        <div className="bg-primary-100 p-5">
          <p className="font-medium text-gray-500 text-sm">
            Recent
          </p>
        </div>
        <div className="p-5">
          <div className="flex justify-between">
            <div className="flex items-center text-sm text-primary-101">
              <img src="https://mdbootstrap.com/img/new/avatars/2.jpg" className="rounded-full w-12 h-12"
                alt="" loading="lazy" />
              <div className="flex flex-col mx-2.5">
                <span className="font-bold">Subject will be here</span>
                <span className="font-medium">Sender name</span>
              </div>
            </div>
            <div className="flex space-x-2">
              <span>Today </span>
              <FontAwesomeIcon 
                icon={faAngleRight} 
                className="w-4 h-4 cursor-pointer mt-1" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-8/12 border-1 border-primary-300">
        <div className="p-5">
          <div className="flex text-sm text-primary-101">
            <img src="https://mdbootstrap.com/img/new/avatars/2.jpg" className="rounded-full w-12 h-12"
              alt="" loading="lazy" />
            <div className="flex flex-col mx-2.5">
              <span className="font-bold">Sender name</span>
              <span className="font-medium">Subject will be here</span>
            </div>
            <span>Today </span>
          </div>
        </div>
        <div className="m-10 border-1 border-primary-300">
          <div className="border-b-1 border-primary-300 bg-primary-800">
            <p className="p-8 font-bold text-xl text-neutral-1000">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
            </p>
          </div>
          <div className="border-b-1 border-primary-300">
            <p className="p-8 font-medium text-mdl text-neutral-1000">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
            </p>
          </div>
          <div className="border-b-1 border-primary-300">
            <div className="p-8">
              <p className="font-medium text-xl text-neutral-1000">
                    Your offer includes
              </p>
              <p className="flex space-x-2">
                <span className="mt-2">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M2 10.5C2 6.08172 5.58172 2.5 10 2.5C14.4183 2.5 18 6.08172 18 10.5C18 14.9183 14.4183 18.5 10 18.5C5.58172 18.5 2 14.9183 2 10.5ZM10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5ZM14.7603 12.0884C15.3126 12.0884 15.7603 11.6407 15.7603 11.0884C15.7603 10.5362 15.3126 10.0884 14.7603 10.0884H11.0278V4.58844C11.0278 4.03615 10.5801 3.58844 10.0278 3.58844C9.47554 3.58844 9.02783 4.03615 9.02783 4.58844V11.0884V12.0884H10.0278H14.7603Z" fill="#0FDAEA"/>
                  </svg>
                </span>
                <span className="font-regular text-xl text-neutral-1000">
                    2 Days Delivery
                </span>
              </p>
            </div>
          </div>
          <div className="p-8">
            <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 text-lg font-bold leading-tight rounded-full mr-2.5">Send Quote</button>
          </div>
        </div>
        <div className="bg-primary-800">
          <div className="p-8">
            
            <div className="relative">
              <input id="legalNameFormInput"
                type="text"
                placeholder="Type here"
                value={message}
                onChange={handleOnChange}
                className="form-control block w-full px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800" />
              <div className="absolute flex border border-transparent right-4 top-0 h-full w-10 space-x-1">
                <div className="items-center justify-center mt-2" onClick={() => setShowEmoji(!showEmoji)} aria-hidden="true">
                  <span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z" fill="#252A2A"/>
                      <path d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z" fill="#252A2A"/>
                      <path d="M12 16C10.52 16 9.25 15.19 8.55 14H6.88C7.68 16.05 9.67 17.5 12 17.5C14.33 17.5 16.32 16.05 17.12 14H15.45C14.75 15.19 13.48 16 12 16ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#252A2A"/>
                    </svg>
                  </span>
                  {showEmoji && <Picker
                    onEmojiClick={onEmojiClick}
                    disableAutoFocus={true}
                    skinTone={SKIN_TONE_LIGHT}
                    groupNames={{ smileys_people: "PEOPLE" }}
                    native
                  />}
                </div>
                <label htmlFor="dropzone-file-tax">
                  <div className="flex items-center justify-center z-10 text-lg h-full w-full group">
                    <FontAwesomeIcon 
                      icon={faArrowUpFromBracket} 
                      className="my-1 w-4 h-4 text-gray-500 cursor-pointer" 
                    />
                    <input id="dropzone-file-tax" type="file" className="hidden" />
                  </div>
                </label>
              </div>
            </div>
            
            <div className="flex justify-end mt-5">
              <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 text-lg font-bold leading-tight rounded-full">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>);   
}

export default Messages;