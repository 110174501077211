import React from "react";
import { Link } from "react-router-dom";
import { DashboardData } from "../../../api";

interface IUserStats {
    dashboard: DashboardData;
}

const UserStats = ({dashboard} : IUserStats) => {
  return (<div className="bg-primary-200 md:p-10">
    <div className="text-left mx-auto">
      <h3 className="text-xll font-medium mb-6 text-gray-500">Welcome, {dashboard?.user?.legalName}</h3>
    </div>
    <div className="grid md:grid-cols-4 gap-16 text-left text-neutral-000">
      <div className="bg-primary-001 md:w-80">
        <div className="block rounded-lg bg-white">
          <div className="p-6">
            <div className="flex justify-between">
              <p className="text-xll font-bold mb-5">{dashboard?.sales?.totalSalesAmount?.currencyType} {dashboard?.sales?.totalSalesAmount?.amount}</p>
              <span className="mt-2">   
                <svg width="48" height="24" viewBox="0 0 48 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30.7433 1.61676C29.95 0.0300912 27.36 0.0300912 26.5666 1.61676L19.3333 16.1068L16.7433 10.9501C16.3466 10.1568 15.5533 9.66676 14.6666 9.66676H0.666626V14.3334H13.22L17.2333 22.3834C17.6533 23.1768 18.4466 23.6668 19.3333 23.6668C20.22 23.6668 21.0133 23.1768 21.41 22.3834L28.6666 7.89342L31.2566 13.0501C31.6533 13.8434 32.4466 14.3334 33.3333 14.3334H47.3333V9.66676H34.78L30.7433 1.61676Z" fill="white"/>
                </svg>
              </span>
            </div>
            <p className="text-xll font-bold mb-3">Sales</p>
            <p className="text-xl font-medium mb-2">{dashboard?.sales?.pendingInvoices} pending Invoices</p>
            <p className="text-xl font-medium">{dashboard?.sales?.newBids} new Bids</p>
          </div>
        </div>
      </div>
      <Link className="bg-primary-002 md:w-80" to="/homepage/cart">
        <div className="block rounded-lg bg-white">
          <div className="p-6">
            <div className="flex justify-between">
              <p className="text-xll font-bold mb-5">{dashboard?.purchase?.totalPurchaseAmount?.currencyType} {dashboard?.purchase?.totalPurchaseAmount?.amount}</p>
              <span className="mt-1">   
                <svg width="39" height="48" viewBox="0 0 39 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33.5 10.0001H28.8334C28.8334 4.84341 24.6567 0.666748 19.5 0.666748C14.3434 0.666748 10.1667 4.84341 10.1667 10.0001H5.50004C2.93337 10.0001 0.833374 12.1001 0.833374 14.6667V42.6668C0.833374 45.2334 2.93337 47.3334 5.50004 47.3334H33.5C36.0667 47.3334 38.1667 45.2334 38.1667 42.6668V14.6667C38.1667 12.1001 36.0667 10.0001 33.5 10.0001ZM19.5 5.33342C22.0667 5.33342 24.1667 7.43342 24.1667 10.0001H14.8334C14.8334 7.43342 16.9334 5.33342 19.5 5.33342ZM33.5 42.6668H5.50004V14.6667H10.1667V19.3334C10.1667 20.6167 11.2167 21.6667 12.5 21.6667C13.7834 21.6667 14.8334 20.6167 14.8334 19.3334V14.6667H24.1667V19.3334C24.1667 20.6167 25.2167 21.6667 26.5 21.6667C27.7834 21.6667 28.8334 20.6167 28.8334 19.3334V14.6667H33.5V42.6668Z" fill="white"/>
                </svg>
              </span>
            </div>
            <p className="text-xll font-bold mb-3">Purchase</p>
            <p className="text-xl font-medium mb-2">{dashboard?.purchase?.ordersToBeShipped} Orders to be shipped</p>
            <p className="text-xl font-medium">{dashboard?.purchase?.pendingInvoices} Pending invoices</p>
          </div>
        </div>
      </Link>
      <Link className="bg-primary-003 md:w-80" to="/homepage/like">
        <div className="block rounded-lg bg-white">
          <div className="p-6">
            <div className="flex justify-between">
              <p className="text-xll font-bold mb-5">{dashboard?.visits?.totalVisitCount}</p>
              <span className="mt-2">   
                <svg width="48" height="43" viewBox="0 0 48 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M34.5 0C30.44 0 26.5434 1.89 24 4.87667C21.4567 1.89 17.56 0 13.5 0C6.31335 0 0.666687 5.64667 0.666687 12.8333C0.666687 21.6533 8.60002 28.84 20.6167 39.76L24 42.8167L27.3834 39.7367C39.4 28.84 47.3334 21.6533 47.3334 12.8333C47.3334 5.64667 41.6867 0 34.5 0ZM24.2334 36.2833L24 36.5167L23.7667 36.2833C12.66 26.2267 5.33335 19.5767 5.33335 12.8333C5.33335 8.16667 8.83335 4.66667 13.5 4.66667C17.0934 4.66667 20.5934 6.97667 21.83 10.1733H26.1934C27.4067 6.97667 30.9067 4.66667 34.5 4.66667C39.1667 4.66667 42.6667 8.16667 42.6667 12.8333C42.6667 19.5767 35.34 26.2267 24.2334 36.2833Z" fill="white"/>
                </svg>
              </span>
            </div>
            <p className="text-xll font-bold mb-3">Profile visits</p>
            <p className="text-xl font-medium mb-2">View top visitors </p>
          </div>
        </div>
      </Link>
      <Link className="bg-primary-004 md:w-80" to="/homepage/messages">
        <div className="block rounded-lg bg-white">
          <div className="p-6">
            <div className="flex justify-between">
              <p className="text-xll font-bold mb-5">{dashboard?.messages?.newMessages + dashboard?.messages?.newRFQs}</p>
              <span className="mt-2">   
                <svg width="48" height="38" viewBox="0 0 48 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M47.3333 4.99992C47.3333 2.43325 45.2333 0.333252 42.6667 0.333252H5.33332C2.76666 0.333252 0.666656 2.43325 0.666656 4.99992V32.9999C0.666656 35.5666 2.76666 37.6666 5.33332 37.6666H42.6667C45.2333 37.6666 47.3333 35.5666 47.3333 32.9999V4.99992ZM42.6667 4.99992L24 16.6666L5.33332 4.99992H42.6667ZM42.6667 32.9999H5.33332V9.66658L24 21.3332L42.6667 9.66658V32.9999Z" fill="white"/>
                </svg>
              </span>
            </div>
            <p className="text-xll font-bold mb-3">Messages</p>
            <p className="text-xl font-medium mb-2">{dashboard?.messages?.newMessages} New Messages</p>
            <p className="text-xl font-medium">{dashboard?.messages?.newRFQs} New RFQs</p>
          </div>
        </div>
      </Link>
    </div>
  </div>);
}

export default UserStats;