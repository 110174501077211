import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductApi, ProductDetailResult } from "../../../api";
import { CountryList } from "../../../helpers/countryList";
import AuthService from "../../../services/auth-service";
import Breadcrumbs from "../../helpers/breadcrumbs";
import ProductJourneyModal from "../product-journey-modal";
import SupplierProfileModal from "../supplier-profile-modal";
import AboutProduct from "./about-product";
import Bidding from "./bidding";
import MoreFromMaker from "./more-from-maker";
import OtherInformation from "./other-information";
import ProductImages from "./product-images";
import QuestionAnswers from "./questions-answers";
import RatingBlock from "./rating-block";

const ProductDetails = () => {
  const { id } = useParams();

  const [productId, setProductId] = useState("");
  const [product, setProduct] = useState<ProductDetailResult>();

  const token = AuthService.getBearerToken();
  const productApi = new ProductApi(token);

  const getProductDetails = async () => {
    if(productId) {
      try{
        const response: ProductDetailResult = await productApi.apiProductDetailedViewGet(
          productId
        );
        console.log(response)
        setProduct({...response});
        console.log(product)
      }
      catch(error: any) {
        console.log(error)
      }
    }
  }

  const breadcrumbs = [
    {name: "Home", link:"/"},
    {name: "Explorer", link:"/explore"},
    {name: "Acacia Creations", link:"/explore/collections"},
    {name: `${product?.name}`, link:`/explore/product/${product?.id}`},
  ]

  useEffect(() => {
    setProductId(id);
  }, [])

  useEffect(() => {
    getProductDetails();
  }, [productId])


  return (product && <div>
    <Breadcrumbs crumbs={breadcrumbs} />
    <div className="md:mx-36">
      <div className="pb-10">
        <h1 className="text-xll font-bold tracking-wide text-neutral-1000 flex">
          {product?.name} 
          <span className="md:flex md:justify-end md:m-2">
            <button 
              data-bs-toggle="modal" data-bs-target="#ModalProductJourney"
              className="w-full text-center inline-block px-2 py-2 border-2 border-gray-500 text-gray-500 font-bold text-xs leading-tight rounded-full">
            Product Journey
            </button>
          </span>
        </h1>
        <p className="text-xl font-bold tracking-wide text-gray-500 cursor-pointer" 
          data-bs-toggle="modal"
          data-bs-target="#ModalSupplierProfile"
        >
          {product?.traderInfo.name}
          <span className="font-medium text-mdl text-gray-000 px-4">
            {product && CountryList[product.traderInfo.country]}
          </span>
        </p>
      </div>
      <div className="md:flex">
        {product?.images && <ProductImages productImages={product?.images} />}
        <div className="md:relative md:flex md:flex-col items-center md:w-1/2">
          <div className="w-full px-10">
            <RatingBlock 
              totalRating={product?.avgRating} 
              ratingCount={product?.ratingCount}
              answersCount={product?.qnA?.length}
              productId={product?.id}
            />
            <Bidding product={product} />
          </div>
        </div>
      </div>
      <AboutProduct description={product?.description} />
      <OtherInformation otherInformation={product?.otherInformation} />
      <QuestionAnswers productId={product?.id} />
      <MoreFromMaker />
      <SupplierProfileModal />
      <ProductJourneyModal />
    </div>
  </div>);   
}

export default ProductDetails;