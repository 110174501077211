import React from "react";
import gems from "../../../assets/gems.jpg";

const ProductJourneyModal = () => {

  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="ModalProductJourney" tabIndex={-1} aria-labelledby="ModalProductJourney" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 text-xl text-neutral-1000 font-bold rounded-t-md">
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div className="modal-body relative p-6 pt-0 space-y-4">
            <div className="md:flex-col w-full">
              <div className="mt-2 mb-4 flex flex-col justify-start space-y-5">
                <div className="flex flex-row space-x-6">
                  <div className="flex">
                    <img src={gems} alt="first block" className="h-20 w-20" />
                  </div>
                  <div className="w-2/4 space-y-4">
                    <p className="font-regular text-sm text-gray-500">
                    1 Ct. Ring
                    </p>
                    <p className="font-regular text-sm text-primary-009">
                    Prod ID: xyz1234
                    </p>
                  </div>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item bg-white border-b border-gray-200">
                    <h2 className="accordion-header mb-0" id="headingOne">
                      <button className="
        accordion-button
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
        journey
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                      aria-controls="collapseOne">
        Retailer
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample">
                      <div className="accordion-body pb-2 px-5">
                        <p className="font-bold text-mdl text-gray-500">Acacia Creations</p>
                        <p className="font-regular text-sm text-gray-500 mt-4">25 May 2021</p>
                        <p className="font-regular text-sm text-primary-009">13:25:48</p>
                        <div className="mt-4 flex flex-row space-x-4">
                          <div>
                            <p className="font-regular text-sm text-primary-009">Owner</p>
                            <p className="font-bold text-sm text-gray-500">MEM159791</p>
                          </div>
                          <div>
                            <p className="font-regular text-sm text-primary-009">Status</p>
                            <p className="font-bold text-sm text-gray-500">Instock</p>
                          </div>
                        </div>
                        <div className="mt-4">
                          <p className="font-regular text-sm text-primary-009">Country of Origin</p>
                          <p className="font-bold text-sm text-gray-500">India</p>
                        </div>
                        <div className="mt-4">
                          <p className="font-regular text-sm text-primary-009">Transaction ID</p>
                          <p className="font-bold text-sm text-gray-500 break-words">a14f11c9eb9a37ef2336e8cdc1948b559ff7815270c0fefc59e124a2f8536214</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item bg-white border-b border-gray-200">
                    <h2 className="accordion-header mb-0" id="headingTwo">
                      <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
        journey
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                      aria-controls="collapseTwo">
        Manufacturer
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample">
                      <div className="accordion-body pb-2 px-5">
                        <p className="font-bold text-mdl text-gray-500">ABC Wholesaler</p>
                        <p className="font-regular text-sm text-gray-500 mt-4">25 May 2021</p>
                        <p className="font-regular text-sm text-primary-009">13:25:48</p>
                        <div className="mt-4 flex flex-row space-x-4">
                          <div>
                            <p className="font-regular text-sm text-primary-009">Owner</p>
                            <p className="font-bold text-sm text-gray-500">MEM159791</p>
                          </div>
                          <div>
                            <p className="font-regular text-sm text-primary-009">Status</p>
                            <p className="font-bold text-sm text-gray-500">Instock</p>
                          </div>
                        </div>
                        <div className="mt-4">
                          <p className="font-regular text-sm text-primary-009">Country of Origin</p>
                          <p className="font-bold text-sm text-gray-500">India</p>
                        </div>
                        <div className="mt-4">
                          <p className="font-regular text-sm text-primary-009">Transaction ID</p>
                          <p className="font-bold text-sm text-gray-500 break-words">a14f11c9eb9a37ef2336e8cdc1948b559ff7815270c0fefc59e124a2f8536214</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item bg-white border-b border-gray-200">
                    <h2 className="accordion-header mb-0" id="headingThree">
                      <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
        journey
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                      aria-controls="collapseThree">
        Miner
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample">
                      <div className="accordion-body pb-2 px-5">
                        <p className="font-bold text-mdl text-gray-500">Mining Co.</p>
                        <p className="font-regular text-sm text-gray-500 mt-4">25 May 2021</p>
                        <p className="font-regular text-sm text-primary-009">13:25:48</p>
                        <div className="mt-4 flex flex-row space-x-4">
                          <div>
                            <p className="font-regular text-sm text-primary-009">Owner</p>
                            <p className="font-bold text-sm text-gray-500">MEM159791</p>
                          </div>
                          <div>
                            <p className="font-regular text-sm text-primary-009">Status</p>
                            <p className="font-bold text-sm text-gray-500">Instock</p>
                          </div>
                        </div>
                        <div className="mt-4">
                          <p className="font-regular text-sm text-primary-009">Country of Origin</p>
                          <p className="font-bold text-sm text-gray-500">India</p>
                        </div>
                        <div className="mt-4">
                          <p className="font-regular text-sm text-primary-009">Transaction ID</p>
                          <p className="font-bold text-sm text-gray-500 break-words">a14f11c9eb9a37ef2336e8cdc1948b559ff7815270c0fefc59e124a2f8536214</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>
  );   
}

export default ProductJourneyModal;