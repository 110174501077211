import React from "react";
import { Link } from "react-router-dom";
import { ProductPreview } from "../../../api";

interface IProductProps {
  product: ProductPreview;
}

const ProductContent = ({product}: IProductProps) => {
  return(
    <div className="block bg-white w-auto">
      <Link to={{pathname: `/explore/product/${product.id}`}}>
        <div className="overflow-hidden border-white mx-auto bg-white">
          <img src={product?.coverImageUrl} alt={product.name} className="w-96 md:h-440" />
        </div>
      </Link>
      <div className="py-6">
        <h4 className="text-sm text-neutral-1000 font-bold mb-4">{product?.name}</h4>
        <p className="mt-4">
          {product && product.authorizedProductPreviewData ? 
            <span>{`${product.authorizedProductPreviewData?.price?.currencyType} ${product.authorizedProductPreviewData?.price?.amount}`}</span> :
            <div>
              <button 
                className="font-medium text-sm underline"
                data-bs-toggle="modal" data-bs-target="#ModalLogin"
              >
                Log in or create account to view WSP
              </button>
            </div>
          }
        </p>
      </div>
    </div>
  )};

export default ProductContent;
