import React from "react";

const KycModal = () => {
  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="ModalKyc" tabIndex={-1} aria-labelledby="ModalKyc" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current text-center">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4">
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body relative m-6 space-y-4">
            <div className="flex flex-col space-y-6 items-center">
              <span>
                <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M25.383 0.776792L24.5257 0.369476L23.6681 0.776049L0.730469 11.6495L2.44388 15.264L2.95299 15.0226V17.9167V19.9167H4.95299H6.29557L6.29557 42.4638H0.730469V48.4638H48.2695V42.4638H42.7051V19.9167H44.0966H46.0966V17.9167V15.0465L46.5528 15.2632L48.2693 11.6503L25.383 0.776792ZM38.7051 42.4638V19.9167H26.4068V42.4638H38.7051ZM22.4068 42.4638V19.9167H10.2956L10.2956 42.4638H22.4068ZM42.0966 13.1461L24.5238 4.79707L6.95299 13.1264V15.9167H42.0966V13.1461Z" fill="#0FDAEA"/>
                </svg>
              </span>
              <p className="font-bold text-mdl text-gray-500">Please add your bank and KYC details to make a transaction</p>
              <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 text-lg font-bold leading-tight rounded-full">Add Details</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );   
}

export default KycModal;