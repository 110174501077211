import React from "react";

const InvoiceModal = () => {
  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="ModalInvoice" tabIndex={-1} aria-labelledby="ModalInvoice" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4  bg-primary-800  rounded-t-md">
            <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 text-lg font-bold leading-tight rounded-full mr-2.5">Generate Invoice</button>
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div className="modal-body relative p-4 space-y-4">
            <span
              className="px-4 py-2 rounded-full text-gray-500 bg-gray-300 font-medium text-mdl flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                Text
              <button className="bg-transparent hover focus:outline-none pl-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="#161416"/>
                  <path d="M10.5855 11.9999L5.63574 16.9497L7.04996 18.3639L11.9997 13.4142L16.9494 18.3639L18.3637 16.9497L13.4139 11.9999L18.3637 7.0502L16.9495 5.63599L11.9997 10.5857L7.04996 5.63599L5.63574 7.0502L10.5855 11.9999Z" fill="white"/>
                </svg>
              </button>
            </span>
            <span
              className="px-4 py-2 rounded-full text-gray-500 bg-gray-300 font-medium text-mdl flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                Text
              <button className="bg-transparent hover focus:outline-none pl-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="#161416"/>
                  <path d="M10.5855 11.9999L5.63574 16.9497L7.04996 18.3639L11.9997 13.4142L16.9494 18.3639L18.3637 16.9497L13.4139 11.9999L18.3637 7.0502L16.9495 5.63599L11.9997 10.5857L7.04996 5.63599L5.63574 7.0502L10.5855 11.9999Z" fill="white"/>
                </svg>
              </button>
            </span>
            <div className="flex items-center py-4">
              <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="48" height="48" rx="24" fill="#161416"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M23 25L23 32H25V25L32 25V23L25 23V16H23L23 23L16 23V25L23 25Z" fill="white"/>
                </svg>
              </span>
              <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold">
                <span>Add</span>
              </span>
              <div className="flex-grow h-px bg-gray-500"></div>
            </div>
            <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 text-lg font-bold leading-tight rounded-full mr-2.5">Send</button>
          </div>
        </div>
      </div>
    </div>
  );   
}

export default InvoiceModal;