import React from "react";
import gems from "../../../assets/gems.jpg";
import check from "../../../assets/check-green.svg";
import cart from "../../../assets/cart-icon.svg";
import { Link } from "react-router-dom";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import ProductJourneyModal from "../../explore/product-journey-modal";

const ProductCard = () => {
  return (<div className="w-full">
    <div className="flex flex-col md:flex-row bg-white shadow-lg border-1 border-gray-300">
      <img className="w-full md:h-80 md:w-5/12" src={gems} alt="" />
      <div className="w-7/12">
        <div className=" mx-8 mt-2 mb-4 flex flex-col justify-start space-y-5">
          <div className="flex justify-end">
            <button 
              data-bs-toggle="modal" data-bs-target="#ModalProductJourney"
              className="w-4/12 text-center inline-block px-2 py-2 border-2 border-gray-500 text-gray-500 font-bold text-xs leading-tight rounded-full">Product Journey</button>
          </div>
          <div className="text-neutral-1000 text-xl font-bold mb-2 flex justify-between">
            <span className="underline">My Little Gem</span>
            <span>EUR 100</span>
          </div>
          <div className="flex font-medium text-sm text-neutral-1000">
            <div className="relative flex flex-col items-start w-4/12">
            Specification:
            </div>
            <div className="relative flex flex-col items-start w-8/12 font-regular">
            18YG | 2 Gram | VSGH Diamond
            </div>
          </div>
          <div className="flex font-medium text-sm text-neutral-1000">
            <div className="relative flex flex-col items-start w-4/12">
            Country of Origin:
            </div>
            <div className="relative flex flex-col items-start w-8/12 font-bold">
            India
            </div>
          </div>
          <div className="flex font-medium text-sm text-neutral-1000">
            <div className="relative flex flex-col items-start w-4/12">
            Supplier:
            </div>
            <div className="relative items-start w-8/12 font-bold">
            Acacia Creations 
              <span className="absolute ml-2">
                <img src={check} alt="" />
              </span>
            </div>
          </div>
          <div className="flex font-medium text-sm text-neutral-1000">
            <div className="relative flex flex-col items-start w-4/12">
            Certification:
            </div>
            <div className="relative items-start w-8/12 font-bold">
            Certified
              <span className="absolute ml-2">
                <img src={check} alt="" />
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-9">
          <span className="mt-2">
            <FontAwesomeIcon 
              icon={faHeart} 
            />
          </span> 
          <span className="mt-2">
            <img src={cart} alt="" />
          </span> 
          <Link to="/get-started" className="px-3 py-2.5 bg-gray-500 text-neutral-000 font-bold text-md leading-tight rounded-full rounded-r">Buy Now 
            <span>
              <FontAwesomeIcon 
                icon={faArrowRightLong} 
                className="ml-2" 
              />
            </span> 
          </Link>
        </div>
      </div>
    </div>
    <ProductJourneyModal />
  </div>);
}

export default ProductCard;