import React from "react";
import InstaFeeds from "./insta-feeds";

const Element5 = () => (
  <section className="p-8 md:p-12 text-center md:text-left custom-background">
    <div className="flex">
      <div className="md:flex md:flex-row justify-start">
        <div className="w-full md:w-4/12 md:p-23 md:pr-0">
          <h3 className="text-x3l font-bold text-gray-500">Behind the scenes</h3>
          <div className="h-1 w-20 bg-primary-600" />
          <div className="md:pr-20 pt-8">
            <p className="text-xl mb-6 font-bold">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            </p>
            <p className="text-lg mb-2 font-medium">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
          </div>
        </div>
        <InstaFeeds token={process.env.REACT_APP_INS_TOKEN} limit={1}/>
      </div>
    </div>
  </section>
);

export default Element5;


