
import React from "react";
import { faCircleXmark, faFilePdf, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FileUpload = ({selectedFiles, setSelectedFiles, id}) => {

  const fileUpload = async (event) => {
    const files = selectedFiles;
    const fileObject = {
      fileData: event.target.files[0],
      url: URL.createObjectURL(event.target.files[0])
    }
    files.push(fileObject);
    setSelectedFiles([...files]);
  }

  const removeItem = (item) => {
    let files;
    if (item.fileData) {
      files = selectedFiles.filter(function( file ) {
        return file.fileData.name !== item.fileData.name;
      });
    }
    else {
      files = selectedFiles.filter(file => file !== item)
    }

    
    setSelectedFiles([...files]);
  }

  const getFileExtension = (fileUrl) => {
    return fileUrl.substring(fileUrl.lastIndexOf(".") + 1);
  }

  const renderPdf = (item) => {
    return (<div className="flex flex-row">
      <FontAwesomeIcon
        icon={faFilePdf}
        className="mb-3 w-10 h-10 text-warning-200 cursor-pointer" />
      <span className="mt-2 text-gray-50 max-w-13 break-words">{item}</span>
    </div>)
  }

  const renderVideo = (item) => {
    return <video preload="none" loop playsInline className="w-full h-48" src={item} 
      controls>
      <track kind="captions" />
    </video>
  }

  const renderEditFiles = (item) => {
    const extension = getFileExtension(item);
    if(extension === "pdf") {
      return renderPdf(item);
    }
    if(extension === "mp4") {
      return renderVideo(item);
    }

    return <img src={item} alt="" className="w-full h-48" />
  }

  const renderFile = (item) => {

    if(!item.fileData) {
      return renderEditFiles(item);
    }
    if(item.fileData?.type === "application/pdf") {
      return renderPdf(item.fileData.name);
    }
    if(item.fileData?.type === "video/mp4") {
      return renderVideo(item.url);
    }
    return <img src={item.url} alt="" className="w-full h-48" />
  }

  return (
    <div className="mt-10">
      <h3 className="h3-m-xl-neutral-1000">Attach files</h3>
      <h3 className="h3-m-mdl-gray-500">Upload JPG, PNG, PDF format</h3>
      <div className="mb-3 mt-8 flex space-x-3">
        {selectedFiles.map((item, index)=>
          <div className="justify-center items-center md:w-2/12" key={index}>
            <div className="relative z-0">
              <button type="button" className="absolute -top-2 -right-2 z-10" onClick={() => {removeItem(item)}}>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="w-6 h-6 text-gray-500 cursor-pointer" />
              </button>
              <div className="dropzone-n-lg-gray-50">
                {renderFile(item)}
              </div>
            </div>
          </div>
        )} 
        <div className="flex justify-center items-center md:w-2/12">
          <label htmlFor={`dropzone-file-${id}`} className="dropzone-n-lg-gray-50">
            <div className="flex flex-col justify-center items-center pt-5 pb-6">
              <p className="p-n-sm-gray-500 mb-2">
                <FontAwesomeIcon
                  icon={faPlus}
                  className="mb-3 w-10 h-10 text-gray-500" />
              </p>
              <p className="p-n-sm-gray-500">Add file</p>
            </div>
            <input id={`dropzone-file-${id}`} type="file" className="hidden" onChange={fileUpload} accept=".jpg,.png,.jpeg,.pdf" disabled={selectedFiles.length >= 5} />
          </label>
        </div>
      </div>
    </div>
  );   
}

export default FileUpload;