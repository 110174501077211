import React from "react";

interface IWhyEthically {
    image: string;
    title: string;
    content: string[];
}

const WhyEthically = (data : IWhyEthically) => (
  <div className="md:w-72">
    <div className="block rounded-lg shadow-lg bg-neutral-000 h-full">
      <div className="overflow-hidden mx-auto bg-gradient-to-tr from-neutral-1000 to-gray-100 h-36">
        <img
          src={data.image}
          className="mix-blend-overlay h-36 w-full md:w-72"
          alt="why-ethically"
        />
        <div className="relative font-bold text-xl text-left bottom-10 text-neutral-000 -right-3.5 z-40">{data.title}</div>
      </div>
      <div className="px-6 pb-6">
        <div className="mt-4 mx-6">
          <ul className="list-disc text-neutral-1000 text-left">
            {data.content.map((item,i) => <li key={i}>{item}</li>)}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default WhyEthically;
