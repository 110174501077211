import React from "react"

const Feed = (props) => {
  const { id, caption, media_type, media_url} = props.feed
  let post;

  switch (media_type) {
  case "VIDEO":
    post = (
      <video
        width='100%'
        height='auto' 
        src={media_url} 
        controls
        className="block object-cover object-center w-full h-full"
      >
        <track kind="captions" />
      </video>
    )
    break;
  case "CAROUSEL_ALBUM":
    post = (
      <img 
        width='100%'
        height='auto'
        id={id} 
        src={media_url} 
        alt={caption} 
        className="block object-cover object-center w-full h-full"
      />
    );
    break;
  default:
    post = (
      <img 
        width='100%'
        height='auto'
        id={id} 
        src={media_url} 
        alt={caption} 
        className="block object-cover object-center w-full h-full"
      />
    );
  }       

  return (
    <React.Fragment>
      {post}
    </React.Fragment>
  );
}

export default Feed;