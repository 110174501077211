import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { MasterApi } from "../../../api";
import { UserContext } from "../../../services/user-context";
import ProductCards from "../../helpers/product-cards";
import UserStats from "./../user-stats";

const Home = () => {
  const {dashboard, getDashboard} = useContext(UserContext);
  const [sortByOptions, setSortByOptions] = useState([]);
  const [selectedSortByOptions, setSelectedSortByOptions] = useState();

  const getSortByOptions = async () => {
    const api = new MasterApi();
    const response = await api.apiMasterSortByOptionsGet();
    setSortByOptions(response);
  }

  const changeSortByOptions = (event) => {
    setSelectedSortByOptions(event.target.value)
  }

  const getDashboardData = async () => {
    try{
      getDashboard()
    }
    catch(error: any) {
      console.log(error)
    }
  }


  useEffect(() => {
    getSortByOptions();
    getDashboardData();
  }, [])

  return (<div>
    <UserStats dashboard={dashboard} />
    <div className="md:m-10">
      <div className="md:flex justify-between">
        <div className="w-1/2">
          <div className="mb-10">
            <h3 className="text-xl font-bold text-neutral-1000">Trending Products and Services</h3>
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex space-x-3 justify-end">
            <div className="md:w-6/12">
              <div className="relative">
                <input
                  type="search"
                  className="form-control block w-full px-4 py-2.5 text-base font-normal text-gray-200 bg-white bg-clip-padding border border-solid border-gray-300 placeholder-gray-200
                            rounded-full transition ease-in-out focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none"
                  id="search"
                  placeholder="Search"
                />
                <div className="m-0.5">
                  <span 
                    aria-hidden="true">
                    <FontAwesomeIcon 
                      icon={faMagnifyingGlass} 
                      className="w-4 h-4 absolute right-4 cursor-pointer bottom-3.5" 
                    />
                  </span> 
                </div>
              </div>
            </div>
            <div className="md:w-4/12">
              <select className="form-select appearance-none w-full
                  form-control block px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                  text-base
                  font-normal
                  bg-white bg-no-repeat
                  focus:bg-white" 
              aria-label="type"
              defaultValue="0"
              onChange={changeSortByOptions}
              >
                <option value="" disabled hidden selected className="text-gray-200">Sort by:</option>
                {sortByOptions.map((item, index)=>
                  <option value={item} key={index}><span className="text-gray-200">{selectedSortByOptions === item ? "Sort by:" : ""}</span> {item} </option>
                )} 
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-10">
        <ProductCards />
      </div>
    </div>
  </div>);   
}

export default Home;