import React from "react";
import Breadcrumbs from "../../helpers/breadcrumbs";
import ProductCards from "../../helpers/product-cards";

const Like = () => {
  const breadcrumbs = [
    {name: "Home", link:"/"},
    {name: "Like", link:"/"},
  ]
  return (<div>
    <Breadcrumbs crumbs={breadcrumbs} />
    <div className="md:m-10">
      <div className="md:flex justify-between">
        <div className="w-1/2">
          <div className="mb-10">
            <h3 className="text-x3l font-bold text-gray-500">My Likes</h3>
            <h3 className="text-xl font-bold text-neutral-1000 mt-8">8 items liked</h3>
          </div>
        </div>
      </div>
      <div className="mb-10">
        <ProductCards />
      </div>
    </div>
  </div>);   
}

export default Like;