import React from "react";
import AdditionalInformation from "./additional-information";
import CompanyDescriptionBlock from "./company-description";
import UserCredentials from "./user-credentials";

const Profile = () => {
  return (<div className="w-full">
    <div className="m-8">
      <h3 className="text-xll font-medium text-gray-500">My Profile</h3>
    </div>
    <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-0 shadow-3xl pl-0 mb-4" id="tabs-tab"
      role="tablist">
      <li className="nav-item" role="presentation">
        <a href="#tabs-home" className="
      nav-tabs-link
      active
    " id="tabs-home-tab" data-bs-toggle="pill" data-bs-target="#tabs-home" role="tab" aria-controls="tabs-home"
        aria-selected="true">Company Description</a>
      </li>
      <li className="nav-item" role="presentation">
        <a href="#tabs-profile" className="
      nav-tabs-link
    " id="tabs-profile-tab" data-bs-toggle="pill" data-bs-target="#tabs-profile" role="tab"
        aria-controls="tabs-profile" aria-selected="false">Additional Information</a>
      </li>
      <li className="nav-item" role="presentation">
        <a href="#tabs-messages" className="
      nav-tabs-link
    " id="tabs-messages-tab" data-bs-toggle="pill" data-bs-target="#tabs-messages" role="tab"
        aria-controls="tabs-messages" aria-selected="false">User credentials</a>
      </li>
    </ul>
    <div className="tab-content" id="tabs-tabContent">
      <div className="tab-pane fade show active" id="tabs-home" role="tabpanel" aria-labelledby="tabs-home-tab">
        <CompanyDescriptionBlock />
      </div>
      <div className="tab-pane fade" id="tabs-profile" role="tabpanel" aria-labelledby="tabs-profile-tab">
        <AdditionalInformation />
      </div>
      <div className="tab-pane fade" id="tabs-messages" role="tabpanel" aria-labelledby="tabs-profile-tab">
        <UserCredentials />
      </div>
    </div>
  
  </div>);   
}

export default Profile;