import React from "react";
import RequirementTable from "../requirement-table";

const MyRequirements = () => {
  return (
    <div className="justify-center m-10">
      <RequirementTable />
    </div>
  );   
}

export default MyRequirements;