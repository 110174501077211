import React, { useContext } from "react";
import homeIcon from "../../../assets/home-icon.svg";
import plusIcon from "../../../assets/plus-icon.svg";
import diamondIcon from "../../../assets/diamond-icon.svg";
import transactionIcon from "../../../assets/transaction-icon.svg";
import networkIcon from "../../../assets/network-icon.svg";
import SideNavElement from "./side-nav-element";
import { UserContext } from "../../../services/user-context";

interface ISideNav {
  current: string;
}

const SideNav = (data : ISideNav) => {

  const {user} = useContext(UserContext);

  return (<div className="w-60 shadow-md bg-white px-1 relative hidden md:block">
    <ul className="relative space-y-3 mt-6 mr-4">
      <SideNavElement icon={homeIcon} name="Home" selected={data.current === "home"} goTo="/homepage" />
      <SideNavElement icon={plusIcon} name="Create" selected={data.current === "create"} goTo="/homepage/create" />
      { user?.groups?.includes("service_provider") ? 
        <SideNavElement 
          icon={diamondIcon} name="My Portfolio" 
          selected={data.current === "myportfolio"} 
          goTo="/homepage/myportfolio" /> :
        <SideNavElement 
          icon={diamondIcon} 
          name="My space" 
          selected={data.current === "myspace"} 
          goTo="/homepage/myspace" />  }
      <SideNavElement icon={transactionIcon} name="My Transactions" selected={data.current === "transactions"} goTo="/homepage/transactions" />
      <SideNavElement icon={networkIcon} name="Network" selected={data.current === "network"} goTo="/homepage/network" />
    </ul>
  </div>);
}

export default SideNav;