
import React, { useContext, useEffect, useState } from "react";
import { CountryList } from "../../../../../helpers/countryList";
import { AssetContext } from "../../../../../services/asset-context";
import Errors from "../../../../helpers/errors";

const WarehouseLocations = ({register, errors, asset}) => {

  const { warehouseLocations  } = useContext(AssetContext);

  const [warehouseLocation, setWarehouseLocation] = useState([]);
  const [pincodes, setPincodes] = useState([]);

  const getPincodes = async (value) => {
    setWarehouseLocation(value)
    const filteredCodes = warehouseLocations.filter(warehouse => {
      return warehouse.country.toString() ===value;
    });
    setPincodes(filteredCodes)
  }

  useEffect(() => {
    if(asset.warehouseLocation) {
      getPincodes(asset.warehouseLocation + "")
    }
  }, [warehouseLocations])
  
  return (
    <div className="md:flex w-full md:space-x-6">
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor="customer-state" className="label-b-sm-neutral-1000"> Warehouse location* </label>
        <select className="select-n-lg-gray-500" 
          aria-label="type"
          defaultValue="0"
          id="customer-state"
          {...register("warehouseLocation")}
          value={warehouseLocation}
          onChange={(e) => getPincodes(e.target.value)}
        >
          <option disabled={true} selected value="">
                Warehouse locations
          </option>
          {warehouseLocations.map((item, index)=>
            <option value={item.country} key={index}>{CountryList[item.country]} </option>
          )}
        </select>
        <Errors value={errors.warehouseLocation} />
      </div>
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor="customer-state" className="label-b-sm-neutral-1000">PIN Code*</label>
        <select className="select-n-lg-gray-500" 
          aria-label="type"
          defaultValue="0"
          id="customer-state"
          {...register("pincode")}
        >
          <option disabled={true} selected value="">
            Pincodes
          </option>
          {pincodes.map((item, index)=>
            <option value={item.pin} key={index}>{item.pin} </option>
          )}
        </select>
        <Errors value={errors.pincode} />
      </div>
    </div>
  );   
}

export default WarehouseLocations;