import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../services/user-context";
import AuthService from "../../../services/auth-service";

const ProfileItem = () => {
  const {user, removeUser} = useContext(UserContext);
  const navigate = useNavigate();
  
  const logout = async () => {
    await AuthService.logout();
    removeUser();
    navigate("/login")
  }

  return(
    <div className="dropdown relative">
      <a className="dropdown-toggle flex items-center hidden-arrow" href="www" id="dropdownMenuButton2" role="button"
        data-bs-toggle="dropdown" aria-expanded="false">
        <span className="font-bold text-lg">{user.legalName}</span>
      </a>
      <ul className="dropdown-menu
        min-w-max
        absolute
        hidden
        bg-neutral-000
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        shadow-lg
        mt-1
        m-0
        bg-clip-padding
        border-none
        text-neutral-1000 
        font-medium
        text-mdl
        left-auto
        right-0" 
      aria-labelledby="dropdownMenuButton2">
        <li className="border-b-1 border-gray-300">
          <Link className="dropdown-item
            text-sm
            py-2
            px-4
            font-normal
            block
            w-full
            whitespace-nowrap" 
          to="/homepage/profile"
          >
            My Profile
          </Link>
        </li>
        <li>
          <button className="dropdown-item
            text-sm
            py-2
            px-4
            font-normal
            block
            w-full
            whitespace-nowrap" 
          onClick={logout}
          >
            Logout
          </button>
        </li>
      </ul>
    </div>);
};

export default ProfileItem;
