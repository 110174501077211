import React from "react";
import TableRow from "./table-row";

const TransactionTable = () => {
  return (
    <div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b-2 border-gray-000 bg-primary-006 text-sm font-bold text-gray-500">
                  <tr>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                
                    </th> 
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                #
                    </th>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Transaction ID
                    </th>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Date
                    </th>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Sales Orders
                    </th>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Staus
                    </th>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Price
                    </th>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <TableRow />
                  <TableRow />
                  <TableRow />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );   
}

export default TransactionTable;