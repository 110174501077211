import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const GetStartedItem = () => (
  <Link className="pp-nav-item" to="/get-started">
    <FontAwesomeIcon icon={faPen} className="px-2 text-md" />
    <span className="font-bold text-lg">Get Started</span>
  </Link>
);

export default GetStartedItem;
