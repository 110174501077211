
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import KycModal from "../kyc-modal";

const AdditionalInformation = () => {
  return (<div className="m-8">
    <div className="">
      <h3 className="h3-m-xl-neutral-1000">Bank name*</h3>
      <input id="legalNameFormInput"
        type="text"
        placeholder="Bank name will be here"
        className="form-control block w-full md:w-3/12 mt-8 px-4 py-2 text-lg font-normal text-gray-200 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800" />
    </div>
    <div className="mt-8">
      <h3 className="h3-m-xl-neutral-1000">Account Details*</h3>
      <div className="md:flex w-full md:space-x-6">
        <div className="mt-8 w-full md:w-2/12">
          <label htmlFor="account-number" className="label-b-sm-neutral-1000"> Account number* </label>
          <input id="account-number"
            type="text"
            placeholder="Account number will be here"
            className="label-n-lg-gray-200-row" />
        </div>
        <div className="mt-8 w-full md:w-2/12">
          <label htmlFor="account-holder" className="label-b-sm-neutral-1000"> Account holder name* </label>
          <input id="account-holder"
            type="text"
            placeholder="Account holder will be here"
            className="label-n-lg-gray-200-row" />
        </div>
        <div className="mt-8 w-full md:w-2/12">
          <label htmlFor="customer-country" className="label-b-sm-neutral-1000"> Country* </label>
          <select className="select-n-lg-gray-500" 
            aria-label="type"
            defaultValue="0"
            id="customer-country"
          >
            <option value={1} key={1}>India </option>
          </select>
        </div>
        <div className="mt-8 w-full md:w-2/12">
          <label htmlFor="ifsc-code" className="label-b-sm-neutral-1000"> IFSC code* </label>
          <input id="ifsc-code"
            type="text"
            placeholder="IFSC code will be here"
            className="label-n-lg-gray-200-row" />
        </div>
      </div>
    </div>
    <div className="mt-10">
      <h3 className="h3-m-xl-neutral-1000">Add KYC details</h3>
      <h3 className="h3-m-mdl-gray-500">Upload documents in PDF format</h3>
      <div className="md:flex w-full md:space-x-6">
        <div className="mt-8 w-full md:w-3/12">
          <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Upload Certificate of Incorporation* </label>
          <label htmlFor="dropzone-file-certificate" className="label-n-lg-gray-200-row">
            <div className="flex flex-col items-end">
              <p className=" text-sm text-gray-500 dark:text-gray-400">
                <FontAwesomeIcon 
                  icon={faArrowUpFromBracket} 
                  className="my-1 w-4 h-4 text-gray-500 cursor-pointer" 
                />
              </p>
            </div>
            <input id="dropzone-file-certificate" type="file" className="hidden" />
          </label>
        </div>
        <div className="mt-8 w-full md:w-2/12">
          <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Upload Articles of Association* </label>
          <label htmlFor="dropzone-file-article" className="label-n-lg-gray-200-row">
            <div className="flex flex-col items-end">
              <p className=" text-sm text-gray-500 dark:text-gray-400">
                <FontAwesomeIcon 
                  icon={faArrowUpFromBracket} 
                  className="my-1 w-4 h-4 text-gray-500 cursor-pointer" 
                />
              </p>
            </div>
            <input id="dropzone-file-article" type="file" className="hidden" />
          </label>
        </div>
        <div className="mt-8 w-full md:w-2/12">
          <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Upload Tax ID* </label>
          <label htmlFor="dropzone-file-tax" className="label-n-lg-gray-200-row">
            <div className="flex flex-col items-end">
              <p className=" text-sm text-gray-500 dark:text-gray-400">
                <FontAwesomeIcon 
                  icon={faArrowUpFromBracket} 
                  className="my-1 w-4 h-4 text-gray-500 cursor-pointer" 
                />
              </p>
            </div>
            <input id="dropzone-file-tax" type="file" className="hidden" />
          </label>
        </div>
      </div>
    </div>
    <div className="flex items-center py-10">
      <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="#161416"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M23 25L23 32H25V25L32 25V23L25 23V16H23L23 23L16 23V25L23 25Z" fill="white"/>
        </svg>
      </span>
      <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold">
        <span>Add More Documents</span>
      </span>
      <div className="flex-grow h-px bg-gray-500"></div>
    </div>
    <div className="mt-2">
      <h3 className="h3-m-xl-neutral-1000">Billing information</h3>
      <div className="md:flex w-full md:space-x-6">
        <div className="mt-8 w-full md:w-3/12">
          <label htmlFor="card-number" className="label-b-sm-neutral-1000"> Card Number </label>
          <input id="card-number"
            type="text"
            placeholder="Card number"
            className="label-n-lg-gray-200-row" />
        </div>
        <div className="mt-8 w-full md:w-2/12">
          <label htmlFor="expiration" className="label-b-sm-neutral-1000"> Expiration date </label>
          <input id="expiration"
            type="text"
            placeholder="MM/YYYY"
            className="label-n-lg-gray-200-row" />
        </div>
        <div className="mt-8 w-full md:w-1/12">
          <label htmlFor="cvv" className="label-b-sm-neutral-1000"> CVV </label>
          <input id="cvv"
            type="text"
            placeholder="CVV"
            className="label-n-lg-gray-200-row" />
        </div>
      </div>
      <h3 className="text-lg font-medium text-gray-500 mt-14">If you want to suspend our sbscription please click here <a href="www" className="underline cursor-pointer font-bold">Suspend Subscription</a></h3>
    </div>
    <div className="mt-10">
      <h3 className="h3-m-xl-neutral-1000">Shipping information</h3>
      <div className="md:flex w-full md:space-x-6">
        <div className="mt-8 w-full md:w-3/12">
          <label htmlFor="customer-country" className="label-b-sm-neutral-1000"> Select shipment provider </label>
          <select className="select-n-lg-gray-500" 
            aria-label="type"
            defaultValue="0"
            id="customer-country"
          >
            <option value={1} key={1}>Shipment provider </option>
          </select>
        </div>
        <div className="mt-8 w-full md:w-3/12">
          <label htmlFor="account-num" className="label-b-sm-neutral-1000">Account Number </label>
          <input id="account-num"
            type="text"
            placeholder="Account number"
            className="label-n-lg-gray-200-row" />
        </div>
      </div>
    </div>
    <div className="flex items-center py-10">
      <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="#161416"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M23 25L23 32H25V25L32 25V23L25 23V16H23L23 23L16 23V25L23 25Z" fill="white"/>
        </svg>
      </span>
      <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold">
        <span>Add More Shipment Provider</span>
      </span>
      <div className="flex-grow h-px bg-gray-500"></div>
    </div>
    <div className="mb-20">
      <p className="mb-2">
        <button type="submit" className="submit-b-lg-neutral-000">Save</button>
        <button type="button" className="cancel-b-lg-gray-500">Cancel</button>
      </p>
    </div>
    <KycModal />
  </div>);   
}

export default AdditionalInformation;