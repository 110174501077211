import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

interface Ibreadcrumb {
    name: string;
    link: string;
}

interface Ibreadcrumbs {
    crumbs: Ibreadcrumb[];
}

const Breadcrumbs = (data : Ibreadcrumbs) => {
  return (<nav className="bg-grey-light rounded-md w-full m-10">
    <ol className="list-reset flex">
      {data.crumbs.map((item,i) => <span className="flex" key={i}>
        <li>
          <Link to={item.link} className={`text-sm ${i != data.crumbs.length - 1 ? "font-medium underline text-gray-500" : "font-bold text-neutral-1000"}`}>
            {item.name}
          </Link>
        </li>
        {i != data.crumbs.length - 1 && <li>
          <span className="text-gray-000 mx-2">
            <FontAwesomeIcon 
              icon={faAngleRight} 
            />
          </span>
        </li>}
      </span>)}
    </ol>
  </nav>);
}

export default Breadcrumbs;