import React, { useEffect, useState } from "react";
interface IStar {
  amount: number;
  id: string;
}

const Star = (star : IStar) => {
  const [fill, setFill] = useState(0);
  
  useEffect(() => {
    const newAmount = star.amount * 100;
    setFill(newAmount);
  }, [star.amount])

  return( 
    <li id={star.id}>
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="w-4 text-primary-700 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <linearGradient id={`grad-${star.id}`}>
          <stop id={`${star.id}-fill`} offset={`${fill}%`} stopColor="currentColor"/>
          <stop offset="0%" stopColor="white"/>
        </linearGradient>
        <path fill={`url(#grad-${star.id})`} d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
        <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
      </svg>
    </li> 
  ); 
}

export default Star;