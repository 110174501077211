import React from "react";
import Carousel from "./carousel";
import Element1 from "./element-1";
import Element2 from "./element-2";
import Element3 from "./element-3";
import Element4 from "./element-4";
import Element5 from "./element-5";

const LandingPage =() => {
  return (
    <div className="App">
      <Carousel />
      <Element1 />
      <Element2 />
      <Element3 />
      <Element4 />
      <Element5 />
    </div>
  );
}

export default LandingPage;
