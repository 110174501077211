import React, { useEffect, useState } from "react";
import { MasterApi } from "../../../api";
import AllBusinesses from "./all-businesses";
import MyFavourites from "./my-favourites";

const Network = () => {
  const [selectedSortByOptions, setSelectedSortByOptions] = useState();
  const [sortByOptions, setSortByOptions] = useState([]);

  const getSortByOptions = async () => {
    const api = new MasterApi();
    const response = await api.apiMasterSortByOptionsGet();
    setSortByOptions(response);
  }

  const changeSortByOptions = (event) => {
    setSelectedSortByOptions(event.target.value)
  }

  useEffect(() => {
    getSortByOptions();
  }, [])

  return (<div className="w-full">
    <div className="m-8">
      <h3 className="text-xll font-medium text-gray-500">Network</h3>
    </div>
    <div className="flex justify-between">
      <div>
            
      </div>
      <div className="md:flex w-1/2 md:w-2/12 space-x-2 space-y-4 md:space-y-0 md:top-10 right-4 relative">
        <select className="form-select appearance-none w-full
                  form-control block px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                  text-base
                  font-normal
                  bg-white bg-no-repeat
                  focus:bg-white" 
        aria-label="type"
        defaultValue="0"
        onChange={changeSortByOptions}
        >
          <option value="" disabled hidden selected className="text-gray-200">Sort by:</option>
          {sortByOptions.map((item, index)=>
            <option value={item} key={index}><span className="text-gray-200">{selectedSortByOptions === item ? "Sort by:" : ""}</span> {item} </option>
          )} 
        </select>
        <button className="w-full md:w-5/12 px-1 py-2 bg-gray-500 text-neutral-000 leading-tight rounded-full">Filters</button>
      </div>
    </div>
    <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-0 shadow-3xl pl-0 mb-4" id="tabs-tab"
      role="tablist">
      <li className="nav-item" role="presentation">
        <a href="#tabs-home" className="
      nav-tabs-link
      active
    " id="tabs-home-tab" data-bs-toggle="pill" data-bs-target="#tabs-home" role="tab" aria-controls="tabs-home"
        aria-selected="true">All Businesses</a>
      </li>
      <li className="nav-item" role="presentation">
        <a href="#tabs-profile" className="
      nav-tabs-link
    " id="tabs-profile-tab" data-bs-toggle="pill" data-bs-target="#tabs-profile" role="tab"
        aria-controls="tabs-profile" aria-selected="false">My Favourites</a>
      </li>
    </ul>
    <div className="tab-content" id="tabs-tabContent">
      <div className="tab-pane fade show active" id="tabs-home" role="tabpanel" aria-labelledby="tabs-home-tab">
        <AllBusinesses />
      </div>
      <div className="tab-pane fade" id="tabs-profile" role="tabpanel" aria-labelledby="tabs-profile-tab">
        <MyFavourites />
      </div>
      
    </div>
  
  </div>);   
}

export default Network;