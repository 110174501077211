import { AuthResult, Configuration, UserApi, UserDetails } from "../api";

const api = new UserApi();

const login = async (loginData) => {
  const response = await api.apiUserSignInPost(loginData);
  const authentication: AuthResult = {
    ...response.authResultTokens,
  }
  const userDetails: UserDetails = {
    ...response.userDetails
  }

  const email: string = response.email

  const authDetails = {
    authentication,
    userDetails,
    email
  }

  localStorage.setItem("ethically", JSON.stringify(authDetails));
  return {...userDetails, email}; 
};

const getAuthDetails = async () => {
  const authDetails = localStorage.getItem("ethically");
  if (!authDetails) {
    await logout();
    return null;
  }
  return JSON.parse(authDetails);
}

const checkExpiry = async () => {
  const authDetails = await getAuthDetails();
  const expiry = authDetails?.authentication.expiry; 
  const currentDate = new Date();
  const expiryDate = new Date(expiry);
  return currentDate > expiryDate;
}

const refreshToken = async () => {
  try{
    const authData = await getAuthDetails();
    if(authData) {
      const response: any = await api.apiUserRefreshTokenPost(authData.authentication);
      const newTokenData = {
        authentication: response,
        userDetails: authData.userDetails,
        email: authData.email
      };
      localStorage.setItem("ethically", JSON.stringify(newTokenData));
    }
  }
  catch(error: any) {
    logout();
  }
}

const validateAuth = async () => {
  if (checkExpiry()) {
    await refreshToken().then(() => {
      return true;
    }).catch(() => {
      logout()
    })
  }
}
 
const forgotPassword = async (data) => {
  const response = await api.apiUserConfirmForgotPasswordPost(data);
  return response;
};

const forgotPasswordCode = async (data) => {
  const response = await api.apiUserForgotPasswordPost(data);
  return response;
};

const logout = async () => {
  await localStorage.removeItem("ethically"); 
};

const getCurrentUser = async () => {
  await validateAuth();
  const user = await getAuthDetails();
  return {...user?.userDetails, email: user?.email};
};

const getBearerToken = () => {
  const authDetails = localStorage.getItem("ethically");
  const user = JSON.parse(authDetails);
  if (user?.authentication?.idToken) {
    const configuration: Configuration = {
      apiKey : `Bearer ${user.authentication.idToken}`
    }
    return configuration;
  }
}

const AuthService = {
  login,
  logout,
  getCurrentUser,
  forgotPassword,
  forgotPasswordCode,
  validateAuth,
  refreshToken,
  getAuthDetails,
  getBearerToken
};
export default AuthService;