import React from "react";
import gems from "../../../../assets/gems.jpg";

const PaymentModal = () => {
  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="ModalPayment" tabIndex={-1} aria-labelledby="ModalPayment" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4">
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body relative m-6 space-y-4">
            <div className="flex flex-row space-x-6">
              <div className="flex w-4/12">
                <img src={gems} alt="first block" className="h-72 w-full md:w-96" />
              </div>
              <div className="w-4/12 space-y-4">
                <p className="font-bold text-xl text-neutral-1000">
                Artisan Link Earring
                </p>
                <p className="font-medium text-sm text-gray-500">
              Seller name will be here
                </p>
                <p className="font-medium text-sm text-gray-500">
              Asset ID: #123456
                </p>
                <p className="font-medium text-sm text-gray-500">
                Price: € 100 
                </p>
                <p className="font-medium text-sm text-gray-500">
                CIF: € 10
                </p>
                <p className="font-medium text-sm text-gray-500">
                Platform Fees: € 2.5
                </p>
                <p className="font-medium text-sm text-gray-500">
                Total: € 112.5
                </p>
              </div>
            </div>
            <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 text-lg font-bold leading-tight rounded-full mr-2.5">Pay now</button>
            <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 text-lg font-bold leading-tight rounded-full mr-2.5">Pay later</button>
            <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 text-lg font-bold leading-tight rounded-full mr-2.5">Schedule payment</button>
            <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 text-lg font-bold leading-tight rounded-full mr-2.5">Pay by escrow</button>
          </div>
        </div>
      </div>
    </div>
  );   
}

export default PaymentModal;