import React, { useEffect, useState } from "react";

interface IMySpaceHeaderModal {
  selectedColumns: string[];
  // eslint-disable-next-line no-unused-vars
  updateColumns: (columnName) => void
}

const MySpaceHeaderModal = (data: IMySpaceHeaderModal) => {
  const columnNames = [
    "Code", "Asset Spec", "Transaction Status", "Purchase", "Closing Stock", 
    "Asset Name", "Status", "Opening Stock", "Issue", "Rate",
    "Asset Type", "Unit", "Sales", "Receive", "Value"
  ]

  const [selectedColumns, setSelectedColumns] = useState(data.selectedColumns);


  const addColumn = (columnName) => {
    setSelectedColumns([columnName, ...selectedColumns]);
  };

  const removeColumn = (columnName) => {
    const index = selectedColumns.indexOf(columnName, 0);
    if (index > -1) {
      selectedColumns.splice(index, 1);
    }
    setSelectedColumns([...selectedColumns])
  }

  const updateColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked === true) {
      addColumn(event.target.value);
    }
    else{
      removeColumn(event.target.value)
    }
  }

  useEffect(() => {
    data.updateColumns(selectedColumns);
  },[selectedColumns])

  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="ModalMySpaceHeader" tabIndex={-1} aria-labelledby="ModalMySpaceHeader" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4  rounded-t-md">
            <p>Select Column names</p>
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div className="modal-body relative p-4 space-y-4">
            <div className="grid md:grid-cols-5 gap-2">
              {columnNames.map((item, index)=>
                <div className="form-check" key={index}>
                  <input 
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                    type="checkbox" 
                    value={item} 
                    id={item} 
                    checked={ selectedColumns.includes(item)? true : false } 
                    onChange={updateColumn}
                  />
                  <label className="form-check-label inline-block text-gray-500 font-bold text-sm" htmlFor="flexCheckDefault">
                    {item}
                  </label>
                </div>
              )} 
            </div>
          </div>
        </div>
      </div>
    </div>
  );   
}

export default MySpaceHeaderModal;