import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";


const AddToPortfolioModal = () => {

  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="ModalAddToPortfolio" tabIndex={-1} aria-labelledby="ModalAddToPortfolio" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-8 text-xl text-neutral-1000 font-bold rounded-t-md">
            <p>Add to Portfolio</p>
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div className="modal-body relative p-8 space-y-4">
            <div className="">
              <h3 className="text-mdl font-bold text-neutral-1000">Portfolio Name</h3>
              <div className="mt-8 w-full md:w-1/2">
                <label htmlFor="customer-country" className="label-b-sm-neutral-1000"> Label </label>
                <select className="select-n-lg-gray-500" 
                  aria-label="type"
                  defaultValue="0"
                  id="customer-country"
                >
                  <option value={1} key={1}>Add to existing portfolio </option>
                </select>
              </div>
              <div className="mt-6">
                <h3 className="text-sm font-bold text-neutral-1000 px-2 mt-4">Asset Description</h3>
                <div className="mb-3 mt-3">
                  <textarea
                    className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-mdl
        font-normal
        text-gray-500
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded-lg
        transition
        ease-in-out
        m-0
        focus:bg-white focus:border-blue-600 focus:outline-none
      "
                    id="exampleFormControlTextarea1"
                    rows={3}
                  />
                </div>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-bold text-gray-500">Add more asset</h3>
                <h3 className="h3-m-mdl-gray-500">Upload JPG, PNG format</h3>
                <div className="mb-3 mt-8">
                  <div className="flex justify-center items-center md:w-3/12">
                    <label htmlFor="dropzone-file" className="dropzone-n-lg-gray-50">
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
              
                        <p className="p-n-sm-gray-500 mb-2"><FontAwesomeIcon 
                          icon={faPlus} 
                          className="mb-3 w-10 h-10 text-gray-500" 
                        /></p>
                        <p className="p-n-sm-gray-500">Add file</p>
                      </div>
                      <input id="dropzone-file" type="file" className="hidden"  />
                    </label>
                  </div> 
                </div>
              </div>
              <hr className="border-gray-300 my-8" />
              <h3 className="text-lg font-bold text-gray-500 flex">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.0039 20.5H4.00391V6.5H13.0039V4.5H4.00391C2.90391 4.5 2.00391 5.4 2.00391 6.5V20.5C2.00391 21.6 2.90391 22.5 4.00391 22.5H18.0039C19.1039 22.5 20.0039 21.6 20.0039 20.5V11.5H18.0039V20.5ZM10.2139 17.33L8.25391 14.97L5.50391 18.5H16.5039L12.9639 13.79L10.2139 17.33ZM20.0039 4.5V1.5H18.0039V4.5H15.0039C15.0139 4.51 15.0039 6.5 15.0039 6.5H18.0039V9.49C18.0139 9.5 20.0039 9.49 20.0039 9.49V6.5H23.0039V4.5H20.0039Z" fill="black"/>
                </svg>
                Add cover image
              </h3>
            </div>
            <div className="my-20">
              <p className="mb-2">
                <button type="submit" className="submit-b-lg-neutral-000">Publish</button>
                <button type="button" className="cancel-b-lg-gray-500">Cancel</button>
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );   
}

export default AddToPortfolioModal;