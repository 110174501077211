import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import AddToPortfolioModal from "../add-to-portfolio-modal";
import CreateFromExistingAssetModal from "../create-from-existing-asset-modal";
import IssueAssetModal from "../issue-asset-modal";
import MySpaceHeaderModal from "../my-space-header-modal";
import TableRow from "./table-row";
import filterIcon from "../../../../assets/filterIcon.svg";
import { ListedAsset, ManageApi, MasterApi } from "../../../../api";
import AuthService from "../../../../services/auth-service";
import { CountryList } from "../../../../helpers/countryList";

const AssetTable = () => {

  const token = AuthService.getBearerToken();
  const manageApi = new ManageApi(token);
  const masterApi = new MasterApi(token);

  const columnNames = [
    "Code", "Asset Spec", "Transaction Status", "Purchase", "Closing Stock", 
    "Asset Name", "Status", "Opening Stock", "Issue", "Rate",
    "Asset Type", "Unit", "Sales", "Receive", "Value"
  ]
  const [selectedColumns, setSelectedColumns] = useState(columnNames);
  const [assets, setAssets] = useState<ListedAsset[]>([]);

  const [countries, setCountries] = useState([]);

  const updateColumns = (columnNames) => {
    setSelectedColumns([...columnNames]);
  };

  const getCountries = async () => {
    const response = await masterApi.apiMasterEligibleCountriesGet();
    const countriesList = response.map(country => ({ value: country, label: CountryList[country] }));
    setCountries(countriesList)
  }

  const getAssets = async () => {
    try{
      const response: ListedAsset[] = await manageApi.apiManageListExistingAssetsGet();
      setAssets(response)
    }
    catch(error: any) {
      console.log(error)
    }
  }

  const onDelete = () => {
    getAssets();
  }

  useEffect(() => {
    getCountries();
    getAssets();
  }, [])

  return (
    <div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 md:-mx-6 lg:-mx-8 ">
          <div className="flex flex-row justify-between space-x-1 text-gray-500 font-bold text-sm px-2">
            <div className="flex">
              <button type="button" className="flex px-6 py-2 space-x-1 leading-tight rounded-full" data-bs-toggle="modal" data-bs-target="#ModalAddToPortfolio">
                <span>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7 9.5L7 16.5H9V9.5L16 9.5V7.5L9 7.5V0.5H7L7 7.5L0 7.5V9.5L7 9.5Z" fill="#161416"/>
                  </svg>
                </span>
                <span>Add Asset/Portfolio</span>
              </button>
              {/* <button type="button" className="flex px-6 py-2 space-x-1 leading-tight rounded-full" data-bs-toggle="modal" data-bs-target="#CreateFromExistingAssetModal">
                <span>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 12V14.5H4.5L11.8733 7.12662L9.37333 4.62662L2 12ZM13.8067 5.19329C14.0667 4.93329 14.0667 4.51329 13.8067 4.25329L12.2467 2.69329C11.9867 2.43329 11.5667 2.43329 11.3067 2.69329L10.0867 3.91329L12.5867 6.41329L13.8067 5.19329Z" fill="black"/>
                  </svg>
                </span>
                <span>Create From Existing Asset</span>
              </button> */}
              {/* <button type="button" className="flex px-6 py-2 space-x-1 leading-tight rounded-full" data-bs-toggle="modal" data-bs-target="#IssueAssetModal">
                <span>
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.33366 13.1243L4.14924 9.97559L2.66699 11.4168L7.33366 16.0834L17.3337 6.08344L15.8514 4.56544L7.33366 13.1243Z" fill="black"/>
                  </svg>
                </span>
                <span>Issue Asset</span>
              </button> */}
              <button type="button" className="flex px-6 py-2 space-x-1 leading-tight rounded-full">
                <span className="-mt-1">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 9.5V19.5H8V9.5H16ZM14.5 3.5H9.5L8.5 4.5H5V6.5H19V4.5H15.5L14.5 3.5ZM18 7.5H6V19.5C6 20.6 6.9 21.5 8 21.5H16C17.1 21.5 18 20.6 18 19.5V7.5Z" fill="#161416"/>
                  </svg>
                </span>
                <span>Delete</span>
              </button>
            </div>
            <div className="flex px-2">
              <ul className="text-mdl font-semiBold">
              </ul>
              <img src={filterIcon} alt="filter block" className="md:mr-7 md:ml-32" />
              <div className="md:w-56 filter">
                <select className="form-select appearance-none w-full
                  form-control block px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                  text-base
                  font-normal
                  bg-white bg-no-repeat
                  focus:bg-white" 
                aria-label="type"
                defaultValue="0"
                >
                  <option value="" disabled hidden selected className="text-gray-200">Sort by:</option>
                  <option value={""} >Value</option>
                </select>
              </div>
            </div>
          </div>
          <div className="py-2 inline-block w-max px-4">
            <div className="overflow-x-scroll max-w-screen-2xl">
              <table className="overflow-x-auto table-fixed m-auto">
                <thead className="border-b-2 border-gray-000 bg-primary-006 text-sm font-bold text-gray-500">
                  <tr>
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                
                    </th> 
                    <th scope="col" className="px-6 pt-12 pb-4 text-left">
                      <div className="form-check">
                        <input className="form-check-input appearance-none h-4 w-4 border bg-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" />
                      </div>
                    </th> 
                    {selectedColumns.includes("Code") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Code
                    </th>}
                    {selectedColumns.includes("Asset Name") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Asset Name
                    </th>}
                    {selectedColumns.includes("Asset Type") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Asset Type
                    </th>}
                    {selectedColumns.includes("Asset Spec") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Asset Spec
                    </th>}
                    {selectedColumns.includes("Status") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Status
                    </th>}
                    {selectedColumns.includes("Unit") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Unit
                    </th>}
                    {selectedColumns.includes("Transaction Status") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Transaction Status
                    </th>}
                    {selectedColumns.includes("Opening Stock") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Opening Stock
                    </th>}
                    {selectedColumns.includes("Sales") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Sales
                    </th>}
                    {selectedColumns.includes("Purchase") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Purchase
                    </th>}
                    {selectedColumns.includes("Issue") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Issue
                    </th>}
                    {selectedColumns.includes("Receive") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Receive
                    </th>}
                    {selectedColumns.includes("Closing Stock") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Closing Stock
                    </th>}
                    {selectedColumns.includes("Rate") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Rate
                    </th>}
                    {selectedColumns.includes("Value") && <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Value
                    </th>}
                    <th data-bs-toggle="modal" data-bs-target="#ModalMySpaceHeader">
                      <FontAwesomeIcon 
                        icon={faAngleRight} 
                        className="w-4 h-4 cursor-pointer" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    assets.map((asset, index) => (
                      <TableRow selectedColumns={selectedColumns} asset={asset} key={index} countries={countries} onDelete={onDelete} />
                    ))
                  }
                </tbody>
              </table>
            </div>
            <MySpaceHeaderModal selectedColumns={selectedColumns} updateColumns={updateColumns} />
            <AddToPortfolioModal />
            <CreateFromExistingAssetModal />
            <IssueAssetModal />
          </div>
        </div>
      </div>
    </div>
  );   
}

export default AssetTable;