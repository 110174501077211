import React from "react";

interface IOurProduct {
    image: string;
    title: string;
}

const OurProduct = (data : IOurProduct) => (
  <div className="md:w-80">
    <div className="block shadow-lg bg-white w-auto">
      <div className="overflow-hidden border-white mx-auto bg-white">
        <img src={data.image} alt="first block" className="h-72 w-full md:w-96" />
      </div>
      <div className="p-6">
        <h4 className="text-2xl text-neutral-1000 font-semibold mb-4">{data.title}</h4>
        <p className="mt-4">
          <button type="button" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-medium text-xs leading-tight rounded-full m-2.5">Coming Soon</button>
        </p>
      </div>
    </div>
  </div>
);

export default OurProduct;
