import React from "react";
import { Link } from "react-router-dom";
import buyers from "../../../assets/buyers.jpg";
import suppliers from "../../../assets/suppliers.jpg";
import traders from "../../../assets/traders.jpg";
import WhyEthically from "./why-ethically";

const Element2 = () => {
  const forBuyers = [
    "Discover a  trusted network of suppliers and browse their multiple unique designs",
    "Get the power of provenance and traceability",
    "Take control of your pay outs with Buy Now Pay later or Escrow options"
  ]

  const forSuppliers = [
    "Be authenticated as a credible, vetted, and transparent supplier",
    "Leverage our suite of shipping, payments, marketing and other services",
    "Expand your business by distributing your inventory online"
  ]

  const forTraders = [
    "Become a part of a global revolution & promote ethical and sustainable trade practices",
    "Pick from a wide range of gemstones, diamonds and jewelry with verifiable authenticity",
    "Trade with global Marketplace of Buyers and Suppliers with Transparent pricing"
  ]

  return (<div>
    <div className="flex flex-col md:flex-row bg-white shadow-lg">
      <div className="md:w-2/5 h-96 md:h-auto object-cover bg-gray-000">
        <div className="relative md:top-1/3 left-1/4 md:left-36 w-5/12">
          <h3 className="text-x4l font-medium text-neutral-000">Why Ethically +</h3>
          <div className="h-1 w-4/12 bg-primary-600" />
        </div>
      </div>
      <div className="md:w-8/12 p-6 flex flex-col justify-start bg-neutral-100">
        <section className="text-gray-500 md:relative md:right-24">
          <div className="grid md:grid-cols-3 gap-16 text-center md:mt-20">
            <WhyEthically image={buyers} title="For Buyers" content={forBuyers} />
            <WhyEthically image={suppliers} title="For Suppliers" content={forSuppliers} />
            <WhyEthically image={traders} title="For Traders" content={forTraders} />
          </div>
          <p className="mb-2 mt-7 md:mr-9.5 md:float-right">
            <Link to="/faq" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-medium text-xs leading-tight rounded-full m-2.5">FAQ</Link>
            <Link to="/pricing-plans" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-medium text-xs leading-tight rounded-full m-2.5">Pricing</Link>
          </p>
        </section>
      </div>
    </div>
  </div>);
}

export default Element2;