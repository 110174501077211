
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";

const CertificateFileUpload = ({setSelectedCertificateFile, id, selectedCertificateFile}) => {
  const [selectedFile, setSelectedFile] = useState("");

  const fileUpload = async (event) => {
    setSelectedFile(event.target.files[0].name);
    setSelectedCertificateFile(event.target.files[0]);
  }

  useEffect(() => {
    if(selectedCertificateFile && !selectedCertificateFile.name) {
      setSelectedFile(selectedCertificateFile);
    }
  }, [selectedCertificateFile])

  return (
    <div className="mt-8 w-full md:w-3/12">
      <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000-row">Upload Certificate* </label>
      <label htmlFor={`dropzone-file-certificate-upload-${id}`} className="label-n-lg-gray-200-row">
        <div className="flex justify-between">
          <span className="flex">{selectedFile}</span>
          <div className="flex flex-col items-end">
            <p className="flex text-sm text-gray-500 dark:text-gray-400">
              <FontAwesomeIcon 
                icon={faArrowUpFromBracket} 
                className="my-1 w-4 h-4 text-gray-500 cursor-pointer" 
              />
            </p>
          </div>
        </div>
        <input id={`dropzone-file-certificate-upload-${id}`} type="file" className="hidden" onChange={fileUpload} />
      </label>
    </div>
  );   
}

export default CertificateFileUpload;