
import React, { useContext } from "react";
import { AssetContext } from "../../../../../services/asset-context";
import Errors from "../../../../helpers/errors";
import CertificateFileUpload from "./certificate-file-upload";

const CertificateUpload = ({register, errors, setSelectedCertificateFile, selectedCertificateFile}) => {
  const { testingLabs } = useContext(AssetContext);

  return (
    <div className="mt-2">
      <div className="md:flex w-full md:space-x-6">
        <CertificateFileUpload setSelectedCertificateFile={setSelectedCertificateFile} selectedCertificateFile={selectedCertificateFile} id="create-asset" />
        <div className="mt-8 w-full md:w-2/12">
          <label htmlFor="testing-labs" className="label-b-sm-neutral-1000"> Select Testing lab* </label>
          <select className="select-n-lg-gray-500" 
            aria-label="type"
            defaultValue="0"
            id="testing-labs"
            {...register("testingLab")}
          >
            <option disabled={true} selected value="">
                Testing labs
            </option>
            {testingLabs.map((item, index)=>
              <option value={item.id} key={index}>{item.name} </option>
            )}
          </select>
          <Errors value={errors.testingLab} />
        </div>
        <div className="mt-16 w-full md:w-2/12">
          <button className="inline-block px-6 py-4 mt-6 md:mt-1 bg-gray-500 text-neutral-000 font-medium text-xs leading-tight rounded-full">Verify</button>
        </div>
      </div>
    </div>
  );   
}

export default CertificateUpload;