import React, { useContext } from "react";
import { AssetProvider } from "../../../services/asset-context";
import CreateAssets from "./create-assets";
import CreatePortfolio from "./create-portfolio";
import CreateRequirement from "./create-requirement";
import CreateService from "./create-service";
import { UserContext } from "../../../services/user-context";
import CreateServiceForServiceProvider from "./create-service-for-service-provider";

const Create = () => {

  const {user} = useContext(UserContext);

  const normalUserView = () => {
    return <>
      <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-0 shadow-3xl pl-0 mb-4" id="tabs-tab"
        role="tablist">
        <li className="nav-item" role="presentation">
          <a href="#tabs-create-assets" className="
      nav-tabs-link
      active
    " id="tabs-create-assets-tab" data-bs-toggle="pill" data-bs-target="#tabs-create-assets" role="tab" aria-controls="tabs-create-assets"
          aria-selected="true">Create Assets</a>
        </li>
        <li className="nav-item" role="presentation">
          <a href="#tabs-create-portfolio" className="
      nav-tabs-link
    " id="tabs-create-portfolio-tab" data-bs-toggle="pill" data-bs-target="#tabs-create-portfolio" role="tab"
          aria-controls="tabs-create-portfolio" aria-selected="false">Create Portfolio</a>
        </li>
        <li className="nav-item" role="presentation">
          <a href="#tabs-create-service" className="
      nav-tabs-link
    " id="tabs-create-service-tab" data-bs-toggle="pill" data-bs-target="#tabs-create-service" role="tab"
          aria-controls="tabs-create-service" aria-selected="false">Create Service</a>
        </li>
        <li className="nav-item" role="presentation">
          <a href="#tabs-create-requirements" className="
      nav-tabs-link
    " id="tabs-create-requirements-tab" data-bs-toggle="pill" data-bs-target="#tabs-create-requirements" role="tab"
          aria-controls="tabs-create-requirements" aria-selected="false">Create Requirements</a>
        </li>
      </ul>
      <div className="tab-content" id="tabs-tabContent">
        <div className="tab-pane fade show active" id="tabs-create-assets" role="tabpanel" aria-labelledby="tabs-create-assets">
          <CreateAssets />
        </div>
        <div className="tab-pane fade" id="tabs-create-portfolio" role="tabpanel" aria-labelledby="tabs-create-portfolio-tab">
          <CreatePortfolio />
        </div>
        <div className="tab-pane fade" id="tabs-create-service" role="tabpanel" aria-labelledby="tabs-create-service-tab">
          <CreateService />
        </div>
        <div className="tab-pane fade" id="tabs-create-requirements" role="tabpanel" aria-labelledby="tabs-create-requirements-tab">
          <CreateRequirement />
        </div>
      </div></>
  }

  const serviceProviderView = () => {
    return <>
      <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-0 shadow-3xl pl-0 mb-4" id="tabs-tab"
        role="tablist">
        <li className="nav-item" role="presentation">
          <a href="#tabs-create-portfolio" className="
      nav-tabs-link
      active
    " id="tabs-create-portfolio-tab" data-bs-toggle="pill" data-bs-target="#tabs-create-portfolio" role="tab"
          aria-controls="tabs-create-portfolio" aria-selected="false">Create Portfolio</a>
        </li>
        <li className="nav-item" role="presentation">
          <a href="#tabs-create-service" className="
      nav-tabs-link
    " id="tabs-create-service-tab" data-bs-toggle="pill" data-bs-target="#tabs-create-service" role="tab"
          aria-controls="tabs-create-service" aria-selected="false">Create Service</a>
        </li>
      </ul>
      <div className="tab-content" id="tabs-tabContent">
        <div className="tab-pane fade show active" id="tabs-create-portfolio" role="tabpanel" aria-labelledby="tabs-create-portfolio-tab">
          <CreatePortfolio />
        </div>
        <div className="tab-pane fade" id="tabs-create-service" role="tabpanel" aria-labelledby="tabs-create-service-tab">
          <CreateServiceForServiceProvider />
        </div>
      </div></>
  }

  return (<div className="w-full">
    <AssetProvider>
      <div className="m-8">
        <h3 className="text-xll font-medium text-gray-500">Create</h3>
      </div>
      { user?.groups?.includes("service_provider") ? serviceProviderView() : normalUserView() }
    </AssetProvider>
  </div>);   
}

export default Create;