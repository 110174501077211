import React from "react";
// import QAndA from "../explore/product-details/questions-answers/q-and-a";
import Breadcrumbs from "../helpers/breadcrumbs";

const FAQ = () => {
  const breadcrumbs = [
    {name: "Home", link:"/"},
    {name: "FAQ", link:"/faq"},
  ]

  // const questionAndAnswers = [
  //   {
  //     question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
  //     answer: "Lorem ipsum dolor sit amet"
  //   },
  //   {
  //     question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
  //     answer: "Lorem ipsum dolor sit amet"
  //   },
  //   {
  //     question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
  //     answer: "Lorem ipsum dolor sit amet"
  //   }
  // ]

  return (<div>
    <Breadcrumbs crumbs={breadcrumbs} />
    <div className="md:mx-36 my-16 text-neutral-1000">
      <h3 className="text-xll font-bold mb-10">FAQ</h3>
      {/* <QAndA questionAndAnswers={questionAndAnswers} addQuestion={false} /> */}
    </div>
  </div>);
}

export default FAQ;