import React from "react";
import { PortfolioPreview } from "../../../api";
import PortfolioContent from "../portfolio-content";

interface IPortfolioProps {
  portfolios: PortfolioPreview[];
  id: number
}

const PortfolioCarousel = ({portfolios, id}: IPortfolioProps) => {
  return (<div id={`carouselExampleCaptions${id}`} className="carousel slide relative" data-bs-ride="carousel" data-bs-interval="false">
    <div className="carousel-inner relative w-full overflow-hidden">
      <div className="carousel-item active relative float-left w-full">
        <PortfolioContent portfolios={portfolios.slice(0, 3)} />
      </div>
      {portfolios.length > 3 && <div className="carousel-item relative float-left w-full">
        <PortfolioContent portfolios={portfolios.slice(3, 6)} />
      </div>}
      {portfolios.length > 6 && <div className="carousel-item relative float-left w-full">
        <PortfolioContent portfolios={portfolios.slice(6, 9)} />
      </div>}
    </div>
  </div>);   
}

export default PortfolioCarousel;