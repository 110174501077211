import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import PaymentModal from "../payment-modal";
import InnerElement from "./inner-element";

const TableRow = () => {
  const [showInnerTable, setShowInnerTable] = useState(false);

  const toggleShowInnerTable = () => {
    setShowInnerTable(!showInnerTable)
  }

  return (
    <React.Fragment>
      <tr className="border-b text-sm font-regular text-gray-500">
        <td className="py-4 text-center" onClick={toggleShowInnerTable} aria-hidden="true">
          { showInnerTable ? <FontAwesomeIcon 
            icon={faAngleDown} 
            className="w-4 h-4 cursor-pointer" 
          /> : <FontAwesomeIcon 
            icon={faAngleRight} 
            className="w-4 h-4 cursor-pointer" />}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            01
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            1234567890
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            13/02/2022
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            Order name will be here
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
            EUR <span className="font-bold">1200</span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span className="font-bold">10</span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          In Transit
        </td>
        <td className="px-6 py-4 whitespace-nowrap flex space-x-2 font-medium">
          <span className="mt-1">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.11908 16.5832L6.0806 16.858L6.19916 16.443L5.97858 16.0721L5.11908 16.5832ZM4 20.5L3.03848 20.2253L2.54398 21.956L4.27472 21.4615L4 20.5ZM7.91677 19.3809L8.42791 18.5214L8.05699 18.3008L7.64205 18.4194L7.91677 19.3809ZM19 12.5C19 16.366 15.866 19.5 12 19.5V21.5C16.9706 21.5 21 17.4706 21 12.5H19ZM12 5.5C15.866 5.5 19 8.63401 19 12.5H21C21 7.52944 16.9706 3.5 12 3.5V5.5ZM5 12.5C5 8.63401 8.13401 5.5 12 5.5V3.5C7.02944 3.5 3 7.52944 3 12.5H5ZM5.97858 16.0721C5.35715 15.0271 5 13.8068 5 12.5H3C3 14.1765 3.45932 15.7487 4.25958 17.0944L5.97858 16.0721ZM4.96152 20.7747L6.0806 16.858L4.15755 16.3085L3.03848 20.2253L4.96152 20.7747ZM7.64205 18.4194L3.72528 19.5385L4.27472 21.4615L8.19149 20.3424L7.64205 18.4194ZM12 19.5C10.6932 19.5 9.47286 19.1428 8.42791 18.5214L7.40563 20.2404C8.7513 21.0407 10.3235 21.5 12 21.5V19.5Z" fill="#161416"/>
              <circle cx="8.0748" cy="12.6471" r="0.9625" fill="#252A2A"/>
              <circle cx="11.9996" cy="12.6471" r="0.9625" fill="#252A2A"/>
              <circle cx="15.9244" cy="12.6471" r="0.9625" fill="#252A2A"/>
            </svg>
          </span>
          <button type="button" className="inline-block px-6 py-2 border-2 border-gray-500 text-gray-500 font-bold leading-tight rounded-full" data-bs-toggle="modal" data-bs-target="#ModalInvoice">Invoice</button>
          <button type="submit" className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold leading-tight rounded-full mr-2.5" data-bs-toggle="modal" data-bs-target="#ModalPayment">Pay now</button>
          <button type="button" className="inline-block px-6 py-2 border-2 border-gray-500 text-gray-500 font-bold leading-tight rounded-full">Cancel</button>
        </td>
      </tr>
      {showInnerTable && <tr>
        <td colSpan={9}>
          <InnerElement />
        </td>
      </tr>}
      <PaymentModal />
    </React.Fragment>
  );   
}

export default TableRow;