import React from "react";
import TransactionTable from "../transaction-table";

const MySales = () => {
  return (
    <div className="justify-center m-10">
      <TransactionTable />
    </div>
  );   
}

export default MySales;