import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { MasterApi } from "../../../../api";
import { CountryList } from "../../../../helpers/countryList";
import AuthService from "../../../../services/auth-service";
import Errors from "../../../helpers/errors";

const Warehouse = ({warehouse, id, register, eligibleCountries, errors, deleteWarehouse}) => {
  const [states, setStates] = useState([]);

  const token = AuthService.getBearerToken();
  const masterApi = new MasterApi(token);

  const getStates = async (event) => {
    const response = await masterApi.apiMasterStatesGet(event.target.value);
    setStates(response)
  }

  useEffect(() => {
    console.log(warehouse)
  },[])

  return (
    <div className="md:flex w-full md:space-x-6">
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Address </label>
        <input id={`warehouse-address-${id}`}
          type="text"
          placeholder="Address line will be here"
          className="label-n-lg-gray-200-row" 
          {...register(`warehouses[${id}].addressLine`)}
        />
        <Errors value={errors.warehouses && errors?.warehouses[id]?.addressLine} />
      </div>
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor={`warehouse-country-${id}`} className="label-b-sm-neutral-1000"> Country </label>
        <select className="select-n-lg-gray-500" 
          aria-label="type"
          defaultValue="0"
          id={`warehouse-country-${id}`}
          {...register(`warehouses[${id}].country`)}
          onChange={(event) => getStates(event)}
        >
          <option disabled={true} selected value="">
                Country
          </option>
          {eligibleCountries.map((item, index)=>
            <option value={item} key={index}>{CountryList[item]} </option>
          )} 
        </select>
        <Errors value={errors.warehouses && errors?.warehouses[id]?.country} />
      </div>
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor={`warehouse-state-${id}`}className="label-b-sm-neutral-1000"> State </label>
        <select className="select-n-lg-gray-500" 
          aria-label="type"
          defaultValue="0"
          id={`warehouse-state-${id}`}
          {...register(`warehouses[${id}].state`)}
        >
          <option disabled={true} selected value="">
                      States
          </option>
          {states.map((item, index)=>
            <option value={item} key={index}>{item} </option>
          )} 
        </select>
        <Errors value={errors.warehouses && errors?.warehouses[id]?.state} />
      </div>
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor="warehouse-city" className="label-b-sm-neutral-1000"> City </label>
        <input id={`warehouse-city-${id}`}
          type="text"
          placeholder="City will be here"
          className="label-n-lg-gray-200-row"
          {...register(`warehouses[${id}].city`)}
        />
        <Errors value={errors.warehouses && errors?.warehouses[id]?.city} />
      </div>
      <div className="mt-8 w-full md:w-2/12">
        <label htmlFor="warehouse-pincode" className="label-b-sm-neutral-1000"> Pin </label>
        <input id={`warehouse-pin-${id}`}
          type="number"
          placeholder="Pincode will be here"
          className="label-n-lg-gray-200-row"
          {...register(`warehouses[${id}].pin`)}
        />
        <Errors value={errors.warehouses && errors?.warehouses[id]?.pin} />
      </div>
      <button type="button" className="mt-16" id={`warehouse-delete-${id}`} onClick={() => deleteWarehouse(id)}>
        <FontAwesomeIcon
          icon={faCircleXmark}
          className="w-6 h-6 text-gray-500 cursor-pointer" />
      </button>
      <input id={`warehouses-${id}`} type="hidden" value={`warehouses-${id}`} {...register(`warehouses[${id}].id`)}  />
    </div>);   
}

export default Warehouse;