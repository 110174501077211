import React, { createContext, useEffect, useState } from "react";
import {  CreateApi, MasterApi, TraderApi } from "../api";
import AuthService from "./auth-service";

const AssetContext = createContext<any>({} as any);

export const AssetProvider = ({ children }) => {
  const token = AuthService.getBearerToken();

  const [assetTypes, setAssetTypes] = useState([]);
  const [eligibleCountries, setEligibleCountries] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [metalPurities, setMetalPurities] = useState([]);
  const [units, setUnits] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [testingLabs, setTestingLabs] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [deliveryTerms, setDeliveryTerms] = useState([]);
  const [warehouseLocations, setWarehouseLocations] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const masterApi = new MasterApi(token);
  const traderApi = new TraderApi(token);
  const createApi = new CreateApi(token);

  const getAssetTypes = async () => {
    const response = await masterApi.apiMasterAssetTypesGet();
    setAssetTypes(response)
  }

  const getEligibleCountries = async () => {
    const response = await masterApi.apiMasterEligibleCountriesGet();
    setEligibleCountries(response)
  }

  const getAllCountries = async () => {
    const response = await masterApi.apiMasterAllCountriesGet();
    setAllCountries(response)
  }

  const getMetalPurities = async () => {
    const response = await masterApi.apiMasterMetalPuritiesGet();
    setMetalPurities(response)
  }

  const getUnits = async () => {
    const response = await masterApi.apiMasterUnitsGet();
    setUnits(response)
  } 
  
  const getCurrencies = async () => {
    const response = await masterApi.apiMasterCurrencyTypesGet();
    setCurrencies(response)
  }
  
  const getTestingLabs = async () => {
    const response = await createApi.apiCreateTestingLabsGet();
    setTestingLabs(response)
  }

  const getDocumentTypes = async () => {
    const response = await masterApi.apiMasterDocumentTypesGet();
    setDocumentTypes(response)
  }

  const getPaymentTerms = async () => {
    const response = await masterApi.apiMasterPaymentTermsOptionsGet();
    setPaymentTerms(response)
  }
  
  const getShippingMethods = async () => {
    const response = await masterApi.apiMasterShippingMethodsGet();
    setShippingMethods(response)
  }

  const getDeliveryTerms = async () => {
    const response = await masterApi.apiMasterDeliveryTermsOptionsGet();
    setDeliveryTerms(response)
  }

  const getPaymentMethods = async () => {
    const response = await masterApi.apiMasterPaymentMethodsGet();
    console.log(response);
    setPaymentMethods(response)
  }
  
  const getWarehouseLocations = async () => {
    const response = await traderApi.apiTraderWarehousesGet();
    setWarehouseLocations(response)
  }


  useEffect(() => {
    getAssetTypes();
    getEligibleCountries();
    getAllCountries();
    getMetalPurities();
    getUnits();
    getCurrencies();
    getTestingLabs();
    getDocumentTypes();
    getPaymentTerms();
    getShippingMethods();
    getDeliveryTerms();
    getWarehouseLocations();
    getPaymentMethods();
  }, [])


  return (
    <AssetContext.Provider value={{ assetTypes, 
      eligibleCountries, metalPurities, 
      units,currencies,testingLabs,documentTypes,
      paymentTerms,shippingMethods,deliveryTerms,
      warehouseLocations, paymentMethods, allCountries  }}>
      {children}
    </AssetContext.Provider>
  );
};

export { AssetContext };

