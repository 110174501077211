import React from "react";
import MySales from "./my-sales";
import MyPurchases from "./my-purchases";

const MyTransactions = () => {

  return (<div className="w-full h-screen">
    <div className="m-8">
      <h3 className="text-xll font-medium text-gray-500">My Transactions</h3>
    </div>
    <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-0 shadow-3xl pl-0 mb-4" id="tabs-tab"
      role="tablist">
      <li className="nav-item" role="presentation">
        <a href="#tabs-home" className="
      nav-tabs-link
      active
    " id="tabs-home-tab" data-bs-toggle="pill" data-bs-target="#tabs-home" role="tab" aria-controls="tabs-home"
        aria-selected="true">My Sales</a>
      </li>
      <li className="nav-item" role="presentation">
        <a href="#tabs-profile" className="
      nav-tabs-link
    " id="tabs-profile-tab" data-bs-toggle="pill" data-bs-target="#tabs-profile" role="tab"
        aria-controls="tabs-profile" aria-selected="false">My Purchases</a>
      </li>
    </ul>
    <div className="tab-content" id="tabs-tabContent">
      <div className="tab-pane fade show active" id="tabs-home" role="tabpanel" aria-labelledby="tabs-home-tab">
        <MySales />
      </div>
      <div className="tab-pane fade" id="tabs-profile" role="tabpanel" aria-labelledby="tabs-profile-tab">
        <MyPurchases />
      </div>
      
    </div>
  
  </div>);   
}

export default MyTransactions;