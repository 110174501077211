import React, { useEffect, useState } from "react";
import Errors from "../../../../helpers/errors";
import FileUpload from "../../helper-components/file-upload";

const PortfolioSingle = ({portfolio, id, register, errors, selectedFiles, setSelectedFiles, errorFiles}) => {
  const [newFiles, setNewFiles] = useState([]);

  const uploadFiles = (event) => {
    console.log(portfolio)
    setNewFiles(event);
    selectedFiles[id] = event;
    setSelectedFiles({...selectedFiles});
  }

  useEffect(() => {
    if(!selectedFiles[id]) {
      selectedFiles[id] = [];
      setSelectedFiles({...selectedFiles});
    }
  },[selectedFiles])

  useEffect(() => {
    if(selectedFiles[portfolio.code]) {
      setNewFiles(selectedFiles[portfolio.code])
    }
  },[portfolio])

  return (<div>
    <input id={`portfolio-file-${id}`} type="hidden" value={`portfolio-${id}`} {...register(`portfolios[${id}].id`)}  />
    <Errors value={errors.portfolios && errors?.portfolios[id]?.id} />
    <div className="mt-8 w-full md:w-2/12">
      <label htmlFor="warehouse-address" className="label-b-sm-neutral-1000"> Portfolio Name </label>
      <input id="warehouse-address"
        type="text"
        placeholder="Name"
        className="label-n-lg-gray-200-row" 
        name="portfolio-name"
        {...register(`portfolios[${id}].name`)}
      />
      <Errors value={errors.portfolios && errors?.portfolios[id]?.name} />
    </div>
    <div className="mb-3 mt-8">
      <textarea
        className="textarea-n-mdl-gray-500"
        id="exampleFormControlTextarea1"
        rows={3}
        placeholder="Input your product requirement here, be as specific as you can about what you need"
        name="portfolio-requirement"
        {...register(`portfolios[${id}].description`)}
      />
      <Errors value={errors.portfolios && errors?.portfolios[id]?.description} />
    </div>
    <FileUpload selectedFiles={newFiles} setSelectedFiles={uploadFiles} id={`create-portfolio-${id}`} /> 
    <Errors value={errorFiles[id]} />
    <div className="flex flex-col mb-6 mt-8">
      <div className="relative">
        <input 
          type="checkbox"
          {...register(`portfolios[${id}].publishAsNFT`)}
          id={`exampleCheck2${id}`}
          className="radio-n-xl-gray-300" />
        <label className="label-m-md-gray-500" htmlFor="exampleCheck2">
          <span className="text-lg">Add as NFT</span>&nbsp;&nbsp; <span className="text-sm">*Additional cost would apply</span>
        </label>
        <Errors value={errors.portfolios && errors?.portfolios[id]?.publishAsNFT} />
      </div>
    </div>
  </div>);   
}

export default PortfolioSingle;