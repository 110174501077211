import React from "react";
import PurchaseTable from "../purchase-table";

const MyFavourites = () => {
  return (
    <div className="justify-center  m-10">
      <PurchaseTable />
    </div>
  );   
}

export default MyFavourites;