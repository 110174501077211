import React from "react";
import { ProductPreview } from "../../../api";
import LoginModal from "../../login/login-modal";
import ProductContent from "../product-content";

interface IProductProps {
  product: ProductPreview;
  setIsLoggedIn: () => void;
}

const Product = ({product, setIsLoggedIn}: IProductProps) => {
  return(
    <>
      <ProductContent product={product} />
      <LoginModal setIsLoggedIn={setIsLoggedIn} />
    </>
  )};

export default Product;
