import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import InnerElement from "../inner-element";
import { ListedAsset } from "../../../../api";
import ConfirmPublishModal from "../confirm-publish-modal";
import ReactSelect, {  components } from "react-select";
import { CountryList } from "../../../../helpers/countryList";

interface ITableRow {
  selectedColumns: string[];
  asset: ListedAsset;
  countries: any[];
  onDelete: () => void;
}

const TableRow = ({selectedColumns, asset, countries, onDelete}: ITableRow) => {
  const [showInnerTable, setShowInnerTable] = useState(false);
  const [openingStock, setOpeningStock] = useState<number>(asset.openingStock || 0);
  const [closingStock, setClosingStock] = useState<number>(asset.closingStock || 0);
  const [rate, setRate] = useState<number>(asset.rate || 0);
  const [value, setValue] = useState<number>(asset.value.amount || 0);
  const [assetStatus, setAssetStatus] = useState<number>(asset.transactionStatus || 0);

  const [selectedCountries, setSelectedCountries] = useState(countries);
  

  const toggleShowInnerTable = () => {
    setShowInnerTable(!showInnerTable)
  }
  
  const onDeleteAsset = () => {
    setShowInnerTable(false);
    onDelete();
  }

  useEffect(() => {
    if (asset.coutriesPublishedTo && asset.coutriesPublishedTo.length > 0) {
      const oldSelectedCountries = asset.coutriesPublishedTo.map(country => ({ value: country, label: CountryList[country] }));
      setSelectedCountries(oldSelectedCountries);
    }
  }, [])

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: "50px",
      width: "120px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: "20px",
    }),

    valueContainer: (provided) => ({
      ...provided,
      height: "20px",
      padding: "0 6px"
    }),

    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "30px",
    }),
  };

  const handleChange = (selected) => {
    setSelectedCountries(selected)
  };

  return (
    <React.Fragment>
      <tr className="border-b text-sm font-regular text-gray-500">
        <td className="py-4 text-center" onClick={toggleShowInnerTable} aria-hidden="true">
          { showInnerTable ? <FontAwesomeIcon 
            icon={faAngleDown} 
            className="w-4 h-4 cursor-pointer" 
          /> : <FontAwesomeIcon 
            icon={faAngleRight} 
            className="w-4 h-4 cursor-pointer" />}
        </td>
        <td className="text-center">
          <div className="form-check">
            <input className="form-check-input appearance-none h-4 w-4 border bg-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" />
          </div>
        </td>
        {selectedColumns.includes("Code") && <td className="px-6 py-4 whitespace-nowrap">
          {asset.id}
        </td>}
        {selectedColumns.includes("Asset Name") && <td className="px-6 py-4 whitespace-nowrap">
          {asset.name}
        </td>}
        {selectedColumns.includes("Asset Type") && <td className="px-6 py-4 whitespace-nowrap">
          {asset.categorisation.type}
        </td>}
        {selectedColumns.includes("Asset Spec") && <td className="px-6 py-4 whitespace-nowrap">
          {asset.purity}
        </td>}
        {selectedColumns.includes("Status") && <td className="py-4 whitespace-nowrap">
          <select className="form-select appearance-none
                  form-control block w-28 px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                  text-base
                  font-normal
                  bg-white bg-no-repeat
                  focus:bg-white" 
          aria-label="type"
          defaultValue="0"
          id="customer-country"
          value={assetStatus}
          onChange={(event) => setAssetStatus(+event.target.value)}
          >
            {/* sales type 0 = Outright  1 = Bid  2 = MadeToOrder  3 = Inventory */}
            {/* asset status 0 = Bidding  1 = InStock  2 = OutOfStock  3 = Inventory  4 = JobWork  5 = ConsignmentOrMemo  6 = MadeToOrder */}
            {asset.salesType === 1 && <option value={0} key={2}>Bidding</option>}
            {asset.salesType === 0 && <option value={1} key={1}>In Stock</option>}
            {(asset.salesType === 0 || asset.salesType === 1 || asset.salesType === 3 ) && <option value={2} key={3}>Out of Stock</option>}
            {asset.salesType === 3 && <option value={3} key={4}>Inventory</option>}
            {asset.salesType === 3 && <option value={4} key={5}>Jobwork</option>}
            {asset.salesType === 3 && <option value={5} key={6}>Consignment/Memo</option>}
            {asset.salesType === 2 && <option value={6} key={6}>Made to order</option>}
          </select>
        </td>}
        {selectedColumns.includes("Unit") && <td className="px-6 py-4 whitespace-nowrap">
          {asset.unit}
        </td>}
        {selectedColumns.includes("Transaction Status") && <td className="px-6 py-4 whitespace-nowrap">
          {asset.transactionStatus}
        </td>}
        {selectedColumns.includes("Opening Stock") && <td className="px-6 py-4 whitespace-nowrap">
          <input id="legalNameFormInput"
            type="number"
            className="asset-cell" 
            value={openingStock}
            onChange={(e) => setOpeningStock(+e.target.value)}
          />
        </td>
        }
        {selectedColumns.includes("Sales") && <td className="px-6 py-4 whitespace-nowrap">
          <input id="legalNameFormInput"
            type="number"
            className="asset-cell" 
            value={0}
          />
        </td>
        }
        {selectedColumns.includes("Purchase") && <td className="px-6 py-4 whitespace-nowrap ">
          <input id="legalNameFormInput"
            type="number"
            className="asset-cell" 
            value={0}
          />
        </td>
        }
        {selectedColumns.includes("Issue") && <td className="px-6 py-4 whitespace-nowrap">
          <input id="legalNameFormInput"
            type="number"
            className="asset-cell"
            value={0}
          />
        </td>
        }
        {selectedColumns.includes("Receive") && <td className="px-6 py-4 whitespace-nowrap">
          <input id="legalNameFormInput"
            type="number"
            className="asset-cell" 
            value={0}
          />
        </td>
        }
        {selectedColumns.includes("Closing Stock") && <td className="px-6 py-4 whitespace-nowrap">
          <input id="legalNameFormInput"
            type="number"
            className="asset-cell"
            value={closingStock}
            onChange={(e) => setClosingStock(+e.target.value)}
          />
        </td>
        }
        {selectedColumns.includes("Rate") && <td className="px-6 py-4 whitespace-nowrap">
          <input id="legalNameFormInput"
            type="number"
            className="asset-cell"
            value={rate}
            onChange={(e) => setRate(+e.target.value)}
          />
        </td>
        }
        {selectedColumns.includes("Value") && <td className="px-6 py-4 whitespace-nowrap">
          <input id="legalNameFormInput"
            type="number"
            className="asset-cell"
            value={value}
            onChange={(e) => setValue(+e.target.value)}
          />
        </td>
        }

        <td className="px-6 py-4 whitespace-nowrap flex space-x-2 font-medium">
          {/* <select className="form-select appearance-none
                  form-control block w-24 px-4 py-2 text-lg font-normal text-gray-500 bg-white bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:bg-white focus:border-gray-500 focus:outline-none placeholder-gray-800
                  text-base
                  font-normal
                  bg-white bg-no-repeat
                  focus:bg-white" 
          aria-label="type"
          defaultValue="0"
          id="customer-country"
          value={currencyType}
          onChange={(e) => setCurrencyType(e.target.value)}
          >
            <option disabled={true} selected value="">
                    Currency
            </option>
            {currencies.map((item, index)=>
              <option value={item} key={index}>{item} </option>
            )} 
          </select> */}

          <span
            className=""
            data-toggle="popover"
            data-trigger="focus"
            data-content="Please select country(s)"
          >
            <ReactSelect
              options={countries}
              isMulti={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
                DropdownIndicator:() => null, IndicatorSeparator:() => null
              }}
              onChange={handleChange}
              value={selectedCountries}
              styles={customStyles}
              placeholder="Countries"
            />
          </span>
          <button 
            type="submit" 
            className="inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold leading-tight rounded-full mr-2.5" 
            data-bs-toggle="modal" 
            data-bs-target={`#ModalConfirmPublish-${asset.id}`}
          >
            Publish
          </button>
        </td>
      </tr>
      {showInnerTable && <tr>
        <td colSpan={9}>
          <InnerElement asset={asset} key={asset.id} onDelete={onDeleteAsset} />
        </td>
      </tr>}
      <ConfirmPublishModal 
        assetID={asset.id} 
        stockStatus={assetStatus} 
        openingStock={openingStock} 
        closingStock={closingStock}
        countriesToPublishTo={selectedCountries}
        assetStatus={assetStatus}
        key={asset.id} 
      />
    </React.Fragment>
  );   
}

export default TableRow;