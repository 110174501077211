import React from "react";


const CreateFromExistingAssetModal = () => {

  return (
    <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="CreateFromExistingAssetModal" tabIndex={-1} aria-labelledby="CreateFromExistingAssetModal" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-neutral-000 bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-8 text-xl text-neutral-1000 font-bold rounded-t-md">
            <p>Create From Existing Asset</p>
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-gray-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div className="modal-body relative p-8 pt-0 space-y-4">
            <div className="">
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full">
                        <thead className="border-b-2 border-gray-000 bg-primary-006 text-sm font-bold text-gray-500">
                          <tr>
                            <th scope="col" className="px-6 pt-12 pb-4 text-left">
                              <div className="form-check">
                                <input className="form-check-input appearance-none h-4 w-4 border bg-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" />
                              </div>
                            </th> 
                            <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Code
                            </th>
                            <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Asset Name
                            </th>
                            <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Asset Type
                            </th>
                            <th scope="col" className="px-6 pt-12 pb-4 text-left">
                    Unit
                            </th>
                            <th scope="col" className="px-6 pt-12 pb-4 text-left">
                            Available Quantity
                            </th>
                            <th scope="col" className="px-6 pt-12 pb-4 text-left">
                            Add Quantity
                            </th>
                            <th scope="col" className="px-6 pt-12 pb-4 text-left">
                            Issue Quantity
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className=" border-b">
                            <td className="text-center">
                              <div className="form-check">
                                <input className="form-check-input appearance-none h-4 w-4 border bg-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" />
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
        abc12334
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
        Ring
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
        Finished Jewellery
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
        Gram
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <input id="legalNameFormInput"
                                type="text"
                                className="asset-cell" />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <input id="legalNameFormInput"
                                type="text"
                                className="asset-cell" />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <input id="legalNameFormInput"
                                type="text"
                                className="asset-cell" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center py-4">
              <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="48" height="48" rx="24" fill="#161416"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M23 25L23 32H25V25L32 25V23L25 23V16H23L23 23L16 23V25L23 25Z" fill="white"/>
                </svg>
              </span>
              <span className="flex-shrink flex text-lg text-neutral-1000 pr-2 font-bold">
                <span>Add</span>
              </span>
              <div className="flex-grow h-px bg-gray-500"></div>
            </div>
            <div className="my-20">
              <p className="mb-2">
                <button type="submit" className="submit-b-lg-neutral-000">Publish</button>
                <button type="button" className="cancel-b-lg-gray-500">Cancel</button>
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );   
}

export default CreateFromExistingAssetModal;