import React from "react";
import { ListedPortfolio } from "../../../../api";
import ConfirmDeleteModal from "../confirm-delete-modal";
import { Link } from "react-router-dom";

interface IInnerElement {
  portfolio?: ListedPortfolio;
  onDelete: () => void;
}

const InnerElementPortfolio = ({portfolio, onDelete}: IInnerElement) => {

  return (
    <div className="m-8">
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="flex flex-row space-x-6">
            {/* <div className="flex w-1/4">
              {portfolio.files.length > 0 && <img src={portfolio.files[0]} alt="first block" className="h-72 w-full md:w-96" />}
            </div> */}
            <div className="w-2/4 space-y-4">
              <p className="font-bold text-xl text-neutral-1000">
                {portfolio.name}
              </p>
              <p className="font-medium text-sm text-gray-500">
                {portfolio.description}
              </p>
              <div className="flex space-x-4">
                <Link to={{pathname: `/homepage/create/${portfolio.id}`}} state={ {item: portfolio, type: "asset"} }>
                  <button type="button" className="flex px-6 py-2 border-2 space-x-1 border-gray-200 text-gray-000 font-bold leading-tight rounded-full">
                    <span className="mt-1">
                      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 9.99996V12.5H2.5L9.87333 5.12662L7.37333 2.62662L0 9.99996ZM11.8067 3.19329C12.0667 2.93329 12.0667 2.51329 11.8067 2.25329L10.2467 0.693291C9.98667 0.433291 9.56667 0.433291 9.30667 0.693291L8.08667 1.91329L10.5867 4.41329L11.8067 3.19329Z" fill="black"/>
                      </svg>
                    </span>
                    <span>Edit</span>
                  </button>
                </Link>
                <button type="button" 
                  className="flex px-6 py-2 border-2 space-x-1 border-gray-200 text-gray-000 font-bold leading-tight rounded-full" 
                  data-bs-toggle="modal" 
                  data-bs-target={`#ModalConfirmDelete-${portfolio.id}`}
                >
                  <span className="mt-1">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2147_5313)">
                        <path d="M11.5561 5.83333V14.7222H4.44499V5.83333H11.5561ZM10.2228 0.5H5.77832L4.88943 1.38889H1.77832V3.16667H14.2228V1.38889H11.1117L10.2228 0.5ZM13.3339 4.05556H2.66721V14.7222C2.66721 15.7 3.46721 16.5 4.44499 16.5H11.5561C12.5339 16.5 13.3339 15.7 13.3339 14.7222V4.05556Z" fill="black"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_2147_5313">
                          <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Delete</span>
                </button>
                <div className="flex text-primary-008 font-bold text-sm space-x-2 mt-2">
                  {/* <span className="mt-1">
                    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.2726 0.5H1.54537C0.745366 0.5 0.0908203 1.15455 0.0908203 1.95455V12.1364H1.54537V1.95455H10.2726V0.5ZM12.4545 3.40909H4.45446C3.65446 3.40909 2.99991 4.06364 2.99991 4.86364V15.0455C2.99991 15.8455 3.65446 16.5 4.45446 16.5H12.4545C13.2545 16.5 13.909 15.8455 13.909 15.0455V4.86364C13.909 4.06364 13.2545 3.40909 12.4545 3.40909ZM12.4545 15.0455H4.45446V4.86364H12.4545V15.0455Z" fill="#0172CB"/>
                    </svg>
                  </span> */}
                  {portfolio.quotesReceived && <span>{portfolio.quotesReceived} quote received</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal assetID={portfolio.id} key={portfolio.id} onDelete={onDelete} />
    </div>
  );   
}

export default InnerElementPortfolio;